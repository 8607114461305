/* eslint-disable no-restricted-globals */
import BaseObject from './utils/base-object';
import getTranslations from './i18n';

/**
 * Controller for navigation between steps.
 *
 * If the current step/page contains a form we defer navigation to the
 * backend, overriding the default link action and indicating direction to
 * navigate through a hidden field in the form.
 */
const StepsController = Object.assign(Object.create(BaseObject), {
  /**
   * Initialize the controller.
   *
   * Adds click handlers for next and previous buttons if they're defined.
   */
  init() {
    const clickHandler = this.handleClick.bind(this);

    this.actions.forEach((action) => {
      if (!action.disabled) {
        action.addEventListener('click', clickHandler);
      }
    });
  },

  /**
   * Moves to previous/next step as appropriate.
   *
   * If moving to the next step and a form is used on current step we submit it
   * and let the backend take care of redirecting to the correct step.
   *
   * Otherwise we simply set the location to the triggering links href
   * attribute.
   */
  proceed(link, err) {
    if (err) {
      return;
    }

    const form = this.form || (this.step && this.step.getForm && this.step.getForm());
    const nextInput = form && form.querySelector('[name="next"]');
    let nextUrl = link.href;

    if (this.step && typeof this.step.getNextUrl === 'function') {
      nextUrl = this.step.getNextUrl(nextUrl);
    }
    /*
     * On send step we don't have a next input, so we just go directly to the
     * destination.
     */
    if (!nextInput) {
      window.location = nextUrl;
    } else {
      nextInput.value = nextUrl;

      if (this.triggerSubmit() !== false) {
        form.submit();
      }
    }
  },

  /**
   * Trigger a form submit event, letting other handlers cancel it.
   *
   * This will trigger a submit event on the form element, and then see if the
   * event was cancelled by any handlers, returning this status for further
   * handling by the caller.
   *
   * @return Boolean
   */
  triggerSubmit() {
    const submitEvent = new CustomEvent('submit', {
      bubbles: true,
      cancelable: true,
    });
    const form = this.form || (this.step && this.step.getForm && this.step.getForm());
    form.dispatchEvent(submitEvent);

    return !submitEvent.defaultPrevented;
  },

  /**
   * Event handler for clicks on previous/next links.
   *
   * Overrides the native link action to either let a step controller save
   * content, or by submitting the steps form for saving changes if needed.
   */
  handleClick(event) {
    event.preventDefault();

    const link = event.currentTarget;
    const proceed = this.proceed.bind(this, link);

    // Delay proceeding till step has ended editing
    if (this.step && typeof this.step.end === 'function') {
      this.step.end(proceed);
    } else {
      proceed();
    }
  },
});

/**
 * Base template step controller.
 *
 * Handles display a warning when changing to another template and double click
 * which automatically selects the template and proceeds.
 */
const baseTemplateStepController = (form) => {
  let currentTemplate = document.querySelector('input[name=template]:checked');
  let templateIsChanged = false;
  let i18n = null;
  const nextButton = document.querySelector('a.next');

  /**
   * Validate choosing a template.
   *
   * If another template is already chosen we popup a confirm dialog so that
   * the consquences are known.
   *
   * @param {HTMLElement} input
   */
  const validateChoice = (input) => {
    const msg = i18n.gettext(
      'Are you sure you want to change the template? Changing the template ' +
        'will result in the loss of all existing content.'
    );

    if (input && currentTemplate && currentTemplate !== input) {
      if (templateIsChanged || confirm(msg)) {
        currentTemplate = input;
        templateIsChanged = true;
        return true;
      }
      return false;
    }

    return true;
  };

  /**
   * Handle click on a template card.
   *
   * @param {HTMLElement} templateCard
   * @param {HTMLElement} input
   */
  const handleTemplateChoose = (templateCard, input) => {
    let isValid = true;

    if (!templateCard.classList.contains('selected')) {
      const currentSelected = document.querySelector('.card.template.selected');

      isValid = validateChoice(input);

      if (isValid) {
        if (currentSelected) {
          currentSelected.classList.remove('selected');
          currentSelected.appendChild(templateCard.querySelector('.card-reveal-info'));
          templateCard.appendChild(currentSelected.querySelector('.card-reveal-info'));
        }

        templateCard.classList.add('selected');

        Object.assign(input, { checked: true, disabled: false });
      }
    }

    return isValid;
  };

  /**
   * Select a template by ID and proceed to next step.
   *
   * @param {Number} id Id of the template to select.
   */
  const selectAndProceed = (id) => {
    const input = document.querySelector(`input[value="${id}"]`);
    const templateCard = input.parentNode;

    if (handleTemplateChoose(templateCard, input)) {
      nextButton.click();
    }
  };

  return {
    init() {
      getTranslations().then((translate) => {
        i18n = translate;
      });
    },

    /**
     * Return URL to content step if template is changed.
     *
     * @param {String} URL URL to the proceeding step.
     */
    getNextUrl(url) {
      if (templateIsChanged) {
        return form.next.value;
      }

      return url;
    },

    selectAndProceed,
  };
};

StepsController.defaults = {
  form: null,
  actions: null,
  step: null,
};

const step = ({ step }) => {
  const navigation = document.querySelector('.steps-navigation');

  if (navigation) {
    StepsController.create({
      actions: Array.from(navigation.querySelectorAll('.prev, .next, .step a')),
      form: step?.form ?? document.querySelector('form'),
      step,
    });
  }
};
export default step;

export { baseTemplateStepController };
