/**
 * A module for creating a generic overlay that can be shown on top of the
 * region an element covers, based on the cursor hovering over a different
 * element. This can be used to indicate for example which region of the
 * document a certain button will affect if clicked.
 */
import { getAbsoluteBoundingClientRect } from 'common/lib/compat';
import Viewport from '../viewport';

/**
 * Creates a new overlay.
 *
 * A single overlay can be used for several elements and their related
 * element regions.
 *
 * Options so far only let's one add extra CSS classes to the overlay
 * element, for custom styling purposes.
 *
 * @param {Object} opts Optional options, see RegionOverlay.defaults.
 */
function RegionOverlay(opts) {
  this.opts = Object.assign({}, RegionOverlay.defaults, opts);

  this.el = document.createElement('div');

  this.el.className = 'mm-region-overlay';
  if (this.opts.className) {
    this.el.className += ` ${this.opts.className}`;
  }

  if (this.opts.title) {
    this.el.title = this.opts.title;
  }

  this.currentTarget = null;
}

RegionOverlay.selector = '.mm-region-overlay';

/**
 * Strips out elements for this UI module from a clone of the editor
 * content elements.
 */
RegionOverlay.cleanup = function cleanup($elements) {
  return $elements.find(this.selector).remove().end();
};

/**
 * Default options.
 */
RegionOverlay.defaults = {
  /*
   * Extra CSS classes to add for custom styling.
   */
  className: '',
  /**
   * Value of `display` CSS property when overlay should be visible.
   */
  displayStyle: 'block',
  /*
   * Configure use of fade transition when showing/hiding overlay.
   *
   * Can be overriden directly when showing or hiding.
   */
  transition: false,
};

/**
 * Add another element and it's related region to the overlay.
 *
 * @param {HTMLElement|jQuery} el The element to listen for mouse events for.
 * @param {HTMLElement} regionElement The region to show overlay on top of.
 */
RegionOverlay.prototype.watch = function watch(el, regionElement, content) {
  const domEl = el.jquery ? el.get(0) : el;
  this.aboveElement = el === regionElement;
  this.watchedElement = domEl;

  const eventHandler = (event) => {
    // Honor the disabled class on the element if present, thus not showing the overlay.
    if (domEl.className.indexOf('disabled') !== -1) {
      return;
    }

    if (
      event.type === 'mouseout' &&
      event.relatedTarget !== this.el &&
      !this.el.contains(event.relatedTarget)
    ) {
      this.hide();
      this.currentTarget = null;
      return;
    } else if (event.type === 'mouseover' && this.el.style.display === 'none') {
      this.show(regionElement, content);
      this.currentTarget = this.aboveElement ? this.el : domEl;
    }
  };

  // Clear any possible previous watches for same element
  this.unwatch(domEl);

  this.mouseEventHandler = eventHandler;
  domEl.addEventListener('mouseover', this.mouseEventHandler);

  if (this.aboveElement) {
    this.el.addEventListener('mouseout', this.mouseEventHandler);
  }

  domEl.addEventListener('mouseout', this.mouseEventHandler);
};

/**
 * Update position of the overlay element.
 *
 * @param {HTMLElement} domEl
 */
RegionOverlay.prototype.updatePositions = function updatePositions(domEl) {
  const rect =
    domEl instanceof Document
      ? Viewport.getBounds()
      : getAbsoluteBoundingClientRect(domEl);

  this.el.style.top = `${rect.top}px`;
  this.el.style.left = `${rect.left}px`;
  this.el.style.width = `${rect.width}px`;
  this.el.style.height = `${rect.height}px`;
};

/**
 * Stops listening to mouse events for an element.
 *
 * @param {HTMLElement|jQuery} el The element to stop listening to.
 */
RegionOverlay.prototype.unwatch = function unwatch(el) {
  const domEl = el.jquery ? el.get(0) : el;

  domEl.removeEventListener('mouseover', this.mouseEventHandler);

  if (this.aboveElement) {
    this.el.removeEventListener('mouseout', this.mouseEventHandler);
  }

  domEl.removeEventListener('mouseout', this.mouseEventHandler);

  if (domEl === this.currentTarget) {
    this.el.parentNode.removeChild(this.el);
  }

  this.watchedElement = null;
};

/**
 * Activates the overlay for the region of a specific element.
 *
 * @param {HTMLElement|Document|jQuery} el The element to overlay its related region
 *                                          for.
 */
RegionOverlay.prototype.show = function show(el, content = null) {
  const domEl = el.jquery ? el.get(0) : el;

  if (content instanceof HTMLElement) {
    this.el.appendChild(content);
  } else if (content) {
    this.el.innerHTML = content;
  }

  this.el.style.display = this.opts.displayStyle;
  this.updatePositions(domEl);

  window.document.body.appendChild(this.el);

  this.handleMovement = () => this.updatePositions(domEl);
  window.addEventListener('resize', this.handleMovement);
  window.parent.ScrollListener.on('scroll', this.handleMovement);
};

/**
 * Hides the overlay.
 */
RegionOverlay.prototype.hide = function hide() {
  /* TODO: Re-introduce transition support after removing jQuery-usage
    if (typeof transition === 'undefined') {
        transition = this.opts.transition;
    }
  */

  this.el.style.display = 'none';
  this.el.innerHTML = '';
};

RegionOverlay.prototype.destroy = function destroy() {
  if (this.watchedElement) {
    this.unwatch(this.watchedElement);
  }
  window.removeEventListener('resize', this.handleMovement);
  window.parent.ScrollListener.off('scroll', this.handleMovement);
  this.el.remove();
};

export default RegionOverlay;
