import Jed from 'jed';
import Norwegian from '../../mailmojo/assets/locale/nb/LC_MESSAGES/messages.po';

/**
 * Simple internationalisation module with support for only
 * gettext translation of strings in available languages.
 */
const currentLang = document.body.parentNode.getAttribute('lang') || 'en';
const translations = {
  en: {},
  nb: Norwegian,
};
const i18n = new Jed(translations[currentLang]);

export default i18n;
