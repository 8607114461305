import { Tooltip as FoundationTooltip } from 'foundation-sites/js/foundation.tooltip';

import { tooltipDefaults } from '../../common/foundation';

const jQuery = window.jQuery;

/**
 * Component for disabling send buttons when the send test email field has a value.
 *
 * @param {HTMLElement} buttonGroup The container that inclues the buttons.
 * @param {HTMLElement} input The input that we base disabling on.
 * @param {String} tipText Tooltip text when hovering the disabled buttons.
 */
const updateSendButtonState = (buttonGroup, input, tipText) => {
  if (buttonGroup.querySelector('button[disabled]')) {
    return;
  }

  const createTooltip = () =>
    new FoundationTooltip(jQuery(buttonGroup), {
      tipText,
      ...tooltipDefaults,
    });
  const buttons = Array.from(buttonGroup.querySelectorAll('button'));
  let tooltip;

  const updateDisabledState = (hasContent) => {
    if (hasContent && !tooltip) {
      tooltip = createTooltip();
    } else if (!hasContent && tooltip) {
      tooltip._destroy();
      // Foundation doesn't remove the title so we have to do it
      buttonGroup.removeAttribute('title');
      tooltip = null;
    }

    buttons.forEach((el) => Object.assign(el, { disabled: hasContent }));
  };

  updateDisabledState(input.value !== '');

  input.addEventListener('input', (event) => {
    updateDisabledState(event.target.value !== '');
  });
};

export default updateSendButtonState;
