import PropTypes from 'prop-types';
import withMediaQuery from './media-query';
import { inputPropsType } from './fields';

let currentId = 0;

/**
 * <Switch /> component.
 *
 * A stateless component for rendering a Foundation switch.
 *
 * @param {Boolean} props.disabled Whether or not the switch is disabled.
 * @param {Boolean} props.isActive State of the switch.
 * @param {Function} props.onChange Callback function when switch is changed.
 * @param {Function} props.viewportSize Current viewport size.
 * @param {String} props.toggleLabel Label for the toggle.
 * @param {String} props.onLabel Label for on state.
 * @param {String} props.offLabel Label for off state.
 * @return {ReactElement}
 */
const Switch = ({
  disabled,
  isActive,
  onChange,
  viewportSize,
  onLabel,
  offLabel,
  toggleLabel,
  inputProps,
  size,
}) => {
  const switchId = `switch-input-${(inputProps && inputProps.name) || currentId}`;
  const switchClass = isActive ? 'switch-active' : 'switch-inactive';

  // TODO: Use a generator for id's instead
  if (!inputProps?.name) {
    currentId += 1;
  }

  return (
    <div className={`switch ${size ?? viewportSize} margin-bottom-0`}>
      <input
        id={switchId}
        type="checkbox"
        className="switch-input"
        checked={isActive}
        onChange={onChange}
        disabled={disabled}
        {...inputProps}
      />
      <label className="switch-paddle" htmlFor={switchId}>
        <span className="show-for-sr">{toggleLabel}</span>
        <span className={switchClass} aria-hidden="true">
          {isActive ? onLabel : offLabel}
        </span>
      </label>
    </div>
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  viewportSize: PropTypes.string,
  toggleLabel: PropTypes.string,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  inputProps: inputPropsType,
  size: PropTypes.string,
};

Switch.defaultProps = {
  disabled: false,
  viewportSize: 'medium',
  toggleLabel: 'Toggle',
  onLabel: 'On',
  offLabel: 'Off',
  inputProps: null,
  size: null,
};

export default withMediaQuery(Switch);
