import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { locales as dateLocales } from 'common/lib/dates';

import { TranslationsContext } from './translate';
import { defaultLang } from '../i18n';

const STATUS_UPDATE_INTERVAL = 30000;

/**
 * Generate a string describing time since last save in current locale.
 *
 * @param {String|Number} lastSaved ISO datetime or UNIX timestamp.
 * @return {String}
 */
const calculate = (lastSaved) =>
  formatDistanceToNow(
    new Date(lastSaved.indexOf('T') > 0 ? lastSaved : lastSaved * 1000),
    {
      locale: dateLocales[defaultLang],
      addSuffix: true,
    }
  );

/**
 * <SavedStatus /> component.
 *
 * Displays a description of how long ago a newsletter was last saved as a
 * human readable sentence. The description is kept up to date at regular
 * intervals so it is always accurate.
 *
 * @param {String} props.lastSaved The last saved ISO time string.
 */
const SavedStatus = ({ lastSaved }) => {
  const i18n = useContext(TranslationsContext);
  const [timeago, setTimeago] = useState(calculate(lastSaved));

  useEffect(() => {
    const interval = setInterval(
      () => setTimeago(calculate(lastSaved)),
      STATUS_UPDATE_INTERVAL
    );

    setTimeago(calculate(lastSaved));

    return () => clearInterval(interval);
  }, [lastSaved]);

  return (
    <small>
      {i18n.sprintf(i18n.gettext('Saved %(timeago)s'), { timeago })}
    </small>
  );
};
SavedStatus.propTypes = {
  lastSaved: PropTypes.string.isRequired,
};

export default SavedStatus;
