import PropTypes from 'prop-types';
import { createContext, Component, useState, useEffect } from 'react';

import getTranslations, { catalogs, defaultLang } from '../i18n';

/**
 * Context that holds a Jed gettext catalog instance for a given language.
 */
export const TranslationsContext = createContext(catalogs.en);

/**
 * Component that provides a `TranslationsContext` for all children.
 *
 * The context is initialised with the default English catalog, and then
 * updated with the proper language catalog as soon as it is resolved.
 */
export const Translations = ({ children, lang }) => {
  const [catalog, setCatalog] = useState(catalogs.en);
  const isLoaded = catalog.options.locale_data.messages[''].lang === lang;

  useEffect(() => {
    getTranslations(lang).then(setCatalog);
  }, [lang]);

  return (
    isLoaded && (
      <TranslationsContext.Provider value={catalog}>
        {children}
      </TranslationsContext.Provider>
    )
  );
};

Translations.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
};

Translations.defaultProps = {
  children: null,
  lang: defaultLang,
};

export const i18nType = PropTypes.shape({
  gettext: PropTypes.func.isRequired,
  sprintf: PropTypes.func.isRequired,
});

/**
 * Create a Higher-order-Component (HoC) for translating a component.
 *
 * This function will return a function to wrap a component in a HoC
 * which provides it with an `i18n` property with translation functions.
 *
 * Currently the wrapped component will not be rendered until messages are
 * loaded.
 *
 * Usage example:
 *   const TranslatedComponent = translate()(MyComponent);
 *
 * @return {Function}
 */
export default function translate() {
  return function wrap(WrappedComponent) {
    return class Translate extends Component {
      state = {
        i18n: null,
      };

      componentDidMount() {
        getTranslations().then((i18n) => this.setState({ i18n }));
      }

      render() {
        const { i18n } = this.state;

        if (!i18n) {
          return null;
        }

        const props = { ...this.props, i18n };
        return <WrappedComponent {...props} />;
      }
    };
  };
}
