import debounce from 'lodash/debounce';
import { emitHeightToParent } from '../utils';

/**
 * Emit height of window `win` to the parent after load and resize events.
 *
 * This is in use for Embedded MailMojo.
 *
 * @param {Window} win
 */
const createHeightEmitter = (win) => {
  const resizeHandler = debounce(() => {
    emitHeightToParent();
  }, 100);
  const resizeObserver = new ResizeObserver(resizeHandler);
  resizeObserver.observe(win.document.body);

  /*
   * Reset parent iframe height to 0 (actually the viewport height) so that new height
   * can be calculated on each page refresh.
   */
  win.addEventListener('beforeunload', () => {
    emitHeightToParent(0);
  });
};

/**
 * Receive height sent from an inner iframe and set height on it.
 *
 * This is to avoid inner scrollbar for iframe inside the v3 app.
 * NOTE: Mainly in use for Mailmojo v2 content as it is served through an iframe inside
 * v3.
 *
 * @param {Window} win
 * @param {HTMLElement} iframe
 */
const createHeightReceiver = (win, iframe) => {
  win.addEventListener('message', (event) => {
    if (
      event.origin.indexOf('.mailmojo.') !== -1 &&
      event.data &&
      event.data.contentHeight
    ) {
      iframe.setAttribute('height', event.data.contentHeight);
      emitHeightToParent();
    }
  });
};

export { createHeightEmitter, createHeightReceiver };
