import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-US';
import nb from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';

const locales = { da, en, nb, sv };

/**
 * Map of date formatting symbols from strftime to date-fns format.
 */
const formatSymbols = {
  a: 'EE',
  A: 'EEEE',
  d: 'dd',
  e: 'd',
  U: 'w',
  W: 'w',
  V: 'w',
  b: 'MMM',
  B: 'MMMM',
  h: 'MMM',
  m: 'MM',
  g: 'yy',
  G: 'yyyy',
  y: 'yy',
  Y: 'yyyy',
  H: 'HH',
  M: 'mm',
  R: 'HH:mm',
  T: 'HH:MM:ss',
};

const SHORT_DATE_FORMAT = 'PPPP';

/**
 * Convert date formatting string from strftime to date-fns syntax.
 *
 * @param {String} str Date format string in strftime syntax (eg. "%d").
 * @return {String}
 */
const convertFormat = (str) => {
  const characters = Object.keys(formatSymbols).join('');

  return str.replace(
    new RegExp(`%[${characters}]`, 'g'),
    (match) => formatSymbols[match.substr(1)] || ''
  );
};

/**
 * Simple wrapper around `date-fns/format`.
 *
 * Makes sure locale is converted from string name to locale module, and
 * supports strftime format string automatically. Parameters are the same as
 * for `date-fns/format`.
 *
 * @param {Date|String|Number} date
 * @param {String} formatStr
 * @param {Object} options
 * @return {String}
 */
const format = (date, formatStr, options = {}) => {
  const locale = options.locale ? locales[options.locale] : en;
  let str = formatStr;

  if (formatStr.indexOf('%') !== -1) {
    str = convertFormat(formatStr);
  }

  return formatDate(typeof date === 'string' ? parseISO(date) : date, str, {
    ...options,
    locale,
  });
};

export { format, locales, SHORT_DATE_FORMAT };
