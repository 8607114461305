import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import sortBy from 'lodash/sortBy';

import Dropdown from 'common/lib/components/dropdown';
import i18n from '../i18n';
import { BrowserContext } from '../contexts';

import { FilterType, IntegrationSettingsType } from '../types';

/**
 * Count number of filters that are active.
 *
 * We exclude the integration as its required and if the filter is an array we
 * include the number of items in it too.
 *
 * @param {Object} filters
 * @returns Number
 */
const countFilters = (filters) =>
  Object.keys(filters).reduce((total, key) => {
    if (key !== 'integration' && key !== 'query' && key !== 'order') {
      return total + (Array.isArray(filters[key]) ? filters[key].length : 1);
    }

    return total;
  }, 0);

/**
 * Render a checkbox field filter.
 *
 * @param {Object} filter
 * @param {Object} current
 * @param {Function} updateFilter
 */
const BooleanFilter = ({ filter, current, updateFilter }) => (
  <>
    <input
      type="checkbox"
      checked={!!current[filter.id]}
      onChange={({ target }) => {
        updateFilter({
          [filter.id]: target.checked || undefined,
        });
      }}
    />
    <span className="padding-left-small">{filter.name}</span>
  </>
);
BooleanFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  current: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

/**
 * Render a select field filter.
 *
 * @param {Object} filter
 * @param {Object} current
 * @param {Function} updateFilter
 */
const SelectFilter = ({ filter, current, updateFilter }) => (
  <select
    name={filter.id}
    value={current[filter.id] ? current[filter.id] : ''}
    onChange={({ target }) => {
      updateFilter({
        [filter.id]: target.value ? target.value : undefined,
      });
    }}
  >
    <option value="">{filter.name}</option>
    {filter.choices.map((choice) => (
      <option key={choice} value={choice}>
        {choice}
      </option>
    ))}
  </select>
);

SelectFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  current: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

/**
 * Filter component.
 *
 * @param {Object} props.current The current Filter.
 * @param {Boolean} props.disabled
 * @param {Object} props.settings
 * @returns {React.Component}
 */
const Filter = ({ current, disabled, settings }) => {
  const context = useContext(BrowserContext);
  const [query, setQuery] = useState('');
  const { content_tags = [], content_filters = [] } = settings;
  const { tags = [] } = current;
  const highlightSelected = content_tags.length > 24;
  const numFilters = countFilters(current);
  let filteredTags = sortBy(content_tags, ['name']);

  const reset = () => {
    setQuery('');

    /*
     * Figure out which active filters are dynamic, integration specific content
     * filters. These will need to be set as `undefined` in the new, updated filter to
     * explicitly remove them from active filters.
     */
    const contentFiltersToClear = Object.entries(current).reduce(
      (toClear, [filterKey, _]) => {
        const isContentFilter = !!content_filters.find(({ id }) => id === filterKey);

        if (isContentFilter) {
          toClear[filterKey] = undefined;
        }

        return toClear;
      },
      {}
    );

    updateFilter({ ...contentFiltersToClear, tags: [] });
  };

  const handleToggle = ({ target }) => {
    const selected = new Set(tags);
    if (target.checked) {
      selected.add(target.value);
    } else {
      selected.delete(target.value);
    }
    updateFilter({ tags: Array.from(selected) });
  };

  const updateFilter = (newFilter) => {
    /*
     * Defer actual context update until after `onChange` event processing is
     * complete. This ensures Foundation's Dropdown component processes the
     * click as inside the dropdown pane, before we re-render the dropdown pane
     * contents with updated filter.
     */
    setTimeout(() => {
      context.updateFilter(newFilter);
    }, 0);
  };

  if (query && query.length > 1) {
    filteredTags = filteredTags.filter(
      (tag) => tag.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  }

  const createTagItem = (tag) => (
    <li key={tag.id}>
      <label style={{ cursor: 'pointer' }}>
        <input
          type="checkbox"
          checked={tags.includes(tag.id)}
          defaultValue={tag.id}
          onChange={handleToggle}
        />
        <span className="padding-left-small">{tag.name}</span>
      </label>
    </li>
  );

  return (
    <>
      <button
        className={`button dropdown secondary ${disabled ? 'disabled' : ''}`}
        data-toggle="filter-dropdown"
        onClick={(e) => e.currentTarget.blur()}
        disabled={disabled}
      >
        {numFilters === 0
          ? i18n.gettext('Filter')
          : i18n.sprintf(i18n.gettext('Filter (%(numFilters)s)'), {
              numFilters: numFilters,
            })}
      </button>
      <Dropdown id="filter-dropdown">
        {content_tags.length > 12 && (
          <>
            <div className="padding-bottom-small">
              <input
                type="text"
                value={query}
                placeholder={i18n.gettext('Search')}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
            {highlightSelected && tags.length > 0 && (
              <ul className="no-bullet scrollable-filter-list">
                {tags.map((tagId) => {
                  const tag = content_tags.find((t) => t.id === tagId);
                  return createTagItem(tag);
                })}
              </ul>
            )}
          </>
        )}
        <ul
          className="no-bullet scrollable-filter-list"
          style={{ overflowY: 'scroll' }}
        >
          {filteredTags
            .filter((tag) => !highlightSelected || !tags.includes(tag.id))
            .map(createTagItem)}
        </ul>
        {filteredTags.length === 0 && (
          <p>
            <em>{i18n.gettext('No match.')}</em>
          </p>
        )}
        {content_filters.length > 0 && (
          <div className="padding-top">
            <ul className="no-bullet border-top padding-top padding-bottom">
              {content_filters.map((filter) => (
                <li key={filter.id}>
                  <label style={{ cursor: 'pointer' }}>
                    {filter.type === 'BooleanField' && (
                      <BooleanFilter
                        filter={filter}
                        current={current}
                        updateFilter={context.updateFilter}
                      />
                    )}
                    {filter.type === 'SelectField' && filter.choices.length > 1 && (
                      <SelectFilter
                        filter={filter}
                        current={current}
                        updateFilter={context.updateFilter}
                      />
                    )}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}
        {numFilters > 0 && (
          <div className="padding-top">
            <button
              type="button"
              className="button secondary compact"
              onClick={() => reset()}
            >
              {i18n.gettext('Reset')}
            </button>
          </div>
        )}
      </Dropdown>
    </>
  );
};

Filter.propTypes = {
  current: FilterType.isRequired,
  disabled: PropTypes.bool.isRequired,
  settings: IntegrationSettingsType.isRequired,
};

export default Filter;
