import getTranslations from '../i18n';

const jQuery = window.jQuery;

/**
 * Setup form submit loader on specified button.
 *
 * When clicking on a submit button in a form we replace the content of the
 * button with "Please wait…" text and disable it. This is to prevent multiple
 * submits and let the user know that something is actually happening.
 *
 * One thing to remember is that all native input validation and Abide validation
 * prevents the submit event to //ever// happen. So in that case we don't need to
 * disable the submit button. That is why we only disable the button when the
 * submit event will occur.
 *
 * @param {HTMLElement} button
 */
const setup = (button) => {
  const { form } = button;
  const content = button.innerHTML;
  const isLoading = () => button.classList.contains('is-loading');

  getTranslations().then((i18n) => {
    const hide = () => {
      if (isLoading()) {
        button.classList.remove('is-loading');
        Object.assign(button, { innerHTML: content, disabled: false });
      }
    };

    const show = () => {
      if (!isLoading()) {
        button.classList.add('is-loading');
        Object.assign(button, { innerHTML: i18n.gettext('Please wait…') });
      }
    };

    const disableButton = (event) => {
      /*
       * If validations are invalid we can safely return right away because
       * the submit has been prevented thus we don't need to disable the button.
       */
      if (event.defaultPrevented) {
        return false;
      }

      Object.assign(button, { disabled: true });
      return true;
    };

    form.addEventListener('submit', disableButton);
    button.addEventListener('click', show);

    if (form.getAttribute('novalidate') === null) {
      // Invalid event doesn't bubble, so use capture
      form.addEventListener('invalid', hide, true);
    } else {
      jQuery(form).on('forminvalid.zf.abide', () => {
        setTimeout(hide, 10);
      });
    }
  });
};

export default setup;
