import PropTypes from 'prop-types';
import { useContext } from 'react';
import i18n from '../i18n';

import { BrowserContext } from '../contexts';

const ChooseButton = ({ isActive, item, imageIndex = 0 }) => {
  const context = useContext(BrowserContext);
  return (
    <button
      type="button"
      className="button primary compact"
      disabled={!isActive}
      onClick={() => {
        context.onSelect({ item, imageIndex });
        context.onClose();
      }}
    >
      {i18n.gettext('Choose')}
    </button>
  );
};

ChooseButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  imageIndex: PropTypes.number,
};

ChooseButton.defaultProps = {
  item: null,
  imageIndex: -1,
};

export default ChooseButton;
