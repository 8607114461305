import PropTypes from 'prop-types';

import Icon from './icon';

import '../../scss/components/spinner.scss';

const Spinner = () => (
  <div className="spinner">
    <Icon name="freehand/loading-circles" classNames="large" />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

export default Spinner;
