/**
 * Controller for handling processing of form submits.
 *
 * When a form is submitted, we want to both prevent any further submits of the
 * same form, and also indicates that a submit request has been sent. The
 * latter due to the potential for form submits to require extended processing
 * in the backend.
 */
import BaseObject from '../../utils/base-object';
import updateState from '../../state';

const Controller = Object.assign(Object.create(BaseObject), {
  /**
   * Initialize controller.
   *
   * Sets up the event listener for the forms submit event.
   */
  init() {
    this.isProcessing = false;

    // We use another form submit loader for abide's, so we have to disable this.
    if (this.form.dataset.abide !== undefined) {
      return;
    }

    this.form.addEventListener('submit', this.handleSubmit.bind(this));
    this.form.addEventListener('resetProcessing', this.reset.bind(this));
  },

  /**
   * Filter all submit events.
   *
   * The first submit event will be allowed, but any subsequent submits will be
   * prevented to avoid duplicate submissions.
   *
   * @param {Event} event
   */
  handleSubmit(event) {
    if (this.isProcessing) {
      event.preventDefault();
      return false;
    }

    this.isProcessing = true;
    updateState('processing', true);
    return true;
  },

  reset() {
    if (this.isProcessing) {
      this.isProcessing = false;
      updateState('processing', false);
    }
  },
});

Controller.defaults = {
  form: document.querySelector('form'),
};

/**
 * Utility function to trigger the `resetProcessing` event on a form.
 *
 * This will reset the processing indicator on the form, and allow a new
 * submit. Use case is when external logic aborts the form submit and
 * wants to allow a re-submit.
 *
 * TODO: The controller itself should rather have a method, but we currently
 * don't expose the controller instance for each form anywhere.
 *
 * @param {HTMLElement} form The form to reset processing for.
 */
const resetProcessing = (form) => {
  const event = document.createEvent('Event');
  event.initEvent('resetProcessing', true, true);
  form.dispatchEvent(event);
};

export { resetProcessing };

export default Controller;
