import { baseTemplateStepController } from '../../steps';

import { initFavoriteActions, initModal } from '../../templates/init';

/**
 * Template selection step controller.
 *
 * Handles favoring templates, display a warning when changing to another template
 * and double click which automatically selects the template and proceeds.
 */
const TemplatesStep = {
  controller: null,

  init(templatesForm) {
    this.form = templatesForm;
    this.controller = baseTemplateStepController(templatesForm);
    this.controller.init();

    initModal({
      primaryButtonHandler: (id, selectedPalette) => {
        this.setPalette(selectedPalette);
        this.controller.selectAndProceed(id);
      },
      step: this,
    });

    initFavoriteActions();
    return this;
  },

  setPalette(palette) {
    Object.assign(this.form.palette, { value: palette });
  },

  getNextUrl(url) {
    return this.controller.getNextUrl(url);
  },
};

export default TemplatesStep;
