import _get from 'lodash/get';
import _set from 'lodash/set';

const EVENT_NAME = 'statechange';
let currentState;

/**
 * Convenience function for adding a listener for the state change event.
 *
 * @param {Function} fn
 * @return {Function} A function to remove the event listener.
 */
const listen = (fn) => {
  window.addEventListener(EVENT_NAME, fn);

  return () => {
    window.removeEventListener(EVENT_NAME, fn);
  };
};

/**
 * Dispatch a new state change event with current state.
 *
 * @param {Object} state
 */
const notify = (state) => {
  const event = new CustomEvent(EVENT_NAME, { detail: state });
  window.dispatchEvent(event);
};

/**
 * Return a specific part of the state.
 *
 * @param {String} path Path to the item to retrieve.
 * @return {Mixed}
 */
const get = (path) => {
  if (!currentState) {
    currentState = window.MailMojo.State || {};
  }
  return _get(currentState, path);
};

/**
 * Update part of the state.
 *
 * States is immutably updated, meaning a new version of the state object
 * is created before change is applied.
 *
 * On first update, initial state will be read from `window.MailMojo.State` if
 * defined.
 *
 * @param {Array} path Path to item in state to update.
 * @param {Mixed} value Value to set at path or a callback function that will
 *                      receive current state for the given path.
 * @return {Object} Updated state.
 */
const update = (path, value) => {
  if (!currentState) {
    currentState = window.MailMojo.State || {};
  }

  currentState = { ...currentState };
  _set(
    currentState,
    path,
    typeof value === 'function' ? value(_get(currentState, path)) : value
  );

  notify(currentState);

  return currentState;
};

export { get, listen as onChange };

export default update;
