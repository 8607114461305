import { createQueryString, once, parseQueryString } from './utils';

const DEFAULT_SCOPE = [
  'account_settings',
  'automations',
  'contacts',
  'content',
  'forms',
  'lists',
  'newsletters',
  'pages',
  'templates',
].join(' ');

// XXX: Consider passing this explicitly in to an "auth creator" function
const config = (window.MailMojo && window.MailMojo.Auth) || {};
const currentUser = (window.MailMojo && window.MailMojo.User) || {};

const getTokenId = (scope) => `${currentUser.username}=${scope}`;

/**
 *  Get token object from SessionStorage.
 *
 *  TODO: Look into a smarter scope lookup so that we can reuse ``newsletters:read``'
 *  for example if a token for ``newsletters`` exists.
 *
 * @param {String} scope
 */
const getSessionToken = (scope) => {
  try {
    return JSON.parse(sessionStorage.getItem(getTokenId(scope)));
  } catch (e) {
    return null;
  }
};

/**
 *  Save token object in SessionStorage.
 *
 * @param {String} scope
 * @param {String} newToken
 */
const saveSessionToken = (scope, newToken) => {
  try {
    sessionStorage.setItem(getTokenId(scope), JSON.stringify(newToken));
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Storage of last retrieved token.
 *
 * @type {Object}
 */
let token = getSessionToken(DEFAULT_SCOPE);
let isLoading = false;

/**
 * Attempts to extract a token response from an auth frame.
 *
 * @throws {Error} If the auth frame location contains an error message
 *                 or if we can't inspect the frames location due to CORS.
 * @param {HTMLElement} authFrame
 * @return {Object} Token information, including absolute expiry time.
 */
const extractToken = (authFrame) => {
  const { location } = authFrame.contentWindow;
  const response = parseQueryString((location.search || location.hash).substr(1));

  if (response.error) {
    throw new Error(response.error);
  }

  if (location.toString().indexOf(config.redirectUrl) === -1) {
    throw new Error('invalid_response');
  }

  response.expires = Date.now() + parseInt(response.expires_in, 10) * 1000;

  return response;
};

/**
 * Get the <iframe/> element used for OAuth authorization.
 *
 * @return {HTMLElement}
 */
const getAuthFrame = () => {
  let iframe = document.querySelector('iframe[name="mm-auth"]');

  if (!iframe) {
    iframe = document.createElement('iframe');

    iframe.setAttribute('name', 'mm-auth');
    iframe.setAttribute('width', 0);
    iframe.setAttribute('height', 0);
    iframe.setAttribute('frameBorder', 0);
    iframe.setAttribute('scrolling', 'no');

    document.body.appendChild(iframe);
  }

  return iframe;
};

/**
 * Validate a token to not be expired and match a scope.
 *
 * Currently expects the scope to be an exact string as used by the token
 * to validate.
 *
 * TODO: Handle scope as a space-separated string of scopes and validate
 * that the token contains all scopes.
 *
 * @param {Object} token
 * @param {String} scope
 * @return {Boolean}
 */
const isValid = (candidateToken, scope) =>
  candidateToken &&
  candidateToken.expires >= Date.now() &&
  candidateToken.scope === scope;

/**
 * Get an OAuth token for requested scope.
 *
 * Attempts to use implicit grant through a GET request in an <iframe/> for
 * the currently configured OAuth client.
 *
 * Since we re-use the same frame for each token request, if we are in the
 * midst of fetching a token we await it completing before possibly starting
 * a new token request. But if we've previously retrieved a token and it is
 * still valid for the scope requested we simply return it for re-use.
 *
 * All newly created tokens are stored in SessionStorage and will be reused until it
 * expires or a new scope is requested.
 *
 * @param {String} scope Required definition of scope for the token.
 * @param {Boolean} refresh Forces fetching a new token even if a valid token
 *                          exists.
 * @return {Promise}
 */
const getToken = (scope = DEFAULT_SCOPE, refresh = false) => {
  const authFrame = getAuthFrame();
  const sessionToken = getSessionToken(scope);

  if (sessionToken) {
    token = sessionToken;
  }

  if (isLoading) {
    return once(authFrame, 'load').then(() => getToken(scope, refresh));
  }

  return new Promise((resolve, reject) => {
    if (isValid(token, scope) && !refresh) {
      resolve(token.access_token);
      return;
    }

    isLoading = true;

    const authorizeParams = {
      scope,
      client_id: config.clientId,
      redirect_uri: config.redirectUrl,
      response_type: 'token',
      state: Math.random().toString(36).substring(2, 15),
    };

    once(authFrame, 'load').then(() => {
      isLoading = false;

      try {
        const newToken = extractToken(authFrame);

        if (newToken && newToken.state === authorizeParams.state) {
          token = newToken;
          saveSessionToken(scope, newToken);
          resolve(token.access_token);
        } else {
          reject(new Error('invalid_state'));
        }
      } catch (e) {
        reject(e);
      }
    });

    authFrame.setAttribute(
      'src',
      `${config.authorizeUrl}?${createQueryString(authorizeParams)}`
    );
  });
};

export default getToken;
