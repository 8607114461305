import { ContentItemType } from '../../types';

/**
 * ArticleContentItem component.
 *
 * @param {object} props.item
 * @returns {React.Component}
 */
const ArticleContentItem = ({ item }) => {
  const { data } = item;

  return (
    <>
      <p className="flex-child-grow padding-bottom-small">
        <strong>{data.title}</strong>
      </p>
    </>
  );
};

ArticleContentItem.propTypes = {
  item: ContentItemType.isRequired,
};

export default ArticleContentItem;
