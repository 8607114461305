import PropTypes from 'prop-types';
import { useContext } from 'react';

import Dropdown from 'common/lib/components/dropdown';
import i18n from '../i18n';
import { BrowserContext } from '../contexts';

/**
 * Ordering component.
 *
 * @param {Object} props.current The current ordering for the content items.
 * @param {Boolean} props.disabled
 * @returns {React.Component}
 */
const Ordering = ({ current, disabled }) => {
  const context = useContext(BrowserContext);
  const options = [
    { key: '-created_at', value: i18n.gettext('Newest First') },
    { key: 'created_at', value: i18n.gettext('Oldest First') },
    { key: '-updated_at', value: i18n.gettext('Last Updated') },
  ];
  return (
    <>
      <button
        className={`button dropdown secondary ${disabled ? 'disabled' : ''}`}
        data-toggle="order-dropdown"
        onClick={(e) => e.currentTarget.blur()}
        disabled={disabled}
      >
        {i18n.gettext('Sort')}
      </button>
      <Dropdown id="order-dropdown">
        <ul className="no-bullet scrollable-filter-list">
          {options.map((option) => (
            <li key={option.key}>
              <label style={{ cursor: 'pointer' }}>
                <input
                  type="radio"
                  name="order-options"
                  defaultChecked={current === option.key}
                  onChange={({ target }) => {
                    context.updateFilter({ order: option.key });
                  }}
                />
                <span className="padding-left-small">{option.value}</span>
              </label>
            </li>
          ))}
        </ul>
      </Dropdown>
    </>
  );
};

Ordering.propTypes = {
  current: String.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Ordering;
