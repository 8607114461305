import { useState } from 'react';

/**
 * Custom hook for managing form data state.
 *
 * @param {object} form  The initial form data.
 * @param {string[]} props  The properties to track in the form data.
 * @returns {object}  An object with data management functions.
 */
const useFormConfig = (form, props = []) => {
  const initialValue = {
    id: null,
    current: null,
    error: null,
    previousError: null,
  };

  const transform = (obj, existingState) => {
    const newState = { ...existingState };
    const { current, error } = existingState;
    const previousErrors = error || {};
    const newErrors = obj.errors?.config || {};
    const mergedErrors = Object.entries({
      ...previousErrors,
      ...newErrors,
    }).reduce((filteredErrors, [key, value]) => {
      if (key !== current) {
        Object.assign(filteredErrors, { [key]: value });
      }
      return filteredErrors;
    }, {});

    newState.id = obj.id;
    props.forEach((prop) => {
      if (obj.config.hasOwnProperty(prop)) {
        newState[prop] = obj.config[prop];
      }
    });

    newState.error = Object.keys(mergedErrors).length ? mergedErrors : null;

    return newState;
  };

  const [data, setData] = useState(transform(form, initialValue));

  const updater = (updatedForm) =>
    setData((prevState) => transform(updatedForm, prevState));

  const updateData = (data) => {
    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
      setData((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    } else {
      throw new Error('Invalid data format. Please provide a non-null object.');
    }
  };

  return {
    data,
    updateForm: updater,
    updateData: updateData,
  };
};

export { useFormConfig };
