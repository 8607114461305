/* eslint-disable no-var, object-shorthand */
import { getScrollTop } from './compat';

/**
 * Filter message events for an event handler.
 *
 * Message events are required to have defined the type of data passed as
 * scroll data.
 *
 * @param Function handler The final event handler for valid messages.
 * @return Function Raw event handler.
 */
const filterMessageEvents = (handler) => (event) => {
  if (
    event.data &&
    Object.prototype.hasOwnProperty.call(event.data, 'type') &&
    event.data.type === 'scroll'
  ) {
    handler(event);
  }
};

/**
 * Listener on scroll events in the topmost window.
 *
 * Attempts to attach a direct DOM event handler for the `scroll` event
 * on the topmost window. If the topmost window is on a different origin
 * this will fail (cross-origin protection), and we rather attach a
 * `postMessage` listener that can handle scroll events posted to us from
 * the external page. Our API embed JavaScript posts these events for us.
 *
 * This gives us a common API for our app to listen to scroll events in the
 * topmost window.
 *
 * TODO: This currently implements a small 'event emitter' interface, which
 * can be removed when core app uses CommonJS modules by making use of the
 * event emitter module in `editor/events.js`.
 */
const ScrollListener = {
  /**
   * Initialize the listener.
   *
   * Sets up the event listener for scroll events, and configures
   * options. Currently a `threshold` option can be set to configure
   * the delay after last scroll event for when to consider scrolling
   * being done.
   *
   * @param {Object} opts Optional options.
   */
  init: function init(opts) {
    var listener = this.handleEvent.bind(this);

    this.opts = Object.assign(
      {
        threshold: 100,
      },
      opts
    );

    this.lastEventData = null;
    this.listeners = { start: [], scroll: [], end: [] };

    try {
      window.top.addEventListener('scroll', listener);
      this.isExternal = false;

      window.top.addEventListener('load', () => {
        // Trigger an initial event on load to pass on window bounds
        listener({ type: 'scroll' });
      });

      window.top.addEventListener('resize', listener);

      window.addEventListener('unload', () => {
        window.top.removeEventListener('scroll', listener);
        window.top.removeEventListener('resize', listener);
      });
    } catch (e) {
      window.addEventListener('message', filterMessageEvents(listener));
      this.isExternal = true;
    }
  },

  /**
   * Return data of last event emitted, if any.
   */
  getLastEvent: function getLastEvent() {
    return this.lastEventData;
  },

  /**
   * Send an event to all listeners of the event type, passing along
   * any arguments.
   *
   * @param {String} event Name of the event.
   */
  emit: function emit(event, ...args) {
    this.listeners[event].forEach((listener) => {
      listener(...args);
    });

    if (event === 'end') {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  },

  /**
   * Attach a listener for the specified event.
   *
   * Currently supported events are `start`, `scroll` and `end`.
   *
   * @param {String} event Name of the event.
   * @param {Function} listener The event listener callback.
   */
  on: function on(event, listener) {
    if (this.listeners[event]) {
      this.listeners[event].push(listener);
    }
  },

  /**
   * Remove a listener for the specified event.
   *
   * @param {String} event Name of the event.
   * @param {Function} listener The event listener to remove.
   */
  off: function off(event, listener) {
    var index;

    if (this.listeners[event]) {
      index = this.listeners[event].indexOf(listener);
      if (index !== -1) {
        this.listeners[event].splice(index, 1);
      }
    }
  },

  /**
   * Handle `scroll` or `message` events.
   *
   * Reads out the current scroll position and triggers appropriate
   * events. On the first scroll event we trigger a `start` and `scroll`
   * event. For subsequent scroll events we only trigger `scroll`.
   * Lastly, after no scroll events within the threshold time set in
   * options, we trigger an `end` event.
   *
   * All events triggered are passed an object with some information about
   * the scroll. The main property is `position` which gives the current
   * scroll top position. The property `isExternal` indicates if the
   * scroll window is external or not (same-origin). Finally we support
   * receiving an `offsetTop` property through `postMessage` from
   * external embed, which should indicate the top offset of the embed
   * inside the external window.
   *
   * @param {Event} event The DOM event.
   */
  handleEvent: function handleEvent(event) {
    const eventData = { isExternal: this.isExternal, offsetTop: 0 };

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (event.type === 'scroll' || event.type === 'resize') {
      eventData.position = getScrollTop(window.top);
      eventData.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    } else if (event.data && event.data.type === 'scroll') {
      eventData.position = event.data.value;

      if (event.data.offsetTop) {
        eventData.offsetTop = event.data.offsetTop;
      }

      if (event.data.windowSize) {
        eventData.windowSize = event.data.windowSize;
      }
    }

    if (!this.timeout) {
      this.emit('start', eventData);
    }

    this.emit('scroll', eventData);
    this.lastEventData = eventData;

    this.timeout = setTimeout(
      this.emit.bind(this, 'end', eventData),
      this.opts.threshold
    );
  },
};

window.ScrollListener = ScrollListener;

export default ScrollListener;
