interface SyncResult {
  created: number;
  updated: number;
}

type ResultData = SyncResult;

type JobResult = {
  status: string;
  result?: ResultData;
};

const jobIsDone = (status: string): boolean =>
  ['finished', 'stopped', 'canceled', 'failed'].indexOf(status) !== -1;

/**
 * Helper function to await completion of an RQ job, and retrieve the result if the
 * job was successful. We also return the status of the job.
 *
 * @param jobId The RQ job ID to resolve result of.
 * @param checkInterval Time in seconds between job status checks for results.
 * @returns Status of job when done, and result if job was successful.
 */
const getJobResult = async (
  jobId: string,
  checkInterval = 5
): Promise<JobResult> => {
  const sleep = (secs: number): Promise<void> =>
    new Promise((cont) => setTimeout(cont, secs * 1000));
  const fetchStatus = async () => {
    const response = await fetch(`/jobs/${jobId}/status/`);
    return (await response.json()) as JobResult;
  };
  let data: JobResult = await fetchStatus();

  while (!jobIsDone(data.status)) {
    await sleep(checkInterval);
    data = await fetchStatus();
  }

  return {
    status: data.status,
    result: data.result,
  };
};

export { getJobResult };
