import '../scss/app.scss';

import './compat';
import initializeCommon from './common/init';
import initializeNewsletters from './newsletters/init';
import { createHeightEmitter, createHeightReceiver } from './components/height-events';
import { getStep as getFormStep } from './forms/steps-controllers';
import { getStep as getNewsletterStep } from './newsletters/steps-controllers';
import { getStep as getPageStep } from './pages/steps-controllers';

initializeCommon();
initializeNewsletters();

const embeddedContent = document.querySelector('.embedded-content');
const path = window.location.pathname;

if (
  path === '/' ||
  path.substr(0, 5) === '/auth' ||
  path.substr(0, 9) === '/overview' ||
  path.substr(0, 15) === '/account/fields'
) {
  import('./accounts/init').then(({ default: init }) => init());
} else if (path.substr(0, 13) === '/subscription' || path.substr(0, 8) === '/account') {
  import('./subscriptions/init').then(({ default: init }) => init());
} else if (
  path.substr(0, 11) === '/automation' ||
  path.substr(0, 10) === '/campaigns'
) {
  import('./automation/init').then(({ default: init }) => init());
  import('./steps').then(({ default: init }) => init({ step: getNewsletterStep() }));
} else if (path.substr(0, 10) === '/templates') {
  import('./templates/init').then(({ default: init }) => init());
} else if (path.substr(0, 6) === '/forms') {
  import('./steps').then(({ default: init }) => init({ step: getFormStep() }));
} else if (path.substr(0, 6) === '/pages') {
  import('./steps').then(({ default: init }) => init({ step: getPageStep() }));
}

if (embeddedContent) {
  createHeightReceiver(global, embeddedContent);
}

createHeightEmitter(global);
