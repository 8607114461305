/**
 * Constants for action types and data status.
 */
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';
const STATUS_LOADED = 'loaded';
const STATUS_LOADING_NEXT_PAGE = 'loading_next_page';

export {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_LOADED,
  STATUS_LOADING_NEXT_PAGE,
};
