import PropTypes from 'prop-types';

import '../../scss/components/progress-bar.scss';

const getBackgroundClass = (progress) =>
  `progress-${Math.floor(progress / 25) * 25}`;

/**
 * A stateless progress bar component.
 *
 * Renders a progress bar given a progress between 0 and a specified max value.
 *
 * @param {Number} props.progress Amount of progress, between 0 and `maxValue`.
 * @param {Number} props.maxValue Maximum amount of progress (defaults to 100).
 */
const ProgressBar = ({ progress, maxValue }) => (
  <div
    className="progress"
    role="progressbar"
    aria-valuenow={progress}
    aria-valuemin="0"
    aria-valuemax={maxValue}
  >
    <div
      className={`progress-meter ${getBackgroundClass(progress)}`}
      style={{ width: `${progress}%` }}
    />
  </div>
);

ProgressBar.defaultProps = {
  maxValue: 100,
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
};

export default ProgressBar;
