import PropTypes from 'prop-types';
import { Component } from 'react';
import { Tooltip as FoundationTooltip } from 'foundation-sites/js/foundation.tooltip';

import { format } from 'common/lib/dates';
import { getReveal, tooltipDefaults } from '../../common/foundation';
import GoogleAnalyticsChecklistItem from './ga-checklist-item';
import SenderChecklistItem from './sender-checklist-item';
import PreviewTextChecklistItem from './previewtext-checklist-item';
import RecipientsChecklistItem from './recipients-checklist-item';
import SubjectChecklistItem from './subject-checklist-item';
import TitleChecklistItem from './title-checklist-item';
import { TranslationsContext } from '../../components/translate';
import { extractNormalizedProps, patch } from '../../utils/api';
import getSocket from '../../websockets';

const jQuery = window.jQuery;

const generateCampaignName = (subject) =>
  `${format(Date.now(), 'yyyy-MM-dd')}: ${subject}`;

/**
 * <Checklist /> component.
 *
 * A component for rendering newsletter checklist before sending.
 *
 * @param {Object} props.newsletter Newsletter data.
 * @param {Object} props.user User info.
 * @param {Object} props.urls Save URLs.
 */
class Checklist extends Component {
  constructor(props) {
    super(props);

    const {
      subject,
      title,
      preview_text: previewText,
      utm_campaign: utmCampaign,
    } = props.newsletter;
    const { from_email: fromEmail, from_name: fromName } = props.newsletter.list;

    this.state = {
      subject,
      title,
      previewText,
      utmCampaign,
      fromEmail,
      fromName,
      current: null,
      error: null,
      isSaving: false,
      previousError: null,
      // The senderValidated field was previously used to confirm sender addresses on
      // an email list via websocket changes. It is now obsolete since we shifted to
      // verifying only the domain, not the sender address. This variable is retained
      // for potential future use.
      senderValidated: null,
      senderValid: null,
      sendingDisabled: false,
      defaultCampaignName: generateCampaignName(subject),
    };
  }

  componentDidMount() {
    const { newsletter } = this.props;
    const { current, error } = this.state;

    this.sendTestContainer = document.querySelector('.send-test');
    this.sendButtonsContainer = document.querySelector('.send .button-group');

    if (
      this.sendButtonsContainer &&
      this.sendButtonsContainer.querySelector('button[disabled]')
    ) {
      this.setState({ sendingDisabled: true });
    }

    if (newsletter.medium === 'email' && !newsletter.is_in_automation) {
      document
        .querySelectorAll('#send-form button[type="submit"]')
        .forEach((button) => {
          button.addEventListener('click', (e) => {
            if (!this.state.senderValid) {
              e.preventDefault();
              getReveal('invalid-sender-domain-' + e.target.value)?.open();
            }
          });
        });
    }

    if (newsletter.medium === 'email') {
      getSocket().then((socket) => {
        this.socket = socket;

        socket.on('sender_address_validated', ({ lid, fromEmail }) => {
          if (newsletter.list.id === lid) {
            this.setState({
              fromEmail,
              current: current === 'sender' ? null : current,
              error: current === 'sender' ? null : error,
              senderValidated: true,
            });
          }
        });
      });
    }
  }

  /**
   * Handle changes to the validity of sender email.
   *
   * When sender email is invalid we disable the send test form and send
   * buttons, with an appropriate tooltip explaining why.
   *
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, { senderValidated: prevSenderValidated }) {
    const i18n = this.context;
    const { senderValidated, sendingDisabled } = this.state;

    if (!sendingDisabled && prevSenderValidated !== senderValidated) {
      const elements = [
        ...Array.from(this.sendTestContainer.querySelectorAll('input, button')),
        ...Array.from(this.sendButtonsContainer.querySelectorAll('button')),
      ];
      elements.forEach((el) => Object.assign(el, { disabled: !senderValidated }));

      if (!senderValidated) {
        this.createTooltip(i18n.gettext('Sending is disabled due to invalid sender.'));
      } else if (this.disabledSendTooltip) {
        this.removeTooltip();
      }
    }
  }

  componentWillUnmount() {
    this.removeTooltip();

    if (this.socket) {
      this.socket.off('sender_address_validated');
    }
  }

  handleOpen = (current) => {
    const { error } = this.state;
    this.setState({ current, previousError: error });
  };

  handleClose = (flags = {}) => {
    const { current, error, previousError } = this.state;

    this.setState({
      current: null,
      isSaving: false,
      error: flags?.canceled ? previousError : { ...error, [current]: null },
      previousError: null,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    this.save(data);
  };

  save = (data) => {
    const { newsletter } = this.props;
    const { current } = this.state;

    if (!data) {
      return;
    }

    this.setState({ isSaving: true });

    if (current === 'sender') {
      patch(`/lists/${newsletter.list.id}/`, data).then(
        (obj) => {
          this.setState({
            fromEmail: obj.from_email,
            fromName: obj.from_name,
          });
          this.handleClose();
        },
        (result) => {
          const error = result?.errors
            ? {
                sender: {
                  fromName: result.errors.from_name,
                  fromEmail: result.errors.from_email,
                },
              }
            : null;

          this.setState({
            error,
            isSaving: false,
          });
        }
      );
    } else {
      patch(`/newsletters/${newsletter.id}/`, data).then(
        (obj) => {
          this.setState({
            ...extractNormalizedProps(data, obj),
            defaultCampaignName: generateCampaignName(obj.subject),
          });

          this.handleClose();
        },
        (result) => {
          const error = result?.errors?.[current]
            ? { [current]: result.errors[current] }
            : null;

          this.setState({
            error,
            isSaving: false,
          });
        }
      );
    }
  };

  /**
   * Creates a Foundation tooltip on the send test and send button containers.
   *
   * @param {String} tipText The text to display in the tooltip.
   */
  createTooltip(tipText) {
    this.disabledSendTestTooltip = new FoundationTooltip(
      jQuery(this.sendTestContainer),
      {
        tipText,
        ...tooltipDefaults,
      }
    );
    this.disabledSendTooltip = new FoundationTooltip(
      jQuery(this.sendButtonsContainer),
      {
        tipText,
        ...tooltipDefaults,
      }
    );
  }

  /**
   * Remove Foundation tooltips from the send test and send button containers.
   */
  removeTooltip() {
    if (!this.disabledSendTestTooltip || !this.disabledSendTooltip) {
      return;
    }

    this.disabledSendTestTooltip._destroy();
    this.disabledSendTestTooltip = null;
    this.sendTestContainer.removeAttribute('title');

    this.disabledSendTooltip._destroy();
    this.disabledSendTooltip = null;
    this.sendButtonsContainer.removeAttribute('title');
  }

  /**
   * Handles the validity status of the sender email address.
   *
   * @param {boolean} senderValid The sender's validity status
   */
  handleSenderValidityChange = (senderValid) => {
    this.setState({ senderValid });
  };

  render() {
    const { newsletter, urls, user } = this.props;
    const {
      current,
      error,
      fromEmail,
      fromName,
      isSaving,
      title,
      subject,
      previewText,
      utmCampaign,
      defaultCampaignName,
    } = this.state;

    const i18n = this.context;

    const checklistItemProps = (key) => ({
      isOpen: current === key,
      isSaving,
      onOpen: () => this.handleOpen(key),
      onClose: (cancel = true) => this.handleClose(cancel),
      onSave: (data) => this.save(data),
    });

    if (newsletter.is_in_automation) {
      return (
        <form onSubmit={this.handleSubmit} method="post" className="cell" noValidate>
          <SubjectChecklistItem
            value={subject}
            error={error && error.subject}
            {...checklistItemProps('subject')}
          />
          <PreviewTextChecklistItem
            value={previewText}
            error={error?.previewText}
            {...checklistItemProps('previewText')}
          />
        </form>
      );
    }

    return (
      <form onSubmit={this.handleSubmit} method="post" className="cell" noValidate>
        <RecipientsChecklistItem
          maxRecipients={user.maxContacts}
          numRecipients={newsletter.num_recipients}
          list={newsletter.list}
          segments={newsletter.segments}
          editUrl={urls.recipients}
          {...checklistItemProps('recipients')}
        />
        <TitleChecklistItem
          value={title}
          error={error?.title}
          {...checklistItemProps('title')}
        />
        {newsletter.medium === 'email' && (
          <>
            <SubjectChecklistItem
              value={subject}
              error={error?.subject}
              {...checklistItemProps('subject')}
            />
            <PreviewTextChecklistItem
              value={previewText}
              error={error?.previewText}
              {...checklistItemProps('previewText')}
            />
            <SenderChecklistItem
              value={{ fromName, fromEmail }}
              error={error?.sender}
              description={i18n.gettext(
                'This newsletter will be sent from <em>%(sender)s</em>'
              )}
              instructions={i18n.gettext(
                'Here you define the sender on the email list your are sending ' +
                  'the newsletter to. The sender name should be something that ' +
                  'the recipients associate with you or your business.'
              )}
              onValidityChange={this.handleSenderValidityChange}
              {...checklistItemProps('sender')}
            />
            <GoogleAnalyticsChecklistItem
              value={utmCampaign}
              defaultValue={defaultCampaignName}
              instructions={[
                i18n.gettext(
                  'Track clicks from your newsletters in Google Analytics. ' +
                    'This can help you analyze sales or other goals that ' +
                    'originates from your newsletters. Customize the ' +
                    "campaign name you'll see in Google Analytics below. " +
                    "The source will be the name of the list you're sending " +
                    'to, while the medium will be <em>email</em>.'
                ),
              ]}
              {...checklistItemProps('analytics')}
            />
          </>
        )}
      </form>
    );
  }
}

Checklist.contextType = TranslationsContext;

Checklist.propTypes = {
  newsletter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    subject: PropTypes.string,
    title: PropTypes.string,
    preview_text: PropTypes.string,
    is_in_automation: PropTypes.bool.isRequired,
    num_recipients: PropTypes.number,
    list: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      from_name: PropTypes.string.isRequired,
      from_email: PropTypes.string.isRequired,
    }).isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    utm_campaign: PropTypes.string,
    medium: PropTypes.string.isRequired,
  }).isRequired,
  urls: PropTypes.shape({
    newsletter: PropTypes.string.isRequired,
    recipients: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    maxContacts: PropTypes.number.isRequired,
  }).isRequired,
};

export default Checklist;
