import PropTypes from 'prop-types';

/**
 * Stateless component to simplify output of Foundation style alert boxes
 * for messages, for example errors or placeholder content.
 */
const Message = ({ type = 'info', message = null }) => (
  <div className={['callout', type].join(' ')}>{message}</div>
);

Message.propTypes = {
  /**
   * Alert box type name. Should be on of Foundation's supported types.
   */
  type: PropTypes.string,

  /**
   * The message itself, a plain string.
   */
  message: PropTypes.string.isRequired,
};

Message.defaultProps = {
  type: 'info',
};

export default Message;
