/* eslint-disable no-underscore-dangle, no-new, import/extensions */
import 'foundation-sites/js/foundation.core';
import 'foundation-sites/js/foundation.util.box';
import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.nest';
import { Abide } from 'foundation-sites/js/foundation.abide';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';
import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { Sticky } from 'foundation-sites/js/foundation.sticky';
import { Tabs } from 'foundation-sites/js/foundation.tabs';
import { Toggler } from 'foundation-sites/js/foundation.toggler';
import { Tooltip } from 'foundation-sites/js/foundation.tooltip';

import { ready } from '../utils';

const jQuery = window.jQuery;
const tooltipDefaults = {
  position: 'top',
  hoverDelay: '100',
  allowHtml: true,
};
let tooltips;

// Instantiated Foundation classes
const instances = {
  reveal: [],
};

/**
 * Return instantiated Foundation Reveal class.
 *
 * @param {String} id
 * @returns Foundation.Reveal
 */
const getReveal = (id) => {
  return instances.reveal.find((r) => r.id === id);
};

const setupFoundation = () => {
  const abideForm = jQuery('form[data-abide]');
  const dropdownPanes = jQuery('.dropdown-pane');
  const dropdownMenus = jQuery('[data-dropdown-menu]');
  const revealModal = jQuery('[data-reveal]');
  const tooltipContainers = jQuery('[data-tooltip]');
  const tabs = jQuery('[data-tabs]');
  const togglers = jQuery('[data-toggler]');
  const responsiveTabs = jQuery('[data-responsive-accordion-tabs]');
  const responsiveToggle = document.querySelector('[data-responsive-toggle]');
  const sticky = jQuery('[data-sticky]');

  MediaQuery._init();

  tabs.each(function initTabs() {
    new Tabs(jQuery(this));
  });

  jQuery(tabs).on('change.zf.tabs', (event, element, target) => {
    if (target) {
      const iframe = target[0].querySelector('iframe[data-src]');
      if (iframe) {
        iframe.src = iframe.dataset.src;
        delete iframe.dataset.src;
      }
    }
  });

  const stickies = [];
  sticky.each(function initSticky() {
    stickies.push(new Sticky(jQuery(this)));
  });

  // The Sticky component in Foundation  bugs out when there is also a Reveal modal
  // opened. So we just need to re-calculate the position of the containers that are
  // sticky for them to work again when a Reveal modal closes.
  jQuery(window).on('closed.zf.reveal', () => {
    stickies.forEach((sticky) => sticky._calc(true));
  });

  responsiveTabs.each(function initResponsiveTabs() {
    new ResponsiveAccordionTabs(jQuery(this));
  });

  abideForm.each(function initAbide() {
    const $form = jQuery(this);
    new Abide($form.add(`[data-abide-include="${this.id}"]`));

    /*
     * If the form is within tabs or accordion, we support `data-validate-tabs`
     * on the form to indicate that we want to mark tabs/accordions as having
     * invalid content.
     */
    if ($form.data('validate-tabs') !== undefined) {
      const getTab = ($el) => {
        const id = $el.closest('.tabs-panel, .accordion-content').attr('id');
        return $form.find(`a[href="#${id}"]`).parent();
      };
      const toggleTabState = (e, elem) => {
        const $panel = elem.closest('.tabs-panel, .accordion-content');
        const $tab = getTab(elem);

        // We only want to remove invalid class if there is no other inputs with invalid
        // state within the same tab.
        if (e.type === 'valid' && $panel.find('.is-invalid-label').length === 0) {
          $tab.removeClass('is-invalid');
        } else if (e.type === 'invalid') {
          $tab.addClass('is-invalid');
        }
      };

      $form.on('invalid.zf.abide valid.zf.abide', toggleTabState);

      // Trigger invalid state on initial page load due to backend validation
      $form.find('.is-invalid-input').each((index, elem) => {
        const $el = jQuery(elem);
        const $tab = getTab($el);

        $el.trigger('invalid.zf.abide', [$el]);

        if ($tab.is(':not(.is-active)') && index === 0) {
          $tab.click();
        }
      });
    }
  });

  if (responsiveToggle) {
    new ResponsiveToggle(responsiveToggle);
  }

  revealModal.each(function initReveal() {
    const reveal = new Reveal(jQuery(this), { appendTo: '.hud-viewport' });
    instances.reveal.push(reveal);
    if (this.dataset.autoOpen !== undefined) {
      reveal.open();
    }
  });

  dropdownPanes.each(function initDropdown() {
    new Dropdown(jQuery(this), {
      closeOnClick: true,
      vOffset: 4,
    });
  });

  dropdownMenus.each(function initDropdownMenu() {
    new DropdownMenu(jQuery(this));
  });

  tooltips = tooltipContainers.get().map((element) => ({
    element,
    instance: new Tooltip(jQuery(element), tooltipDefaults),
  }));

  const handleToggleDisable = (element) => {
    const disableList = element.dataset.toggleDisable;

    const setDisabled = (elements) => {
      elements.split(' ').forEach((id) => {
        const input = document.getElementById(id);
        const disabledCondition = input.dataset.disabledIf;

        if (disabledCondition) {
          const allOff = disabledCondition
            .split(' ')
            .every((id) => !document.getElementById(id).checked);

          input.disabled = allOff;

          if (input.dataset.toggleDisable) {
            handleToggleDisable(input);
          }
        } else {
          input.disabled = !element.checked || element.disabled;
        }
      });
    };

    if (disableList) {
      setDisabled(disableList);
    }
  };

  document.querySelectorAll('[data-toggle-disable]').forEach((element) => {
    element.addEventListener('click', () => handleToggleDisable(element));
  });

  togglers.each(function initToggler() {
    const $element = jQuery(this);
    const $toggler = new Toggler($element);

    /*
     * Currently Toggler only supports data-toggle triggers, so we add support
     * for data-open and data-close to control open/close when we need too, i.e
     * for checkboxes.
     * TODO: Remove this when Foundation has support for it.
     */
    $element
      .on('open.zf.trigger', () => {
        if (!$element.is(':visible')) {
          $toggler.toggle();
        }
      })
      .on('close.zf.trigger', () => {
        if ($element.is(':visible')) {
          $toggler.toggle();
        }
      });
  });

  /*
   * Turn off Abide's event handler for submit buttons that specify a custom form
   * action, since Abide doesn't support this properly and will pass the submit event on
   * to the form overriding any `formaction` attribute.
   */
  jQuery('button[formaction]').off('click.zf.abide keydown.zf.abide');
};

const getTooltip = (element) =>
  tooltips.find((tooltip) => tooltip.element === element).instance;

export { tooltipDefaults, getTooltip, getReveal };

export default () => ready(setupFoundation);
