/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';
import { createRoot } from 'react-dom/client';
import { Browser } from 'productbrowser';

import { Reveal as FoundationReveal } from 'foundation-sites/js/foundation.reveal';
import createContentEditor from 'content-editor/factory';
import createStickyNavigation from './sticky-navigation';
import { trackEvent } from '../common/analytics';
import { addAlertMessage, clearMessages } from '../common/messages';
import updateState from '../state';
import { TranslationsContext } from './translate';
import { emitHeightToParent } from '../utils';
import { getJobResult } from '../helpers';
import { get, post } from '../utils/api';

const jQuery = window.jQuery;
let numProcessing = 0;

/**
 * Render processing indicator when we first start processing something.
 */
const startProcessing = () => {
  if (numProcessing === 0) {
    updateState('processing', true);
  }

  numProcessing += 1;
};

/**
 * Remove processing indicator when everything being processed is done.
 */
const stopProcessing = () => {
  numProcessing = Math.max(0, numProcessing - 1);

  if (numProcessing === 0) {
    updateState('processing', false);
  }
};

/**
 * Handle requesting a content browser.
 *
 * Returns a promise that renders a modal with the content browser React component
 * within. After you select an item or closes the content browser it will resolve the
 * promise.
 *
 * @returns {Promise}
 */
const handleRequestingContentBrowser = ({ settings }) =>
  new Promise((resolve) => {
    const revealElement = document.createElement('div');
    const offsetPixels = 16;
    const reveal = new FoundationReveal(jQuery(revealElement), {
      appendTo: '.hud-viewport',
      vOffset: `${offsetPixels}px`,
      closeOnClick: false,
    });
    revealElement.style.minHeight = 'calc(100vh - 3rem)';
    const root = createRoot(revealElement);
    const handleScroll = (data) => {
      const { windowSize, offsetTop } = data;
      revealElement.style.height = `${
        windowSize.height - offsetTop - offsetPixels * 2
      }px`;
    };
    const cleanup = (...args) => {
      root.unmount();
      reveal.close();
      reveal._destroy();
      document.querySelector('.hud-viewport').removeChild(revealElement);
      window.ScrollListener.off('scroll', handleScroll);
      resolve(...args);
    };

    revealElement.classList.add('reveal', 'large');
    reveal.open();

    window.ScrollListener.on('scroll', handleScroll);

    root.render(
      <Browser
        synchronizeContent={async (integration) => {
          const response = await post(`/content/${integration}/synchronize/`);
          return await getJobResult(response.job_id);
        }}
        getContent={({ integration, query, tags = [], ...extraFilters }) => {
          const params = new URLSearchParams();
          if (integration) {
            params.append('integration_id', integration);
          }
          if (query) {
            params.append('query', query);
          }
          tags.forEach((tag) => {
            params.append('tags', tag);
          });
          for (const [filterName, filterValue] of Object.entries(
            extraFilters
          )) {
            if (filterValue) {
              params.append(filterName, filterValue);
            }
          }
          return get('/content/?' + params.toString());
        }}
        onSelect={cleanup}
        onClose={cleanup}
        settings={settings}
      />
    );
  });

/**
 * <ContentEditor /> component.
 */
class ContentEditor extends Component {
  static contextType = TranslationsContext;

  static propTypes = {
    editorSrc: PropTypes.string,
    vendorSrc: PropTypes.string,
    html: PropTypes.string.isRequired,
    saveHandler: PropTypes.func.isRequired,
    styleContent: PropTypes.string,
    replaceHtmlSelector: PropTypes.string,
    config: PropTypes.object,
    reinitialize: PropTypes.bool,
    saveAfterReinitialize: PropTypes.bool,
    saveNow: PropTypes.bool,
    onSettingsChanged: PropTypes.func,
  };

  static defaultProps = {
    editorSrc: window.MailMojo?.Mailings?.editorSrc,
    styleSrc: window.MailMojo?.Mailings?.styleSrc,
    vendorSrc: window.MailMojo?.Mailings?.vendorSrc,
    styleContent: null,
    replaceHtmlSelector: '',
    config: {},
    reinitialize: false,
    saveAfterReinitialize: false,
    saveNow: false,
    onSettingsChanged: null,
  };

  constructor(props) {
    super(props);
    this.textareaElement = createRef();
  }

  /**
   * Setup content editor when the component is mounted.
   */
  componentDidMount() {
    const i18n = this.context;
    const {
      config,
      editorSrc,
      styleContent,
      styleSrc,
      onEditForm,
      onSettingsChanged,
      onModalEnter,
      onModalExit,
      vendorSrc,
    } = this.props;
    const element = this.textareaElement.current;
    const navLinks = document.querySelectorAll(
      '.steps-navigation a, .top-bar a'
    );

    startProcessing();

    createContentEditor(element, {
      staticRoot: config.staticRoot,
      v3Root: config.v3Root,
      loadingHtml: `<p>${i18n.gettext('Loading')}</p>`,
      styleContent,
      editorSrc,
      styleSrc,
      vendorSrc,
    }).then((conduit) => {
      this.conduit = conduit;

      if (config.contentBrowser) {
        config.contentBrowser.onRequest = () =>
          handleRequestingContentBrowser(config.contentBrowser);
      }

      this.conduit
        .getEditor()
        .init(config)
        .then((editor) => {
          const toolbar = editor.getToolbar();
          createStickyNavigation(toolbar);

          stopProcessing();
        });

      this.conduit.on(
        'conduit.heightChanged',
        (_event, { height, iframeRect }) => {
          emitHeightToParent(height + iframeRect.top);
        }
      );

      this.conduit.on(
        'editor.contentChanged',
        (_event, { reset = false } = {}) => {
          if (!this.props.saveNow && !reset) {
            this.save({
              html: this.conduit.getEditor().getContent(),
              isPartialChange: true,
            });
          }
        }
      );

      this.conduit.on('editor.error', (error, msg) =>
        this.showError(error, msg)
      );

      // Toggle process indicator when editor is processing content
      this.conduit.on('editor.startProcessing', () => startProcessing());
      this.conduit.on('editor.stopProcessing', () => stopProcessing());

      this.conduit.on('editor.userEvent', (event) => {
        trackEvent(event.name, event.properties);
      });

      if (onSettingsChanged) {
        this.conduit.on('editor.settingsChanged', (_event, data) => {
          startProcessing();
          onSettingsChanged(data)
            .then(() => clearMessages())
            .catch((response) => this.showError(response))
            .finally(() => stopProcessing());
        });
      }

      if (onEditForm) {
        this.conduit.on('editor.editForm', onEditForm);
      }

      if (onModalEnter) {
        this.conduit.on('editor.modalModeEntered', () => onModalEnter());
      }

      if (onModalExit) {
        this.conduit.on('editor.modalModeExited', () => onModalExit());
      }
    });

    // Make sure we save the content before redirecting after click on steps navigation
    const saveAndRedirect = (url) => {
      this.save({
        html: this.conduit.getEditor().getContent(),
        isPartialChange: false,
      }).then((saved) => {
        if (saved) {
          window.location = url;
        }
      });
    };
    navLinks.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        saveAndRedirect(element.getAttribute('href'));
      });
    });
  }

  /**
   *  Only rerender component if we explicitly tell it to do so.
   */
  shouldComponentUpdate(nextProps) {
    return nextProps.reinitialize || nextProps.saveNow;
  }

  /**
   * Update HTML content in the editor iframe when `props.html` are changed.
   *
   * When `props.replaceHtmlSelector` is supplied the HTML for the given
   * selector is found and its contents are changed. This makes it possible to
   * preserve any other HTML that doesn't require to be changed.
   *
   * If the `props.html` contains complete HTML, we make sure to only replace the
   * content within `<body>`, because the editor has already been initialized with
   * complete HTML.
   *
   * After the HTML content is changed we emit a `newContent` event with the
   * newly created DOM elements so that editor features can reinitialize.
   */
  componentDidUpdate() {
    if (!this.conduit) {
      return;
    }

    const {
      config,
      html,
      replaceHtmlSelector,
      saveAfterReinitialize,
      saveNow,
      styleContent,
    } = this.props;
    const editor = this.conduit.getEditor();

    if (saveNow) {
      this.save({
        html: editor.getContent(),
        isPartialChange: true,
      });
      return;
    }

    editor.close(saveAfterReinitialize).then(() => {
      editor.setConfig(config);
      editor.setStyleContent(styleContent);
      editor.setContent(html, replaceHtmlSelector);

      if (saveAfterReinitialize) {
        this.save({
          html: editor.getContent(),
          isPartialChange: false,
        });
      }
    });
  }

  /**
   * Save the HTML content through the `saveHandler`.
   */
  save(data) {
    const { saveHandler } = this.props;
    let saved = false;

    startProcessing();
    return saveHandler(data)
      .then(() => {
        clearMessages();
        saved = true;
      })
      .catch((response) => this.showError(response))
      .then(() => {
        stopProcessing();
        return saved;
      });
  }

  /**
   * Show an error message due to failure to save.
   *
   * The message is customized based on the response from failed save. If
   * the response has a HTTP status code of 401 we know it failed because
   * user has been logged out. If the browser reports that it is offline,
   * we know that's the reason it failed. Otherwise we just show a generic
   * message indicating an error to the user.
   *
   * @param {Object} response The response from save attempt, or error
   *                          instance if the request couldn't be done at all.
   * @param {String} [msg=null] An optional custom error message.
   */
  showError(response, msg = null) {
    const i18n = this.context;
    const { error } = response;

    if (msg) {
      addAlertMessage(msg);
    } else if (error === 'Unauthorized') {
      addAlertMessage(
        i18n.gettext('Changes could not be saved because you are logged out.')
      );
    } else if (navigator.onLine === false) {
      addAlertMessage(
        i18n.gettext(
          'Changes could not be saved because you are currently offline.'
        )
      );
    } else {
      addAlertMessage(
        i18n.gettext('An unknown error occurred while saving changes.')
      );
    }
  }

  /**
   * Render the component through a textarea that the editor depends on.
   */
  render() {
    const { html } = this.props;
    let completeHtml = html;

    // Ensure HTML has a doctype so Edge doesn't enter IE quirks mode
    if (/<html/i.test(html) === false) {
      completeHtml = `<!doctype html><html><head></head><body>${html}</body></html>`;
    }

    return (
      <textarea ref={this.textareaElement} value={completeHtml} readOnly />
    );
  }
}

export default ContentEditor;
