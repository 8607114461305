/* eslint-disable import/extensions */
import { Component } from 'react';
import { MediaQuery as FoundationMediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

const jQuery = window.jQuery;

/**
 * High-Order Component for media query support.
 *
 * Wraps the given component and passes in `viewportSize` as props.
 *
 * @param {React.Component} WrappedComponent
 * @return {ReactElement}
 */
function withMediaQuery(WrappedComponent) {
  return class MediaQuery extends Component {
    constructor(props) {
      super(props);

      this.state = {
        viewportSize: FoundationMediaQuery.current,
      };
    }

    componentDidMount() {
      this.handleChange = (event, newSize) => {
        this.setState({ viewportSize: newSize });
      };
      jQuery(global).on('changed.zf.mediaquery', this.handleChange);
    }

    componentWillUnmount() {
      jQuery(global).off('changed.zf.mediaquery', this.handleChange);
    }

    render() {
      const { viewportSize } = this.state;
      return <WrappedComponent viewportSize={viewportSize} {...this.props} />;
    }
  };
}

export default withMediaQuery;
