/**
 * Create a "cancelable" promise.
 *
 * The original async task/request performed in the original promise can not be
 * canceled. But this avoids triggering the resolved callback if canceling has
 * been requested.
 *
 * @param {Promise} originalPromise
 * @return {Array} With the promise and a cancel callback function.
 */
export default (promise) => {
  let isCanceled = false;
  const cancel = () => {
    isCanceled = true;
  };

  const cancelablePromise = new Promise((resolve, reject) => {
    promise.then((result) =>
      isCanceled ? reject(new Error({ isCanceled })) : resolve(result)
    );
  });

  return [cancelablePromise, cancel];
};
