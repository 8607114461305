import PropTypes from 'prop-types';
import { useContext } from 'react';
import { createRoot } from 'react-dom/client';

import Gauge from './gauge';
import { Translations, TranslationsContext } from './translate';
import Tooltip from './tooltip';
import { formatNumber } from '../i18n';

const ContactsStatus = ({
  numContacts,
  maxContacts,
  displayCapacity = false,
}) => {
  const i18n = useContext(TranslationsContext);
  const capacity = Math.round((numContacts / maxContacts) * 100);
  return (
    <div className="contacts-status">
      <Tooltip
        title={i18n.sprintf(
          i18n.gettext(
            'You have %(numContacts)s contacts of maximum %(maxContacts)s on your account.'
          ),
          {
            numContacts: formatNumber(numContacts, 0),
            maxContacts: formatNumber(maxContacts, 0),
          }
        )}
      >
        <Gauge
          percent={Math.min(capacity, 100)}
          label={
            displayCapacity ? `${capacity}%` : formatNumber(numContacts, 0)
          }
          description={
            displayCapacity
              ? i18n.gettext('Capacity used').toLowerCase()
              : i18n.gettext('Contacts').toLowerCase()
          }
        />
      </Tooltip>
    </div>
  );
};

ContactsStatus.propTypes = {
  numContacts: PropTypes.number.isRequired,
  maxContacts: PropTypes.number.isRequired,
};

/**
 * Setup account status React component.
 *
 * @param {HTMLElement} container
 */
const createContactStatus = (container) => {
  const render = (user) => {
    const root = createRoot(container);
    root.render(
      <Translations>
        <ContactsStatus
          numContacts={user.numContacts}
          maxContacts={user.maxContacts}
          displayCapacity={container.dataset.displayCapacity ?? false}
        />
      </Translations>
    );
  };
  render(window.MailMojo.User);
};

export default ContactsStatus;
export { createContactStatus };
