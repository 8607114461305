import PropTypes from 'prop-types';
import { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';

/*
 * <TitleChecklistItem /> component.
 *
 * A component for editing the title of a campaign.
 */
const TitleChecklistItem = ({ value, error, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Title')}
      description={
        value ? (
          <em>{value}</em>
        ) : (
          i18n.gettext(
            'A descriptive title for the campaign for your own reference. It is not ' +
              'visible to recipients.'
          )
        )
      }
      isValid={!!value || null}
      {...checklistItemProps}
    >
      <StringField
        inputProps={{ name: 'title', defaultValue: value }}
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

TitleChecklistItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
};

TitleChecklistItem.defaultProps = {
  value: '',
  error: null,
};

export default TitleChecklistItem;
