import PropTypes from 'prop-types';

import { ErrorMessage } from './fields';

/**
 * <Select /> component for rendering an HTML <select> element.
 *
 * Simplifies generating the <option> elements inside a <select>, making sure
 * the correct value is selected and triggering a callback on change of value.
 *
 * @param {String} props.name The field name.
 * @param {Boolean} props.disabled Whether or not the select is disabled.
 * @param {Array[Array]} props.options List of options, each option being an
 *                                     array with value and label.
 * @param {String} selectedValue The currently selected value.
 * @param {Function} onChange Optional callback function on change of value.
 */
const Select = ({
  name,
  label,
  disabled,
  options,
  selectedValue,
  onChange = null,
  isErrorVisible,
  errorMessage,
}) => {
  const valueAttrs = onChange
    ? { onChange, value: selectedValue }
    : { defaultValue: selectedValue };

  const field = (
    <>
      <select
        id={name}
        name={name}
        className={isErrorVisible ? 'is-invalid-input' : null}
        disabled={disabled}
        {...valueAttrs}
      >
        {options.map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {isErrorVisible && errorMessage && (
        <ErrorMessage message={errorMessage} isVisible={isErrorVisible} />
      )}
    </>
  );

  return label ? (
    <label htmlFor={name}>
      {label}
      {field}
    </label>
  ) : (
    field
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  isErrorVisible: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Select.defaultProps = {
  disabled: false,
  onChange: null,
  selectedValue: null,
};

export default Select;
