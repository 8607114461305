/* global FB */

export default {
  /**
   * Open a Facebook share dialog for given URL.
   */
  open(url) {
    FB.ui({ method: 'share', href: url });
  },
};
