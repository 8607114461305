import { createRoot } from 'react-dom/client';

import { renderColorPicker } from '../components/color-picker';
import update, { get as getState } from '../state';
import TemplateModal from './components/template-modal';
import {
  getDefaultPalette,
  getPaletteColorByName,
  getPaletteColors,
  getPaletteNameByColor,
} from '.';
import getTranslations from '../i18n';

/**
 * Get all template cards.
 *
 * @return {Array}
 */
const getCards = () => Array.from(document.querySelectorAll('.card.template'));

/**
 * Get all template IDs.
 *
 * @return {Array}
 */
const getTemplateIds = () =>
  getCards().map((card) => parseInt(card.dataset.templateId, 10));

/**
 * Open template modal.
 *
 * @param {Number} id
 */
const openModal = (id, purchaseView, selectedPalette) => {
  update('templateModal', {
    id: getTemplateIds().indexOf(id),
    purchaseView,
    selectedPalette,
  });
};

/**
 * Toggle favorite state on template.
 *
 * @param {Number} id ID of the template.
 * @param {HTMLElement} button The template star button.
 */
const toggleFavorite = (id, button, i18n) => {
  const url = button.dataset.toggleFavoriteUrl;
  const isFavorite = button.dataset.isFavorite !== undefined;
  const starOff = button.querySelector('[data-favorite-off]');
  const starOn = button.querySelector('[data-favorite-on]');
  const params = {
    method: 'POST',
    body: JSON.stringify({
      id,
      is_favorite: !isFavorite,
    }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin', // For sending cookie
  };
  const setTitle = (title) => {
    button.setAttribute('title', title);
    // Updates the tooltip text created by Foundation.Tooltip
    document.getElementById(button.dataset.toggle).textContent = title;
  };
  const toggle = () => {
    if (isFavorite) {
      button.classList.remove('highlight');
      button.classList.add('secondary', 'bordered');
      starOff.classList.remove('is-hidden');
      starOn.classList.add('is-hidden');
      setTitle(i18n.gettext('Mark as favorite'));
      delete button.dataset.isFavorite;
    } else {
      button.classList.add('highlight');
      button.classList.remove('secondary', 'bordered');
      starOff.classList.add('is-hidden');
      starOn.classList.remove('is-hidden');
      setTitle(i18n.gettext('Remove from favorites'));
      button.dataset.isFavorite = '';
    }

    button.blur();
  };

  // Optimistic update of UI
  toggle();

  fetch(url, params)
    // Revert to previous UI state when API call failed
    .catch(() => toggle());
};

/**
 * Render template modal.
 *
 * @param {Function} primaryButtonHandler
 */
const renderModal = (primaryButtonHandler) => {
  const elem = document.getElementById('template-preview-modal');
  if (elem) {
    const root = createRoot(elem);
    root.render(
      <TemplateModal
        templateIds={getTemplateIds()}
        primaryButtonHandler={primaryButtonHandler}
      />
    );
  }
};

/**
 * Initialize favoriting of a template.
 *
 * @param {Array} array
 */
const initFavoriteActions = () => {
  getTranslations().then((i18n) => {
    getCards().forEach((card) => {
      const button = card.querySelector('button.star');

      if (button) {
        button.addEventListener('click', () =>
          toggleFavorite(card.dataset.templateId, button, i18n)
        );
      }
    });
  });
};

/**
 * Initialize modal for templates.
 *
 * @param {Function} options.primaryButtonHandler
 */
const initModal = ({ primaryButtonHandler, step }) => {
  getCards().forEach((card) => {
    const templateId = parseInt(card.dataset.templateId, 10);
    const template = getState('templates').entities[templateId];
    const previewBtn = card.querySelector('button.preview');
    const buyBtn = card.querySelector('.buy');
    const chooseBtn = card.querySelector('.choose');
    const colorPicker = card.querySelector('.color-picker-container');
    const previewImg = card.querySelector('img');
    const paletteColors = getPaletteColors(template);
    let selectedPalette = getDefaultPalette(template);

    previewBtn.addEventListener('click', () =>
      openModal(templateId, false, selectedPalette)
    );

    if (chooseBtn) {
      chooseBtn.addEventListener('click', () =>
        primaryButtonHandler(templateId, selectedPalette)
      );
    } else if (buyBtn) {
      buyBtn.addEventListener('click', () =>
        openModal(templateId, true, selectedPalette)
      );
    }

    if (paletteColors.length > 1) {
      renderColorPicker(colorPicker, {
        selected: getPaletteColorByName(template, selectedPalette),
        colors: paletteColors,
        onSelect: (color) => {
          const [srcUrl, srcParamString] = previewImg.src.split('?');
          const srcParams = new URLSearchParams(srcParamString);
          const palette = getPaletteNameByColor(template, color);
          srcParams.set('palette', palette);
          previewImg.src = `${srcUrl}?${srcParams.toString()}`;
          selectedPalette = palette;

          if (step && card.classList.contains('selected')) {
            step.setPalette(selectedPalette);
          }
        },
        buttonClasses: 'compact',
      });
    }
  });

  renderModal(primaryButtonHandler);

  if (module.hot) {
    module.hot.accept('./components/template-modal', () => {
      renderModal(primaryButtonHandler);
    });
  }
};

const initTemplateBuilder = () => {
  if (!document.querySelector('template-module')) {
    return;
  }

  const moduleList = document.querySelector('.template-module-list');

  // Ensure module selector switches are turned off when a toggle element
  // within the module list is clicked to remove it. This keeps the switches in
  // sync with off being invisible and on being visible.
  moduleList.addEventListener('click', (event) => {
    if (event.target.dataset.toggle) {
      const macroId = event.target.dataset.toggle;
      const switchInput = document.getElementById(`switch-${macroId}`);
      if (switchInput) {
        switchInput.checked = false;
      }
    }
  });

  import('../../scss/template-builder/styles.scss').then(({ default: css }) => {
    window.customElements.define(
      'template-module',
      class extends HTMLElement {
        constructor() {
          super();
          const htmlContent = this.innerHTML;
          this.innerHTML = null;

          const root = this.attachShadow({ mode: 'open' });
          root.innerHTML = htmlContent;

          const style = document.createElement('style');
          style.textContent = css;
          root.insertBefore(style, root.firstChild);
        }
      }
    );
  });
};

const init = () => {
  initFavoriteActions();
  initModal({
    /*
     * TODO: We should use the API to create a newsletter here instead when
     * it's supported.
     */
    primaryButtonHandler: (id, selectedPalette) => {
      const paletteParam = selectedPalette ? `&palette=${selectedPalette}` : '';
      window.location.href = `/campaigns/create/?tid=${id}${paletteParam}`;
    },
  });

  initTemplateBuilder();
};

export { initFavoriteActions, initModal };

export default init;
