import PropTypes from 'prop-types';
import { Component } from 'react';

import Icon from 'common/lib/components/icon';
import i18n from '../i18n';
import { BaseItemType } from '../types';

/**
 * Component for showing a preview of an item.
 *
 * For items with one or more images we display the image. If there's more than one
 * image we also allow flipping through images.
 *
 * TODO: Rewrite to a functional component.
 */
class Preview extends Component {
  /**
   * Handle click on next/prev buttons.
   *
   * Updates the currently displayed image index in the direction and
   * distance indicated by the `step` argument.
   *
   * @param Event event
   * @param Number step
   */
  roll(event, step = 1) {
    const { images, imageIndex, onImageChange } = this.props;
    let newIndex;

    event.preventDefault();
    event.stopPropagation();

    if (imageIndex + step < 0) {
      newIndex = images.length - 1;
    } else if (imageIndex + step >= images.length) {
      newIndex = 0;
    } else {
      newIndex = imageIndex + step;
    }

    // Blur button to remove :focus Foundation styling
    event.target.blur();

    onImageChange(newIndex);
  }

  render() {
    const { images, imageIndex } = this.props;
    const imageUrl =
      images.length > 0 ? images[imageIndex].metadata?.thumbnail_url : null;

    return (
      <div className={`preview flex-container align-center-middle`}>
        {images.length > 0 ? (
          <div
            className="thumbnail"
            style={{
              backgroundImage: `url('${imageUrl}')`,
              clipPath: 'polygon(81% 0, 100% 19%, 100% 100%, 0 100%, 0 0)',
            }}
          />
        ) : (
          <div>
            <Icon name="freehand/picture-landscape" classNames="xlarge" />
          </div>
        )}
        {images.length > 1 ? (
          <div className="controls button-group">
            <button
              type="button"
              className="button secondary icon"
              onClick={(event) => this.roll(event, -1)}
              title={i18n.gettext('Previous image')}
            >
              <Icon name="freehand/arrow-left" />
            </button>
            <button
              type="button"
              className="button secondary icon"
              onClick={(event) => this.roll(event)}
              title={i18n.gettext('Next image')}
            >
              <Icon name="freehand/arrow-right" />
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

Preview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(BaseItemType)).isRequired,
  imageIndex: PropTypes.number.isRequired,
  onImageChange: PropTypes.func.isRequired,
};

export default Preview;
