import { useState } from 'react';

import Icon from 'common/lib/components/icon';

import ArticleContentItem from './items/ArticleContentItem';
import ChooseButton from './ChooseButton';
import Preview from './Preview';
import ProductContentItem from './items/ProductContentItem';
import i18n from '../i18n';
import { ContentItemType } from '../types';

/**
 * The ContentItem component.
 *
 * This component expects an item sent as a `item` property, and will render a list item
 * with a preview and name of the item with additional data.
 */
const ContentItem = ({ item }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const { data } = item;

  return (
    <li className="cell flex-container">
      <div className={`${item.type} card`}>
        <Preview
          images={data.media !== undefined ? data.media : []}
          imageIndex={imageIndex}
          onImageChange={(index) => setImageIndex(index)}
        />
        <div
          className="position-absolute"
          style={{
            position: 'absolute',
            right: '6px',
            top: '6px',
          }}
        >
          <a
            href={data.url}
            target="_blank"
            rel="noreferrer"
            title={i18n.gettext('Open item in a new window')}
          >
            <Icon name="ui-bold/interface-arrows-expand-5" classNames="small" />
          </a>
        </div>
        <div className="card-section flex-container">
          <div
            className="flex-container flex-dir-column"
            style={{ width: '100%' }}
          >
            <div className="flex-child-grow flex-container flex-dir-column">
              {item.type === 'article' && <ArticleContentItem item={item} />}
              {item.type === 'product' && <ProductContentItem item={item} />}
            </div>
            <div className="text-right">
              <ChooseButton isActive item={item} imageIndex={imageIndex} />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

ContentItem.propTypes = {
  item: ContentItemType,
};

export default ContentItem;
