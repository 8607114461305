/* eslint-disable camelcase */
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import Select from '../../components/select';
import Switch from '../../components/switch';
import Tabs from '../../components/tabs';
import { TranslationsContext } from '../../components/translate';
import { patch } from '../../utils/api';
import Tooltip from '../../components/tooltip';

/**
 * Component for editing name.
 */
const NameChecklistItem = ({ value, error, onChange, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);

  return (
    <ChecklistItem
      title={i18n.gettext('Name')}
      description={value.name || <em>{i18n.gettext('Not set')}</em>}
      isValid={!error && value.name !== ''}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Give the landing page a name. This will be used as the page title too, ' +
            'unless you give it a different public title below.'
        )}
        inputProps={{
          name: 'name',
          value: value.name,
          onChange,
        }}
        isRequired
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

NameChecklistItem.propTypes = {
  value: PropTypes.shape({ name: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

NameChecklistItem.defaultProps = {
  error: null,
};

/**
 * Component for editing slug.
 */
const UrlChecklistItem = ({
  value,
  domains,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const handleChange = ({ target: { name: key, value } }) =>
    onChange({
      key,
      value: value ? parseInt(value, 10) : null,
    });

  return (
    <ChecklistItem
      title={i18n.gettext('URL')}
      description={
        value.slug
          ? i18n.sprintf(
              i18n.gettext(
                'The public URL for this landing page is <em>%(public_url)s</em>.'
              ),
              value
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={!!value.slug}
      {...checklistItemProps}
    >
      <div className="grid-x grid-padding-x-small grid-padding-y-small align-middle">
        <div className="cell">
          {i18n.gettext(
            'Set the URL where this landing page will be available when it is ' +
              'published.'
          )}
        </div>
        <div className="cell">
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.gettext(
                'Enhance your branding and professionalism by adding your own domain ' +
                  'to your landing pages. <a href="/account/domains/" target="_new">' +
                  'Add domain here</a>.'
              ),
            }}
          />
        </div>
        <div className="cell shrink">https://</div>
        <div className="cell shrink" style={{ maxWidth: '22rem' }}>
          <Select
            name="domain_id"
            options={domains.map((domain) => [domain.id ? domain.id : '', domain.host])}
            selectedValue={`${value.domain_id || ''}`}
            onChange={handleChange}
            isErrorVisible={!!error?.domain_id}
          />
        </div>
        <div className="cell shrink">/</div>
        <div className="cell auto">
          {/* Send an empty list in `errors` so that the input field is red, we handle
            it our selves further down */}
          <StringField
            inputProps={{
              name: 'slug',
              value: value.slug || '',
              onChange,
            }}
            isRequired
            errors={error?.slug ? [] : null}
            isErrorVisible={!!error?.slug}
          />
        </div>
        {(error?.domain_id || error?.slug) && (
          <div className="cell form-error is-visible">
            {error?.domain_id || error?.slug}
          </div>
        )}
      </div>
    </ChecklistItem>
  );
};

UrlChecklistItem.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.shape({
    public_url: PropTypes.string,
    domain_id: PropTypes.number,
    slug: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({
    slug: PropTypes.arrayOf(PropTypes.string),
    domain_id: PropTypes.arrayOf(PropTypes.string),
  }),
};

UrlChecklistItem.defaultProps = {
  value: { public_url: '', domain_id: null, slug: '' },
  error: null,
};

/**
 * Component for editing email list to subscribe to.
 */
const SubscribeListChecklistItem = ({
  value,
  lists,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const handleChange = ({ target: { name: key, value } }) =>
    onChange({
      key,
      value: parseInt(value, 10),
    });
  const selectedList = lists.find((list) => list.id === parseInt(value?.lid, 10));

  return (
    <ChecklistItem
      title={i18n.gettext('Email list')}
      description={
        selectedList
          ? i18n.sprintf(
              i18n.gettext(
                'Subscribers will be subscribed to the email list ' +
                  '<em>%(name)s</em>.'
              ),
              selectedList
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={!!value}
      {...checklistItemProps}
    >
      <Select
        name="lid"
        label={i18n.gettext('Choose the list which new subscribers will be added to')}
        isErrorVisible={!!error}
        errorMessage={error}
        options={lists.map((list) => [`${list.id}`, list.name])}
        selectedValue={`${value.lid}`}
        onChange={handleChange}
      />
    </ChecklistItem>
  );
};

SubscribeListChecklistItem.propTypes = {
  value: PropTypes.shape({ lid: PropTypes.number.isRequired }).isRequired,
  error: PropTypes.string,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

SubscribeListChecklistItem.defaultProps = {
  error: null,
};

/**
 * Component for editing subscribe tags.
 */
const SubscribeTagsChecklistItem = ({
  value,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);

  return (
    <ChecklistItem
      title={i18n.gettext('Tags')}
      description={
        value.subscribe_tags
          ? i18n.sprintf(
              i18n.gettext(
                'Subscribers will be tagged with <em>%(subscribe_tags)s</em>.'
              ),
              value
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={value.subscribe_tags ? true : null}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Specify any tags the subscribers should get.' +
            ' Separate multiple tags with a comma.'
        )}
        inputProps={{
          name: 'subscribe_tags',
          value: value.subscribe_tags,
          onChange,
        }}
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

SubscribeTagsChecklistItem.propTypes = {
  value: PropTypes.shape({
    subscribe_tags: PropTypes.string,
  }),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SubscribeTagsChecklistItem.defaultProps = {
  value: { subscribe_tags: '' },
  error: null,
};

/**
 * Component for editing search engine meta info.
 */
const SearchMetaChecklistItem = ({
  value,
  selectedDomain,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const { is_indexed, title, description } = value;
  const withTitle = i18n.sprintf(
    i18n.gettext('with the public title <em>%(title)s</em>.'),
    value
  );
  const withTitleNoSearch = i18n.sprintf(
    i18n.gettext('It has the public title <em>%(title)s</em>.'),
    value
  );
  const withoutTitle = i18n.gettext('Edit to customize public title and description.');
  const switchProps = { name: 'is_indexed' };
  if (!selectedDomain.id) {
    switchProps.disabled = true;
    switchProps.readOnly = true;
  }
  const ToggleSwitch = () => (
    <Switch
      isActive={is_indexed}
      onChange={({ target }) => onChange({ key: target.name, value: target.checked })}
      toggleLabel={i18n.gettext('Toggle')}
      onLabel={i18n.gettext('Yes')}
      offLabel={i18n.gettext('No')}
      viewportSize="small"
      inputProps={switchProps}
    />
  );
  return (
    <ChecklistItem
      title={i18n.gettext('Search & public information')}
      description={
        is_indexed
          ? i18n.gettext('The landing page can be indexed by search engines.') +
            (title ? ` ${withTitle}` : `. ${withoutTitle}`)
          : i18n.gettext("The landing page can't be indexed by search engines.") +
            (title ? ` ${withTitleNoSearch}` : ` ${withoutTitle}`)
      }
      isValid={is_indexed === false && !title && !description ? null : true}
      {...checklistItemProps}
    >
      <div className="grid-x grid-padding-x grid-padding-y-small">
        <div className="cell">
          <label htmlFor="switch-input-is_indexed">
            {i18n.gettext('Allow search engines to index this landing page?')}
            <div>
              {selectedDomain.id ? (
                <ToggleSwitch />
              ) : (
                <Tooltip
                  title={i18n.gettext(
                    'You need to use your own domain to enable indexing.'
                  )}
                >
                  <ToggleSwitch />
                </Tooltip>
              )}
            </div>
          </label>
        </div>
        <div className="cell">
          <StringField
            label={i18n.gettext('Page title')}
            inputProps={{
              name: 'title',
              value: title,
              onChange,
            }}
            isRequired
            isErrorVisible={!!error}
            errorMessage={error}
          />
        </div>
        <div className="cell">
          <StringField
            label={i18n.gettext('Meta description')}
            inputProps={{
              name: 'description',
              value: description,
              rows: 3,
              onChange,
            }}
            isRequired
            isErrorVisible={!!error}
            errorMessage={error}
          />
        </div>
      </div>
    </ChecklistItem>
  );
};

SearchMetaChecklistItem.propTypes = {
  value: PropTypes.shape({
    is_indexed: PropTypes.bool.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  selectedDomain: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchMetaChecklistItem.defaultProps = {
  value: {
    is_indexed: true,
    title: '',
    description: '',
  },
  error: null,
};

/**
 * Component for editing social media meta info.
 */
const SocialMediaChecklistItem = ({
  value,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const { description } = value;

  return (
    <ChecklistItem
      title={i18n.gettext('Social media')}
      description={
        value.description
          ? i18n.sprintf(i18n.gettext('Description: <em>%(description)s</em>'), value)
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={description ? true : null}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Set a custom description that social media websites like ' +
            'Facebook, Twitter and LinkedIn will use when sharing the landing page.'
        )}
        inputProps={{
          name: 'description',
          value: value.description,
          rows: 3,
          onChange,
        }}
        isRequired
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

SocialMediaChecklistItem.propTypes = {
  value: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    description: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

SocialMediaChecklistItem.defaultProps = {
  error: null,
};

const ExtraScriptsChecklistItem = ({
  title,
  value,
  error,
  onChange,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const [currentTab, setCurrentTab] = useState('head');
  const { head, body_start, body_end } = value;
  const hasScriptsDefined = head || body_start || body_end;

  return (
    <ChecklistItem
      title={title}
      description={!hasScriptsDefined ? `<em>${i18n.gettext('Not set')}</em>` : ''}
      isValid={hasScriptsDefined ? !error : null}
      {...checklistItemProps}
    >
      <Tabs
        items={[
          ['head', i18n.gettext('Head')],
          ['body_start', i18n.gettext('Body')],
          ['body_end', i18n.gettext('Footer')],
        ]}
        selectedItem={currentTab}
        onItemClick={setCurrentTab}
      />

      {currentTab === 'head' && (
        <StringField
          inputProps={{
            name: 'head',
            value: head,
            rows: 5,
            onChange,
          }}
          isErrorVisible={!!error?.head}
          errors={error?.head}
        />
      )}
      {currentTab === 'body_start' && (
        <StringField
          inputProps={{
            name: 'body_start',
            value: body_start,
            rows: 5,
            onChange,
          }}
          isErrorVisible={!!error?.body_start}
          errors={error?.body_start}
        />
      )}
      {currentTab === 'body_end' && (
        <StringField
          inputProps={{
            name: 'body_end',
            value: body_end,
            rows: 5,
            onChange,
          }}
          isErrorVisible={!!error?.body_end}
          errors={error?.body_end}
        />
      )}
    </ChecklistItem>
  );
};

ExtraScriptsChecklistItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    head: PropTypes.string,
    body_start: PropTypes.string,
    body_end: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    head: PropTypes.arrayOf(PropTypes.string),
    body_start: PropTypes.arrayOf(PropTypes.string),
    body_end: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
};

ExtraScriptsChecklistItem.defaultProps = {
  error: null,
};

/**
 * Reducer for the checklist state.
 *
 * Handles transitioning between states based on actions.
 */
const checklistState = (state, action) => {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        current: action.itemName,
        previousError: state.error,
      };

    case 'close':
      return {
        ...state,
        current: null,
        isSaving: !!action.save,
        error: action.userCanceled ? state.previousError : state.error,
        previousError: null,
      };

    case 'startSaving':
      return { ...state, isSaving: true };

    case 'saveError':
      return { ...state, isSaving: false, error: action.error };

    case 'updateCache':
      return {
        ...state,
        error: null,
        cache: action.page
          ? {
              name: {
                name: action.page.name,
              },
              url: {
                public_url: action.page.public_url,
                domain_id: action.page.domain_id,
                slug: action.page.slug,
              },
              lid: {
                lid: action.page.lid,
              },
              subscribe_tags: {
                subscribe_tags: action.page.subscribe_tags?.join(', ') ?? '',
              },
              search_meta: action.page.config.search_meta,
              social_media: action.page.config.social_media,
              extra_scripts_index: action.page.config.extra_scripts_index,
              extra_scripts_confirmation: action.page.config.extra_scripts_confirmation,
            }
          : {
              ...state.cache,
              [state.current]: action.value,
            },
      };

    default:
      throw new Error('Unknown state action type.');
  }
};

/**
 * Checklist for page settings.
 */
const Checklist = ({ page, lists, domains, onUpdate }) => {
  const configItems = [
    'search_meta',
    'social_media',
    'extra_scripts_index',
    'extra_scripts_confirmation',
  ];
  const convertToList = ['subscribe_tags'];
  const i18n = useContext(TranslationsContext);
  const [state, dispatch] = useReducer(checklistState, {
    current: null,
    error: null,
    isSaving: false,
    previousError: null,
    cache: {
      name: {
        name: page.name,
      },
      url: {
        public_url: page.public_url,
        domain_id: page.domain_id,
        slug: page.slug,
      },
      lid: {
        lid: page.lid,
      },
      subscribe_tags: {
        subscribe_tags: page.subscribe_tags?.join(', ') ?? '',
      },
      search_meta: page.config.search_meta,
      social_media: page.config.social_media,
      extra_scripts_index: page.config.extra_scripts_index,
      extra_scripts_confirmation: page.config.extra_scripts_confirmation,
    },
  });
  const save = useCallback(() => {
    dispatch({ type: 'startSaving' });
    let payload = { ...state.cache[state.current] };

    if (configItems.includes(state.current)) {
      payload = { config: { [state.current]: payload } };
    } else if (convertToList.includes(state.current)) {
      payload[state.current] = payload[state.current]?.split(/,\s*/) ?? [];
    }

    patch(`/pages/${page.id}/`, payload).then(
      (obj) => {
        onUpdate(obj);
        dispatch({ type: 'close' });
      },
      (result) => {
        let currentError = null;

        if (result?.errors) {
          if (result.errors.config) {
            currentError = result.errors.config[state.current];
          } else if (result.errors.slug) {
            currentError = { slug: result.errors.slug };
          } else if (result.errors.domain_id) {
            currentError = { domain_id: result.errors.domain_id };
          } else {
            currentError = result.errors.config;
          }
        }

        dispatch({
          type: 'saveError',
          error: {
            ...(state.error || {}),
            [state.current]:
              currentError && isArray(currentError) ? currentError[0] : currentError,
          },
        });
      }
    );
  }, [configItems, convertToList, state, page, onUpdate]);
  const close = useCallback(
    (userCanceled = false) => {
      let oldValue = {
        ...state.cache[state.current],
        [state.current]: page[state.current],
      };

      if (configItems.includes(state.current)) {
        oldValue = page.config[state.current];
      } else if (state.current === 'url') {
        // `url` is a special case, since it's not part of the config object, so we need
        // to handle it separately.
        oldValue = {
          public_url: page.public_url,
          domain_id: page.domain_id,
          slug: page.slug,
        };
      }

      dispatch({ type: 'updateCache', value: oldValue });
      dispatch({ type: 'close', userCanceled });
    },
    [configItems, state, page]
  );

  useEffect(() => {
    dispatch({ type: 'updateCache', page });
  }, [page]);

  const createItemProps = (key) => ({
    dispatch,
    isOpen: state.current === key,
    isSaving: state.isSaving,
    /*
     * Open checklist item, triggering close on any other open item as if user
     * canceled editing it.
     */
    onOpen: () => {
      if (state.current) {
        close(true);
      }

      dispatch({ type: 'open', itemName: key });
    },
    /*
     * Handle update of page cache for editing changes.
     *
     * Should pass an object with key/value properties which describe the
     * updated item property. But we also support fetching this from a browser
     * `change` event object as well for simplicity in child components.
     * In these cases, no coercion will be done to the event target value, so
     * if that's needed the child component should wrap the event and call this
     * function directly with proper value.
     *
     * @param {Object|React.SyntheticEvent} object
     */
    onChange: (object) => {
      let changeDescriptor = object;

      if (object.target) {
        changeDescriptor = {
          key: object.target.name,
          value: object.target.value,
        };
      }

      dispatch({
        type: 'updateCache',
        value: {
          ...state.cache[state.current],
          [changeDescriptor.key]: changeDescriptor.value,
        },
      });
    },
    /*
     * Close the current checklist item, canceling any changes.
     */
    onClose: close,
  });
  return (
    <form
      method="post"
      noValidate
      onSubmit={(event) => {
        if (!state.current && !state.error) {
          return;
        }

        event.preventDefault();
        save();
      }}
    >
      <input type="hidden" name="next" value="" />
      <NameChecklistItem
        value={state.cache.name}
        error={state.error?.name}
        {...createItemProps('name')}
      />
      <SubscribeListChecklistItem
        value={state.cache.lid}
        lists={lists}
        error={state.error?.lid}
        {...createItemProps('lid')}
      />
      <UrlChecklistItem
        value={state.cache.url}
        domains={domains}
        error={state.error?.url}
        {...createItemProps('url')}
      />
      <SubscribeTagsChecklistItem
        value={state.cache.subscribe_tags}
        error={state.error?.subscribe_tags}
        {...createItemProps('subscribe_tags')}
      />
      <SearchMetaChecklistItem
        value={state.cache.search_meta}
        selectedDomain={domains.find(
          (domain) => domain.id === state.cache.url.domain_id
        )}
        error={state.error?.search_meta}
        {...createItemProps('search_meta')}
      />
      <SocialMediaChecklistItem
        value={state.cache.social_media ?? {}}
        error={state.error?.social_media}
        {...createItemProps('social_media')}
      />
      <ExtraScriptsChecklistItem
        title={i18n.gettext('Extra scripts on page')}
        value={state.cache.extra_scripts_index ?? {}}
        error={state.error?.extra_scripts_index}
        {...createItemProps('extra_scripts_index')}
      />
      <ExtraScriptsChecklistItem
        title={i18n.gettext('Extra scripts on confirmation page')}
        value={state.cache.extra_scripts_confirmation ?? {}}
        error={state.error?.extra_scripts_confirmation}
        {...createItemProps('extra_scripts_confirmation')}
      />
    </form>
  );
};

Checklist.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    subscribe_tags: PropTypes.arrayOf(PropTypes.string),
    public_url: PropTypes.string,
    domain_id: PropTypes.number,
    slug: PropTypes.string,
    lid: PropTypes.number,
    config: PropTypes.shape({
      search_meta: PropTypes.shape({
        is_indexed: PropTypes.bool.isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      social_media: PropTypes.shape({
        description: PropTypes.string,
      }),
      extra_scripts_index: PropTypes.shape({
        head: PropTypes.string,
        body_start: PropTypes.string,
        body_end: PropTypes.string,
      }),
      extra_scripts_confirmation: PropTypes.shape({
        head: PropTypes.string,
        body_start: PropTypes.string,
        body_end: PropTypes.string,
      }),
    }),
  }).isRequired,
  lists: PropTypes.arrayOf(PropTypes.object),
  domains: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func.isRequired,
};

export default Checklist;
