/**
 * Simple Undo Reducer.
 *
 * Inspired by:
 * https://redux.js.org/recipes/implementing-undo-history/
 *
 * TODO: Improve function by making it an enchaned reducer that can isolate this code
 * completely.
 */
export default (state, action) => {
  const { past, present, future } = state;

  if (!action) {
    return {
      ...state,
      past: [],
      future: [],
    };
  }

  switch (action.type) {
    case 'UNDO': {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      return {
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }

    case 'REDO': {
      const next = future[0];
      const newFuture = future.slice(1);
      return {
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }

    default:
      return {
        past: [...past, present],
        present: action.newPresent,
        future: [],
      };
  }
};
