import PropTypes from 'prop-types';
import { useContext } from 'react';

import Dropdown from 'common/lib/components/dropdown';
import i18n from '../i18n';
import { BrowserContext } from '../contexts';

/**
 * IntegrationFilter component.
 *
 * @param {string} props.current ID of current integration.
 * @param {Array} props.integrations A list of integrations with content.
 * @returns {React.Component}
 */
const IntegrationFilter = ({ current, disabled, integrations }) => {
  const context = useContext(BrowserContext);
  return (
    <>
      <button
        className={`button dropdown secondary ${disabled ? 'disabled' : ''}`}
        data-toggle="integration-dropdown"
        onClick={(e) => e.currentTarget.blur()}
        disabled={integrations.length === 1 || disabled}
      >
        {i18n.sprintf(i18n.gettext('Source: %(selected)s'), {
          selected: integrations.find(
            (integration) => integration.id === current
          ).name,
        })}
      </button>
      <Dropdown id="integration-dropdown">
        <ul className="no-bullet">
          {integrations.map((integration) => (
            <li key={integration.id}>
              <label style={{ cursor: 'pointer' }}>
                <input
                  type="radio"
                  name="source-filter"
                  defaultChecked={integration.id === current}
                  onChange={() => {
                    context.updateFilter({ integration: integration.id });
                  }}
                />
                <span className="padding-left-small">{integration.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </Dropdown>
    </>
  );
};

IntegrationFilter.propTypes = {
  current: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default IntegrationFilter;
