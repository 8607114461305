/* eslint-disable no-restricted-globals */
import { createRoot } from 'react-dom/client';
import createEditorConfig from '../common/editors';
import getTranslations from '../i18n';
import { useGlobalState } from '../common/hooks';
import PageChecklist from './components/checklist';
import PageEditor from './components/editor';
import { Translations } from '../components/translate';
import updateState from '../state';

const steps = {
  /**
   * TODO: Unify logic with the form steps controller. Pages uses submit
   * buttons currently which needs to be handled differently.
   */
  template(el) {
    let i18n;
    let templateInput;
    const form = el.querySelector('form');
    const currentTemplate = el.querySelector('.template.highlight')?.dataset.templateId;

    const selectTemplate = (templateId) => {
      // The submit button is only visible on hover, so we need to manually
      // provide the template value for the next form submit
      if (!templateInput) {
        templateInput = document.createElement('input');
        templateInput.type = 'hidden';
        templateInput.name = 'template';
        form.appendChild(templateInput);
      }

      if (templateId) {
        templateInput.value = templateId;
      }

      form.submit();
    };

    getTranslations().then((translations) => {
      i18n = translations;
    });

    form.addEventListener('submit', (event) => {
      if (!templateInput) {
        event.preventDefault();
      }

      const templateId = event.submitter?.value ?? currentTemplate;

      if (!templateInput && currentTemplate && currentTemplate !== templateId) {
        const msg = i18n.gettext(
          'Are you sure you want to change the template? Changing the template ' +
            'will result in the loss of all existing content.'
        );

        if (confirm(msg)) {
          selectTemplate(templateId);
        } else {
          form.dispatchEvent(new CustomEvent('resetProcessing'));
        }
      } else if (!templateInput) {
        selectTemplate(templateId);
      }
    });
  },

  content(el) {
    const editorContainer = el.querySelector('.content-editor-container');

    createEditorConfig({
      logo: { disableSuggestAccount: true },
    }).then((config) => {
      const root = createRoot(editorContainer);
      root.render(
        <Translations>
          <PageEditor config={config} />
        </Translations>
      );
    });
  },

  settings(el) {
    const checklist = el.querySelector('.checklist');
    const StatefulPageChecklist = () => {
      const page = useGlobalState('page');
      const lists = useGlobalState('email_lists');
      const domains = useGlobalState('domains');
      const update = (updatedPage) => updateState('page', updatedPage);
      return (
        <PageChecklist page={page} lists={lists} domains={domains} onUpdate={update} />
      );
    };
    const root = createRoot(checklist);

    root.render(
      <Translations>
        <StatefulPageChecklist />
      </Translations>
    );

    return {
      getForm() {
        return checklist.querySelector('form');
      },
    };
  },
};

/**
 * Return a step controller for current step in page editor flow.
 */
const getStep = () => {
  const main = document.querySelector('main');
  return steps[main?.dataset.wizardStep]?.(main) ?? null;
};

export { getStep };
