import ScrollListener from 'common/lib/scroll-listener';
import { getAbsoluteBoundingClientRect } from 'common/lib/compat';

/**
 * Update toolbar and sticky navigation offsets.
 *
 * Simply offsets the navigation's top position when top bounds is reached during scroll
 * in embedded context. If the content editor is present we make sure the toolbar is
 * offset enough from the top to make it visible beneath the navigation.
 *
 * @param {HTMLElement} navigation Navigation element.
 * @param {Object} bounds Bounds of the navigation container.
 * @param {Integer} position Position of scroll relative to iframe.
 * @param {ContentEditor.ui.Toolbar} toolbar Optional editor toolbar instance.
 */
function updateNavigationPosition(navigation, bounds, position, toolbar) {
  let navTop = 0;
  let toolbarOffset = 0;
  const intercomBanner = document.querySelector('iframe[name="intercom-banner-frame"]');
  const intercomHeight =
    intercomBanner?.scrollTop === 0 ? intercomBanner.offsetHeight : 0;
  const offset = Math.max(0, position - bounds.top);

  if (offset > 0) {
    navTop =
      (window.top === window ? 0 : offset) +
      (intercomHeight ? Math.min(intercomHeight, offset) : 0);
    toolbarOffset = bounds.height;
  }

  if (toolbar) {
    toolbar.setTopOffset(toolbarOffset);
  }

  Object.assign(navigation.style, { transform: `translateY(${Math.round(navTop)}px)` });
}

/**
 * Setup sticky functionality on scroll listener.
 *
 * @param {ContentEditor.ui.Toolbar} toolbar Optional editor toolbar instance.
 */
const init = (toolbar = null) => {
  const navigation = document.querySelector('.section-header.sticky');
  const bounds = navigation && getAbsoluteBoundingClientRect(navigation);

  if (!bounds) {
    return;
  }

  ScrollListener.on('scroll', (data) => {
    const position = data.position - data.offsetTop;
    updateNavigationPosition(navigation, bounds, position, toolbar);
  });
};

export default init;
