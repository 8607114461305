import { createRoot } from 'react-dom/client';

import Checklist from '../components/checklist';
import createDisableSendButtons from '../components/disable-send-buttons';
import getTranslations from '../../i18n';
import { Translations } from '../../components/translate';

/**
 * Send step controller.
 */
const step = {
  init(sendForm) {
    const sendTestInput = document.getElementById('emails');
    const { User, State } = global.MailMojo;
    const root = createRoot(document.getElementById('campaign-checklist'));

    root.render(
      <Translations>
        <Checklist user={User} newsletter={State.campaign} urls={State.urls} />
      </Translations>
    );

    if (sendTestInput) {
      getTranslations().then((i18n) => {
        createDisableSendButtons(
          sendForm.querySelector('.button-group'),
          sendTestInput,
          i18n.gettext('Sending is disabled while sending tests')
        );
      });
    }

    return this;
  },
};

export default step;
