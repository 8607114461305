import toad from 'toad';
import RegionOverlay from './ui/RegionOverlay';
/*
 * Version for cache busting.
 * TODO: Look into adding a version number based on commit hash.
 */
const VERSION = +new Date();
const BASE_ASSETS = [
  `lib/ckeditor/ckeditor.js?v=${VERSION}`,
  '//code.jquery.com/jquery-3.7.1.min.js',
];

const SELF_CLOSING_ELEMENTS = /<mm:(from|date|subject|address|phone)([^>]*?)\s*\/>/gi;
const THIRD_PARTY_META =
  '<meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />\n' +
  '<meta name="pinterest" content="nopin" />\n';

// Copy Font style from main window
const FONT_STYLE = document.getElementById('webfonts').outerHTML;

/**
 * Prepare HTML for content editor.
 *
 * Makes sure our self-closing content editor elements have an explicit
 * close tag for compatibility with legacy Internet Explorer.
 *
 * Also adds meta tags to prevent external services from decorating the
 * HTML content.
 *
 * @param {String} html The HTML content to prepare.
 * @return {String}
 */
const prepareHtml = (html) =>
  html
    .replace(SELF_CLOSING_ELEMENTS, '<mm:$1$2></mm:$1>')
    .replace(/<head([^>]*)>/gi, `<head$1>\n${THIRD_PARTY_META}${FONT_STYLE}`);

/**
 * Injects HTML code into an iframe.
 *
 * @param {HTMLElement} iframe The iframe element to inject HTML into.
 * @param {String} html The HTML to inject as content.
 */
const injectHtmlToFrame = (iframe, html) => {
  const iframeDoc = iframe.contentWindow.document;
  iframeDoc.open();
  iframeDoc.write(html);
  iframeDoc.close();
};

/**
 * Load resources into the content editor iframe.
 *
 * The content editor depends on some CSS and JavaScript, as well as
 * external libraries like CKEditor and jQuery. These are injected into
 * the <head> of the editor iframe.
 *
 * When the supplied resources are loaded the returned promise is resolved.
 *
 * @param {HTMLElement} iframe The editor iframe to load resources into.
 * @param {Array} resources List of URLs for resources to load.
 * @param {String} baseUrl The base URL for all resources.
 * @return {Promise}
 */
const loadResources = (iframe, resources, baseUrl) => {
  const win = iframe.contentWindow;
  const header = win.document.querySelector('head');

  if (baseUrl) {
    toad.setBaseUrl(baseUrl);
  }

  return new Promise((resolve) => {
    toad.inject(header, resources, resolve);
  });
};

/**
 * Set editor height and emit a `conduit.heightChanged` event
 *
 * This is triggered when the editor is first initialized and after any content
 * changes to set height on iframe and notify interested listeners.
 */
const setEditorHeight = (iframe) => {
  const win = iframe.contentWindow;
  const iframeDocument = iframe.contentDocument;
  const contentElement =
    iframeDocument.body.querySelector('.mm-popup-wrapper') ??
    iframeDocument.body.querySelector('.mm-editor-content') ??
    iframeDocument.body;

  /*
   * Reset height on the iframe first so that its contents has the shortest possible
   * height before we retrieve the height we need to set on the iframe.
   */
  Object.assign(iframe, { height: 0 });

  const height = Math.round(contentElement.getBoundingClientRect().height);
  Object.assign(iframe, { height });

  win.jQuery(win).trigger('conduit.heightChanged', {
    height,
    iframeRect: iframe.getBoundingClientRect(),
  });
};

/**
 * Factory for creating a content editor for an element with HTML code.
 *
 * Creates a new iframe with the HTML code in the source element and
 * initializes a content editor inside the iframe.
 *
 * Options can be passed to the factory, which currently supports
 * customizing the message displayed while loaded the content editor.
 *
 * The promise returned from the factory will resolve with a conduit object once
 * the content editor is fully loaded. The caller need to explicitly initialize
 * the editor. The `conduit.getEditor` function will return the loaded editor
 * module for initializing. And editor events can be listened to through
 * the `conduit.on` helper, eg. to listen for changes to the content.
 *
 * @param {HTMLElement} sourceElement The element containing the HTML code for
 *                                    the editor. Currently has to be a form
 *                                    element with a value property.
 * @param {Object} opts Optional options.
 * @return {Promise}
 */
const createContentEditor = (sourceElement, opts = {}) => {
  const html = prepareHtml(sourceElement.value);
  const iframe = document.createElement('iframe');
  const overlay = new RegionOverlay({
    className: 'loading-overlay',
    // TODO: Fix dynamic/compat lookup of display style in RegionOverlay
    displayStyle: 'flex',
    transition: true,
  });
  const conduit = {
    getEditor() {
      return iframe.contentWindow.ContentEditor;
    },

    getElement() {
      return iframe;
    },

    on(event, fn) {
      const win = iframe.contentWindow;
      win.jQuery(win).on(event, fn);
    },
  };
  const {
    editorSrc = `content_editor.js?v=${VERSION}`,
    styleSrc = null,
    vendorSrc = null,
    loadingHtml = 'Loading editor…',
    styleContent = null,
  } = {
    editorSrc: sourceElement.dataset.editorSrc,
    styleSrc: sourceElement.dataset.styleSrc,
    vendorSrc: sourceElement.dataset.vendorSrc,
    ...opts,
  };
  let loaded = false;

  iframe.classList.add('content-editor');

  // Hide the element with the raw HTML source itself.
  Object.assign(sourceElement.style, { display: 'none' });

  sourceElement.parentNode.insertBefore(iframe, sourceElement);
  injectHtmlToFrame(iframe, html);

  setTimeout(() => !loaded && overlay.show(iframe, false, loadingHtml), 0);

  return new Promise((resolve) => {
    const resources = [vendorSrc, ...BASE_ASSETS];
    if (styleSrc) {
      resources.push(styleSrc);
    }

    loadResources(iframe, resources, opts.staticRoot)
      .then(() => loadResources(iframe, [editorSrc], opts.staticRoot))
      .then(() => {
        const editor = conduit.getEditor();
        loaded = true;
        overlay.hide(iframe);

        editor.attachToFrame(iframe);
        editor.setStyleContent(styleContent);
        conduit.on(
          'editor.contentLoaded editor.contentChanged editor.contentSizeChanged',
          () => setEditorHeight(iframe)
        );

        resolve(conduit);
      });
  });
};

export default createContentEditor;
