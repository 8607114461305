/**
 * Fetch data from Brønnøysyndregistrene.
 *
 * Docs: https://data.brreg.no/enhetsregisteret/api/docs/index.html#enheter-oppslag
 */
const fetchData = (orgNumber) =>
  new Promise((resolve) => {
    const matches = orgNumber.replace(/\s/g, '').match(/\d+/);
    const orgnr = matches && matches[0];

    if (!orgnr || orgnr.length !== 9) {
      resolve(null);
      return;
    }

    fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.ok && response.json())
      .then((data) => {
        if (data) {
          const { navn, postadresse, forretningsadresse, registrertIMvaregisteret } =
            data;
          const { adresse, postnummer, poststed } = postadresse || forretningsadresse;
          let newOrgnr = data.organisasjonsnummer;

          if (registrertIMvaregisteret) {
            newOrgnr = `${newOrgnr} MVA`;
          }

          resolve({
            id: newOrgnr,
            name: navn,
            address: adresse[0],
            address2: adresse.length > 1 ? adresse[1] : '',
            zip_code: postnummer,
            city: poststed,
          });
        } else {
          resolve(null);
        }
      });
  });

/**
 * Create brreg lookup on given input field.
 *
 * @param {HTMLInputElement} orgNumberInput
 * @param {Function} onLookup
 */

const createLookup = (orgNumberInput, onLookup) => {
  let updating = false;

  orgNumberInput.addEventListener('change', () => {
    if (updating) {
      return;
    }

    updating = true;

    fetchData(orgNumberInput.value)
      .then((data) => {
        if (onLookup) {
          onLookup(data);
        }
      })
      .finally(() => {
        updating = false;
      });
  });
};

/**
 * Attach automatic lookup of organization number from Brreg.
 *
 * Input fields that have `data-brreg-address` etc are automatically filled when
 * organization number is added/changed.
 *
 * @param {HTMLInputElement} orgNumberInput
 */
const setupBrregField = (orgNumberInput) => {
  createLookup(orgNumberInput, (data) => {
    if (data) {
      Object.assign(orgNumberInput, { value: data.id });

      Object.keys(data).forEach((key) => {
        const input = document.querySelector(`[data-brreg-${key}]`);
        if (input) {
          input.value = data[key];
        }
      });
    }
  });
};

export default fetchData;

export { createLookup, fetchData, setupBrregField };
