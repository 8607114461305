/* eslint-disable new-cap */

/**
 * Open Intercom with a predefined message.
 *
 * @param {Array} elements
 */
export default (element) => {
  const msg = element.dataset.intercomText;

  element.addEventListener('click', () => {
    window.Intercom('showNewMessage', msg);
  });
};
