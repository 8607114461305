import PropTypes from 'prop-types';

const FEATURED_CATEGORIES = ['featured', 'anbefalt', 'rekommenderas'];

/**
 * Component for a template category.
 *
 * @param {String} props.name Name of the category.
 * @return {ReactElement}
 */
const CategoryLabel = ({ name }) => {
  const isFeatured = FEATURED_CATEGORIES.indexOf(name.toLowerCase()) !== -1;

  return [
    <span
      key={`label-${name}`}
      className={`label ${isFeatured ? 'highlight' : 'secondary'}`}
    >
      {name}
    </span>,
    ' ',
  ];
};

CategoryLabel.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CategoryLabel;
