import { Dropdown as FoundationDropdown } from 'foundation-sites/js/foundation.dropdown';
import { useEffect, useRef } from 'react';

const jQuery = window.jQuery;

/**
 * Foundation Dropdown pane as a React component.
 *
 * @param {int} props.id The identifier for the dropdown. Must correspond with the
 *                       toggler.
 * @param {React.Component} props.children Inner components.
 * @returns {React.Component}
 */
const Dropdown = ({ id, children }) => {
  const containerRef = useRef(null);
  let dropdown;

  useEffect(() => {
    dropdown = new FoundationDropdown(jQuery(containerRef.current), {
      closeOnClick: true,
      position: 'bottom',
      alignment: 'right',
      vOffset: 4,
    });
    return () => {
      dropdown._destroy();
    };
  }, []);

  return (
    <div
      id={id}
      className="dropdown-pane padding-x padding-y"
      ref={containerRef}
      style={{ width: 'auto' }}
    >
      {children}
    </div>
  );
};

export default Dropdown;
