/**
 * Creates a HTML5 field validator for a form field.
 *
 * Handles adding/removing the Foundation CSS class for invalid field
 * styling. This is a small and custom implementation than using Foundation's
 * Abide widget. It defers the actual validation to the browser's HTML5
 * validation engine, which also supports multiple email input fields for
 * example.
 *
 * On creation of the validator and on blur events on the field it will be
 * styled as invalid if it is. Then on changes to the field value we clear
 * invalid styling once it becomes valid again.
 *
 * @param HTMLInputElement field
 */
const createFieldValidator = (field) => {
  let lastValidity = field.validity.valid;

  const checkValidity = ({ type } = {}) => {
    const isValid = field.validity.valid;
    const hasChanged = isValid !== lastValidity;

    if (hasChanged && isValid) {
      field.classList.remove('is-invalid-input');
    } else if (type !== 'input' && !isValid) {
      field.classList.add('is-invalid-input');
    }

    lastValidity = field.validity.valid;
  };

  field.addEventListener('input', checkValidity);
  field.addEventListener('blur', checkValidity);

  checkValidity();
};

export default createFieldValidator;
