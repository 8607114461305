import PropTypes from 'prop-types';
import { useContext } from 'react';
import i18n from '../i18n';
import { BrowserContext } from '../contexts';

/**
 * Stateless component for rendering a button to trigger a sync of content and refresh.
 */
const RefreshButton = ({ disabled, integration }) => {
  const context = useContext(BrowserContext);
  return (
    <button
      type="button"
      className={`button secondary ${disabled ? 'disabled' : ''}`}
      onClick={(e) => {
        e.currentTarget.blur();
        context.triggerSync(integration);
      }}
      disabled={disabled}
    >
      {i18n.gettext('Refresh content')}
    </button>
  );
};

RefreshButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  integration: PropTypes.string.isRequired,
};

export default RefreshButton;
