/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { createRoot } from 'react-dom/client';

import createEditorConfig from '../common/editors';
import FormChecklist from './components/checklist';
import FormTargetingChecklist from './components/targeting-checklist';
import FormTriggersChecklist from './components/triggers-checklist';
import ContentEditorContainer from '../components/content-editor-container';

import { Translations, TranslationsContext } from '../components/translate';
import { baseTemplateStepController } from '../steps';
import * as formApi from './api';
import * as webComponentApi from '../templates/api';

const CONTENT_TOOLBAR = [
  [
    'Format',
    'Font',
    'FontSize',
    '-',
    'Bold',
    'Italic',
    'Strike',
    '-',
    'TextColor',
    'BGColor',
    '-',
    'JustifyLeft',
    'JustifyCenter',
    'JustifyRight',
    'JustifyBlock',
  ],
  '/',
  [
    'Link',
    'Unlink',
    '-',
    'EmojiPanel',
    'Image',
    '-',
    'NumberedList',
    'BulletedList',
    'Outdent',
    'Indent',
    '-',
    'Deletable',
  ],
];

const FormEditor = ({ config }) => {
  const controlsContainer = document.querySelector('.extra-header-controls');
  const i18n = useContext(TranslationsContext);
  const items = [
    {
      resource: 'forms',
      stateKey: 'popupform',
      field: 'html',
      updateField: 'updated_at',
      label: i18n.gettext('Form'),
      config: {
        forms: {
          api: formApi,
          editInline: true,
        },
        webComponents: {
          api: webComponentApi,
        },
      },
    },
    {
      resource: 'forms',
      stateKey: 'popupform',
      field: 'confirmation_html',
      updateField: 'updated_at',
      label: i18n.gettext('Confirmation'),
      config: {
        webComponents: {
          api: webComponentApi,
        },
      },
    },
  ];

  return (
    <ContentEditorContainer
      items={items}
      replaceHtmlSelector=".mm-popup-wrapper"
      controlsContainer={controlsContainer}
      styleContent={`
        .mm-editor-content {
          display: flex;
        }
        .mm-popup-wrapper .mm-popup {
          animation: none !important;
        }
      `}
      config={config}
    />
  );
};

FormEditor.propTypes = {
  config: PropTypes.object,
};

const steps = {
  template(el) {
    const form = el.querySelector('form');
    const controller = baseTemplateStepController(form);
    const templates = Array.from(document.querySelectorAll('.card.template'));
    controller.init();

    templates.forEach((card) => {
      const templateId = card.querySelector('[name="template"]');
      const chooseBtn = card.querySelector('.choose');

      if (chooseBtn) {
        chooseBtn.addEventListener('click', () =>
          controller.selectAndProceed(templateId.value)
        );
      }
    });

    return {
      getNextUrl(url) {
        return controller.getNextUrl(url);
      },
    };
  },

  content(el) {
    const editorContainer = el.querySelector('.content-editor-container');

    createEditorConfig({
      ckeditor: { toolbar_Content: CONTENT_TOOLBAR },
      imageEdit: { inlineSize: true },
    }).then((config) => {
      const root = createRoot(editorContainer);
      root.render(
        <Translations>
          <FormEditor config={config} />
        </Translations>
      );
    });
  },

  targeting(el) {
    const checklist = el.querySelector('.targeting-checklist');
    const root = createRoot(checklist);

    root.render(
      <Translations>
        <FormTargetingChecklist />
      </Translations>
    );

    return {
      getForm() {
        return checklist.querySelector('form');
      },
    };
  },

  triggers(el) {
    const checklist = el.querySelector('.triggers-checklist');
    const root = createRoot(checklist);

    root.render(
      <Translations>
        <FormTriggersChecklist />
      </Translations>
    );

    return {
      getForm() {
        return checklist.querySelector('form');
      },
    };
  },

  settings(el) {
    const checklist = el.querySelector('.checklist');
    const root = createRoot(checklist);

    root.render(
      <Translations>
        <FormChecklist />
      </Translations>
    );

    return {
      getForm() {
        return checklist.querySelector('form');
      },
    };
  },

  publish() {},
};

/**
 * Return a step controller for current step in form editor flow.
 */
const getStep = () => {
  const main = document.querySelector('main');
  return steps[main?.dataset.wizardStep]?.(main) ?? null;
};

export { getStep };
