/* eslint-disable no-param-reassign */
import startCase from 'lodash/startCase';

const noop = () => {};

const SUPPORTED_PROPS = ['event', 'category', 'label', 'value'];

/*
 * Get promise that resolves to initialized analytics instance.
 *
 * @return {Promise}
 */
const getAnalytics = () =>
  new Promise((resolve, reject) => {
    if (window.analytics) {
      window.analytics.ready(() => resolve(window.analytics));
    } else {
      reject();
    }
  });

/**
 * Helper function to track a general event in Segment.
 *
 * Arguments match the arguments to the track method in the Segment JS SDK, but
 * this helper always ensures the group for the event is set in options.
 *
 * @param {String} event Name of event to track.
 * @param {Object} props Extra event properties.
 * @param {Object} options Event tracking options.
 */
const trackEvent = (event, props, options = {}, ...args) => {
  if (!options.groupId) {
    options.groupId = window.MailMojo.User.username;
  }

  getAnalytics()
    .then((analytics) => analytics.track(event, props, options, ...args))
    .catch(noop);
};

/**
 * Helper function to track a link click event in Segment.
 *
 * Implements our own click event handler with a 300 ms delay before
 * redirecting to the link destination to allow the analytics event to be
 * tracked. This is similar to the built-in Segment `trackLink` helper, but we
 * can't use that since it doesn't support options to the event tracking.
 *
 * @param {HTMLElement} element
 * @see `trackEvent` for documentation on the rest of the arguments.
 */
const trackLink = (element, ...args) => {
  element.addEventListener('click', (event) => {
    trackEvent(...args);

    if (
      element.tagName.toLowerCase() === 'a' &&
      element.getAttribute('href')
    ) {
      event.preventDefault();
      setTimeout(() => {
        window.location.href = element.getAttribute('href');
      }, 300);
    }
  });
};

/*
 * Set up analytics tracking for clicks on an element.
 *
 * The element must have `data-analytics-*` attributes in order for the tracking
 * to work.
 *
 * @param {HTMLElement} element
 */
const createTracking = (element) => {
  const event = element.dataset.analyticsEvent;

  if (!event) {
    return;
  }

  const props = SUPPORTED_PROPS.slice(1).reduce((data, key) => {
    const datasetKey = `analytics${startCase(key)}`;
    if (element.dataset[datasetKey]) {
      data[key] = element.dataset[datasetKey];
    }
    return data;
  }, {});

  if (element.id) {
    props.element = element.id;
  }

  trackLink(element, event, props);
};

export { createTracking, getAnalytics, trackEvent, trackLink };
