/**
 * ShareService handles initiation of the different services to share on.
 *
 * Currently supported is: Facebook and LinkedIn. Twitter is the only one who
 * makes it easy to use custom buttons so we don't have to handle it here.
 * Though when we want to track shares in the future we need it.
 */
import facebook from './facebook';
import linkedin from './linkedin';

const Services = { facebook, linkedin };

export default {
  init(name, element, opts) {
    element.addEventListener('click', () => {
      Services[name].open(opts.url);
    });
  },
};
