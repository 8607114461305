import PropTypes from 'prop-types';

import Icon from 'common/lib/components/icon';
import translate from '../../components/translate';

/**
 * A component for action buttons in the template preview modal.
 *
 * @param {String} props.primaryLabel Label for the primary button.
 * @param {Function} props.onPrimaryClick Click handler for the primary button.
 * @param {Function} props.onPreviousClick Click handler for the previous button.
 * @param {Function} props.onNextClick Click handler for the next button.
 * @param {Function} props.onCloseClick Click handler for the close button.
 * @param {Function} props.disablePrevious Disable previous button.
 * @param {Function} props.disableNext Disable next button.
 * @return {ReactElement}
 */
const Actions = ({
  primaryLabel,
  onPrimaryClick,
  onPreviousClick,
  onNextClick,
  onCloseClick,
  disablePrevious,
  disableNext,
  i18n,
}) => (
  <div className="cell auto">
    <div className="button-group float-right">
      {onPrimaryClick && (
        <button
          type="button"
          className="button primary"
          onClick={onPrimaryClick}
        >
          {primaryLabel}
        </button>
      )}
      {onPreviousClick && (
        <button
          type="button"
          className="button secondary icon"
          onClick={onPreviousClick}
          disabled={disablePrevious}
          title={i18n.gettext('Previous')}
        >
          <Icon name="freehand/arrow-left" />
        </button>
      )}
      {onNextClick && (
        <button
          type="button"
          className="button secondary icon"
          onClick={onNextClick}
          disabled={disableNext}
          title={i18n.gettext('Next')}
        >
          <Icon name="freehand/arrow-right" />
        </button>
      )}
      {onCloseClick && (
        <button
          type="button"
          className="button secondary icon"
          onClick={onCloseClick}
          title={i18n.gettext('Close')}
        >
          <Icon name="freehand/remove-delete-sign-thin" />
        </button>
      )}
    </div>
  </div>
);

Actions.defaultProps = {
  primaryLabel: '',
  onPrimaryClick: null,
  onPreviousClick: null,
  onNextClick: null,
  onCloseClick: null,
  disablePrevious: false,
  disableNext: false,
};

Actions.propTypes = {
  primaryLabel: PropTypes.string,
  onPrimaryClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

export default translate()(Actions);
