import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import Icon from 'common/lib/components/icon';
import { formatNumber } from '../../i18n';
import { loadNewsletter } from '../index';
import Tooltip from '../../components/tooltip';
import { TranslationsContext } from '../../components/translate';

/**
 * Component for rendering a summary of statistics for a newsletter in
 * listings. It'll load the newsletter with statistics for the given ID.
 *
 * TODO: Generalize to `<ItemStatsSummary />` component, matching the backend
 * Jinja "component" for this.
 */
const StatisticsSummary = ({ id }) => {
  const i18n = useContext(TranslationsContext);
  const [stats, setStats] = useState({});

  useEffect(() => {
    loadNewsletter(id)
      .then((newsletter) =>
        setStats({
          num_recipients: newsletter.num_recipients,
          ...newsletter.statistics,
        })
      )
      .catch(() => {});
  }, [id]);

  return (
    <div className="grid-x grid-padding-x align-center">
      <div className="cell small-4 flex-container align-center">
        <Tooltip title={i18n.gettext('Recipients')}>
          <div className="flex-container flex-dir-column align-middle item-stats">
            <Icon name="freehand/multiple-man-woman" />
            <span className="small padding-top-small">
              {stats.num_recipients !== undefined
                ? formatNumber(stats.num_recipients, 0)
                : '—'}
            </span>
          </div>
        </Tooltip>
      </div>
      <div className="cell small-4 flex-container align-center">
        <Tooltip title={i18n.gettext('Opens')}>
          <div className="flex-container flex-dir-column align-middle item-stats">
            <Icon name="freehand/view-eye" />
            <span className="small padding-top-small">
              {stats.open_rate !== undefined
                ? `${formatNumber(stats.open_rate, 1)}%`
                : '—'}
            </span>
          </div>
        </Tooltip>
      </div>
      <div className="cell small-4 flex-container align-center">
        <Tooltip title={i18n.gettext('Clicks')}>
          <div className="flex-container flex-dir-column align-middle item-stats">
            <Icon name="freehand/select-cursor-double-click-3" />
            <span className="small padding-top-small">
              {stats.click_rate !== undefined
                ? `${formatNumber(stats.click_rate, 1)}%`
                : '—'}
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
StatisticsSummary.propTypes = {
  id: PropTypes.number.isRequired,
};

export default StatisticsSummary;
