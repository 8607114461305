import BaseObject from '../../utils/base-object';

/**
 * Controller for automatically saving changes to a field.
 *
 * Given a form field with a data attribute containing the URL endpoint,
 * this will automatically send a `PATCH` request to the endpoint with new
 * value when the field loses focus and value has changed.
 */
const Controller = Object.assign(Object.create(BaseObject), {
  init(input) {
    this.input = input;

    this.endpoint = input.getAttribute('data-endpoint');
    this.currentValue = input.value;

    if (this.endpoint) {
      input.addEventListener('blur', this.save.bind(this));
      input.addEventListener('keyup', this.handleKeyUp.bind(this));
    }
  },

  /**
   * Handles key events on the input field.
   *
   * Triggers blur, and thus save, when the enter key was pressed.
   */
  handleKeyUp(event) {
    if (event.keyCode === 13) {
      this.input.blur();
    }
  },

  /**
   * Save the fields value by executing XHR request to given endpoint.
   */
  save() {
    const form = new FormData();
    const params = {
      method: 'PATCH',
      body: form,
      credentials: 'same-origin', // For sending cookie
    };
    const value = this.input.value;

    if (this.input.value === this.currentValue) {
      return true;
    }

    form.append(this.input.name, value);

    fetch(this.endpoint, params)
      .then((response) => response.json())
      .then(() => {
        this.currentValue = value;
      });

    return true;
  },
});

export default Controller;
