/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { Component } from 'react';

import Spinner from './spinner';

/**
 * Loading indicator component.
 *
 * Supports an optional list of messages which it will display one by one
 * at a 3 second interval, with no message displayed initially.
 */
class LoadingIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageIndex: -1,
    };
  }

  /**
   * Set up interval timer if we have messages to display.
   */
  componentDidMount() {
    if (!this.props.messages) {
      return;
    }

    this.timer = setInterval(() => this.showNextMessage(), 3000);
  }

  /**
   * Make sure to clean up and reset timer before unmounting.
   */
  componentWillUnmount() {
    this.reset();
  }

  /**
   * Set next message as active if we have more in the list.
   */
  showNextMessage() {
    const messageIndex = this.state.messageIndex + 1;

    if (this.props.messages[messageIndex]) {
      this.setState({ messageIndex });
    } else {
      this.reset();
    }
  }

  /**
   * Reset interval timer.
   */
  reset() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  /**
   * Render the loading indicator with a spinner and optionally a message.
   */
  render() {
    const { messages } = this.props;
    const message = messages && messages[this.state.messageIndex];

    return (
      <div className="text-center">
        <Spinner />
        <em className="padding-top">{message}</em>
      </div>
    );
  }
}

LoadingIndicator.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
};

LoadingIndicator.defaultProps = {
  messages: null,
};

export default LoadingIndicator;
