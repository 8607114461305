const RecipientsStep = {
  init(form) {
    const containers = Array.from(document.querySelectorAll('.list-container'));
    const segmentsAll = Array.from(form.querySelectorAll("[name='segments']"));

    containers.forEach((container) => {
      /*
       * Needed in order to not trigger bubbling of closing parent container in
       * Foundation when clicking on `all` recipients within a list.
       */
      const dataCloseFix = container.querySelector('[data-close-fix]');
      if (dataCloseFix) {
        container
          .querySelector('[data-close-fix]')
          .addEventListener('click', ({ target }) => {
            const data =
              target.dataset.closeFix || target.parentElement.dataset.closeFix;
            if (data) {
              document.getElementById(data).classList.add('is-hidden');
            }
          });
      }

      // Clicking the list checks the "all" recipients
      const recipientsAll = container.querySelector("input[value*='all-']");
      container.addEventListener('click', (event) => {
        const element = event.target;
        if (element.name === 'list' && element.checked) {
          recipientsAll.checked = true;
        }
      });
    });

    // Changing the list or changing to "all" recipients deselects every segment,
    // and clears any segment validation errors.
    form.addEventListener('change', (event) => {
      const element = event.target;
      const listContainer = element.closest('.list-container');

      if (element.name === 'list' || element.value.indexOf('all-') === 0) {
        this.clearErrors(listContainer);

        segmentsAll.forEach((input) => {
          Object.assign(input, { checked: false });
        });
      } else if (element.name === 'segments') {
        this.clearErrors(listContainer);
      }
    });

    return {};
  },

  clearErrors(listContainer) {
    const segmentErrorLabel = listContainer.querySelector(
      '.is-invalid-label[data-open]'
    );

    if (segmentErrorLabel) {
      segmentErrorLabel.classList.remove('is-invalid-label');
      segmentErrorLabel.parentNode
        .querySelector('.form-error')
        .classList.remove('is-visible');
    }
  },
};

export default RecipientsStep;
