import { defaultLang } from '../i18n';
import { get } from '../utils/api';

const Config = window.MailMojo?.Config;
const User = window.MailMojo?.User;

// TODO: This is a copy of removedPlugins in web-modules/content-editor/config.js
const removedPlugins =
  'about,elementspath,flash,forms,newpage,pagebreak,preview,' +
  'print,save,scayt,smiley,showblocks,sourcearea,wsc';

/**
 * Create a common configuration object for the content editor.
 *
 * Includes user specific context for the editor features.
 */
const createConfig = async ({ ckeditor = {}, ...overrides } = {}) => ({
  language: defaultLang,
  username: User?.username,
  publicStaticRoot: Config?.publicStaticRoot,
  staticRoot: Config?.staticRoot,
  v3Root: Config?.v3Root,
  contentBrowser: {
    settings: await get('/content/settings'),
  },
  ckeditor,
  ...overrides,
});

export default createConfig;

export { removedPlugins };
