const SpfStatus = {
  NONE: 'NONE',
  NEUTRAL: 'NEUTRAL',
  PASS: 'PASS',
  FAIL: 'FAIL',
  SOFTFAIL: 'SOFTFAIL',
  TEMPERROR: 'TEMPERROR',
  PERMERROR: 'PERMERROR',
};

const Status = {
  VALID: 'valid',
  INVALID: 'invalid',
  WARNING: 'warning',
  INFO: 'info',
};

export { SpfStatus, Status };
