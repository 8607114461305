import Jed from 'jed';

export const catalogs = {
  en: new Jed({}),
};

export const defaultLang = document.documentElement.getAttribute('lang') || 'en';

/**
 * Simple helper function to format numbers using the new Intl APIs.
 *
 * Currently just supports decimal formatting according to given language. By
 * default the number will be displayed with the number of significant decimals
 * up to the maximum number of supported decimals in the Intl API. If the
 * decimal parameter is specified it'll be used as the maximum number of
 * decimals, which avoids adding decimals to numbers that don't need it. This
 * logic is based on how we currently format decimal numbers in the backend.
 *
 * @param {Number} num The number to format.
 * @param {Number} decimals Maximum number of decimals, default is 20 which is
 *                          the absolute maximum in the Intl API.
 * @param {String} lang Locale language to use.
 * @param {String|undefined} currency Currency code to use, if any.
 * @return {String}
 */
export const formatNumber = (
  num,
  decimals = 20,
  lang = defaultLang,
  currency = undefined
) =>
  (num || 0).toLocaleString(lang, {
    maximumFractionDigits: decimals,
    currency,
    style: currency ? 'currency' : 'decimal',
  });

/**
 * Get Jed instance for a specified language.
 *
 * This will dynamically load the messages catalog on first request,
 * and resolve with a Jed instance for the loaded messages on subsequent
 * requests.
 *
 * @param {String} lang The language to load, defaults to document language
 *                      or English if no language is defined for document.
 * @return {Promise}
 */
const i18n = (lang = defaultLang) =>
  new Promise((resolve, reject) => {
    if (catalogs[lang]) {
      resolve(catalogs[lang]);
    } else {
      import(`../locale/${lang}/LC_MESSAGES/messages.po`)
        .then(({ default: messages }) => messages)
        .then((messages) => {
          catalogs[lang] = new Jed(messages);
          resolve(catalogs[lang]);
        })
        .catch(reject);
    }
  });

export default i18n;
