/* eslint-disable import/prefer-default-export */
const ISO_LOCALE = {
  no: 'no-NO',
  en: 'en-GB',
  fi: 'fi-FI',
  dk: 'da-DK',
  se: 'sv-SE',
};

/**
 * Truncate a string to a maximum length.
 *
 * Strings longer than supplied length will get truncated down to one
 * character less than length before a ellipsis character is prepended
 * to indicate truncation. If trimming leaves white space at the end that
 * is also removed before appending ellipsis.
 *
 * @param {String} str
 * @param {Number} len
 * @return {String}
 */
const truncate = (str, len) =>
  str.length > len ? `${str.substr(0, len - 1).trim()}…` : str;

/**
 * Format data on a product item.
 *
 * TODO: should support variable locale.
 *
 * @param {Object} content
 */
const formatData = (content, { lang, currency }) => {
  const formatter = new Intl.NumberFormat(ISO_LOCALE[lang], {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  content.map((data) => {
    if (data.price !== undefined) {
      Object.assign(data, {
        price: formatter.format(data.price),
      });
    }

    if (data.original_price !== undefined) {
      Object.assign(data, {
        original_price: formatter.format(data.original_price),
      });
    }

    if (data.discount_percent !== undefined) {
      Object.assign(data, {
        discount_percent: `-${data.discount_percent}%`,
      });
    }

    return data;
  });
};

export { truncate, formatData };
