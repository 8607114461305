import PropTypes from 'prop-types';
import { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';

/*
 * <SubjectChecklistItem /> component.
 *
 * A component for editing the subject of a newsletter.
 */
const SubjectChecklistItem = ({ value, error, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Subject')}
      description={value ? <em>{value}</em> : i18n.gettext('Not set')}
      isValid={!error && value !== ''}
      {...checklistItemProps}
    >
      <StringField
        inputProps={{ name: 'subject', defaultValue: value }}
        isRequired
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

SubjectChecklistItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
};

SubjectChecklistItem.defaultProps = {
  value: '',
  error: null,
};

export default SubjectChecklistItem;
