import getToken from '../auth';
import { get, normalize } from '../utils/api';
import { merge } from '../utils/index';
import update from '../state';

const ABORTED = 'aborted';
const DRAFT = 'draft';
const SENT = 'sent';
const SCHEDULED = 'scheduled';
const SENDING = 'sending';
const STATUSES = [ABORTED, DRAFT, SENT, SCHEDULED, SENDING];

/**
 * Returns the textual status of the newsletter.
 *
 * @param {Object} newsletter
 * @return {String}
 */
const getStatus = (newsletter) => {
  if (newsletter.is_aborted) {
    return ABORTED;
  }
  if (newsletter.is_draft) {
    return DRAFT;
  }
  if (newsletter.is_sent) {
    return SENT;
  }
  if (newsletter.is_scheduled) {
    return SCHEDULED;
  }
  if (newsletter.is_sending) {
    return SENDING;
  }

  return null;
};

/**
 * Returns the time of the newsletter based on it's status.
 *
 * @param {Object} newsletter
 * @return {String}
 */
const getTime = (newsletter) =>
  ({
    [ABORTED]: newsletter.saved,
    [DRAFT]: newsletter.saved,
    [SCHEDULED]: newsletter.started,
    [SENDING]: newsletter.started,
    [SENT]: newsletter.completed,
  })[getStatus(newsletter)];

/**
 * Fetches newsletters from the API for the given page.
 *
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} type
 * @return {Promise}
 */
const loadNewsletters = (page, perPage, type = null) =>
  new Promise((resolve, reject) => {
    const typeFilter = type ? `&type=${type}` : '';
    const url = `/newsletters/?page=${page}&per_page=${perPage}${typeFilter}`;

    getToken()
      .then((token) =>
        get(url, {
          token,
        }).then((response) => {
          if (response.data.length) {
            update('newsletters', (newsletters) =>
              merge(newsletters, normalize(response.data))
            );
          }

          resolve(response);
        })
      )
      .catch(reject);
  });

/**
 * Fetches a newsletter from the API.
 *
 * @param {Number} id
 * @return {Promise}
 */
const loadNewsletter = (id) =>
  new Promise((resolve, reject) => {
    getToken()
      .then((token) =>
        get(`/newsletters/${id}/`, {
          token,
        }).then((response) => {
          update('newsletters', (newsletters) =>
            merge(newsletters, { entities: { [id]: response } })
          );

          resolve(response);
        })
      )
      .catch(reject);
  });

export {
  ABORTED,
  DRAFT,
  SENT,
  SCHEDULED,
  SENDING,
  STATUSES,
  getStatus,
  getTime,
  loadNewsletters,
  loadNewsletter,
};
