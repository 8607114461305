import PropTypes from 'prop-types';

const BaseItemType = {
  title: PropTypes.string,
  summary: PropTypes.string,
  content: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
};

const ContentItemType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  integration_id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  source_id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  data: PropTypes.shape({
    ...BaseItemType,
    media: PropTypes.arrayOf(PropTypes.shape(BaseItemType)),
    metadata: PropTypes.shape(),
  }),
});

const FilterType = PropTypes.shape({
  integration: PropTypes.string.isRequired,
  query: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

const IntegrationSettingsType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content_tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  content_filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
});

export { BaseItemType, ContentItemType, FilterType, IntegrationSettingsType };
