import camelCase from 'lodash/camelCase';
import Cookies from 'js-cookie';

import getToken from '../auth';

const API_BASE_URL = window.MailMojo?.Api?.baseUrl;

/**
 * Return minimum default headers for API requests.
 *
 * If no access token is provided in the options, this will also resolve a
 * token for the default scopes. In addition it sets the standard `Accept`
 * and `Content-Type` headers.
 *
 * @param {Object} opts Any options. Currently only a `token` property is
 *                      supported to supply a custom token.
 * @return {Promise}
 */
const getHeaders = (opts) =>
  new Promise((resolve) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const locale = Cookies.get('locale');
    headers['Accept-Language'] = locale ? locale.replace('_', '-') : 'nb-NO';

    if (opts && opts.token) {
      headers.Authorization = `Bearer ${opts.token}`;
      resolve(headers);
    } else if (!opts || opts.auth !== 'implicit') {
      getToken().then((token) => {
        headers.Authorization = `Bearer ${token}`;
        resolve(headers);
      });
    } else {
      resolve(headers);
    }
  });

/**
 * Send a request to the given API endpoint.
 *
 * This is the generic API request helper which assures the correct headers
 * are set, encodes any request data and deals with basic handling of API
 * response.
 *
 * Usually the helper functions for specific request methods as defined below
 * will be used directly.
 *
 * @param {String} path API endpoint path, will be appended to API base URL.
 * @param {String} method A valid HTTP method for the endpoint.
 * @param {Object} data Any data to send as request body.
 * @param {Object} opts Any extra options, currently just passed through to
 *                      `getHeaders`.
 * @return {Promise}
 */
const request = (path, method, data = null, opts = null) =>
  new Promise((resolve, reject) => {
    if ((!opts || !opts.baseUrl) && !API_BASE_URL) {
      throw new Error('Missing API base URL.');
    }

    getHeaders(opts)
      .then((headers) => {
        const params = { method, headers };
        const baseUrl = (opts && opts.baseUrl) || API_BASE_URL;

        if (opts && opts.auth === 'implicit') {
          params.credentials = 'same-origin';
        }

        if (data) {
          params.body = JSON.stringify(
            data[Symbol.iterator] ? Object.fromEntries(data) : data
          );
        }

        return fetch(`${baseUrl}${path}`, params);
      })
      .then((response) => {
        response
          .json()
          .then((d) => (response.ok ? resolve(d) : reject(d)))
          .catch(reject);
      })
      .catch((error) => reject(error));
  });

const get = (url, opts) => request(url, 'GET', null, opts);
const patch = (url, data, opts) => request(url, 'PATCH', data, opts);
const post = (url, data, opts) => request(url, 'POST', data, opts);

/**
 * Save the data on a resource to backend.
 *
 * TODO: Can be removed when the internal JS API is in.
 *
 * @param {Object} url URL to save the data.
 * @param {Object} data Data to be saved.
 * @param {String} method HTTP method, defaults to `PATCH`.
 * @return {Promise}
 */
const save = (url, data, method = 'PATCH') => {
  const form = data instanceof FormData ? data : new FormData();
  const params = {
    method,
    headers: {
      Accept: 'application/json',
    },
    body: form,
    credentials: 'same-origin', // For sending cookie
  };

  if (!(data instanceof FormData)) {
    Object.keys(data).forEach((prop) => {
      form.append(prop, data[prop]);
    });
  }

  return new Promise((resolve, reject) =>
    fetch(url, params).then((response) => {
      if (response.ok) {
        return response.json().then(resolve);
      }

      return response.json().then(reject);
    })
  );
};

/**
 * Extract properties in data sent in an API request from a response object.
 *
 * The properties in the response object will be in snake case from Python, so
 * we'll also normalize to camel case.
 *
 * @param {FormData|Object} data Keys and values sent in an API request.
 * @param {Object} obj The response object from the API request.
 * @return {Object} Values from `obj` for the (normalized) keys in `data`.
 */
const extractNormalizedProps = (data, obj) => {
  const fields = Object.keys(data[Symbol.iterator] ? Object.fromEntries(data) : data);
  const normalized = {};

  fields.forEach((key) => {
    normalized[camelCase(key)] = obj[key];
  });

  return normalized;
};

/**
 * Normalizes a set of data returned from the API to an object.
 *
 * This implmentation is based on the Normalizr library, which grabs the id's and
 * stuff them into the `result` key for preserving the order of the objects. The
 * entity it self is in the `entities` object with id as keys. No schema is
 * defined yet for, but when it's time we will most likely use the Normalizr
 * lib instead.
 *
 * @param {Array} data
 * @return {Object}
 */
const normalize = (data) => ({
  result: data.map((item) => item.id),
  entities: Object.assign({}, ...data.map((item) => ({ [item.id]: item }))),
});

export { request, get, patch, post, save, extractNormalizedProps, normalize };
