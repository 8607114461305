/* eslint new-cap: 0 */
/* global IN */

export default {
  /**
   * Open a LinkedIn share dialog for given URL.
   */
  open(url) {
    IN.UI.Share().params({ url }).place();
  },
};
