/**
 * Simple mapping of singular and plural labels for time parts in Norwegian.
 */
const labels = {
  days: {
    singular: 'dag',
    plural: 'dager',
  },
  hours: {
    singular: 'time',
    plural: 'timer',
  },
  minutes: {
    singular: 'minutt',
    plural: 'minutter',
  },
  seconds: {
    singular: 'sekund',
    plural: 'sekunder',
  },
};

/**
 * Return a string of the value padded with the padding character on the left
 * side to ensure a minimum string length.
 *
 * @param {Object} val The value to pad.
 * @param {Number} minLength The length to ensure the returned string has.
 * @param {String} padChar The character used for padding to minimum length.
 * @return {String}
 */
const leftPad = (val, minLength, padChar = ' ') => {
  const str = `${val}`;
  if (str.length >= minLength) {
    return str;
  }

  return (
    Array(minLength - str.length)
      .fill(padChar)
      .join('') + str
  );
};

/**
 * A simple countdown component.
 *
 * Based on the expiry time of the form, this will update a countdown clock
 * with the remaining days, hours, minutes and seconds left until it expires.
 *
 * @param {HTMLElement} element The countdown component element.
 * @param {Date} expires
 */
const countdown = (element, expires) => {
  const clockElements = Array.from(
    element.querySelectorAll('[data-timepart]')
  );
  const tock = () => {
    const dt = (expires - Date.now()) / 1000;
    return {
      days: Math.floor(dt / 60 / 60 / 24),
      hours: Math.floor((dt / 60 / 60) % 24),
      minutes: Math.floor((dt / 60) % 60),
      seconds: Math.floor(dt % 60),
    };
  };
  const refresh = () => {
    const remaining = tock();
    clockElements.forEach((el) => {
      const part = el.dataset.timepart;
      const displayEl = el.querySelector('div');
      const labelEl = displayEl && displayEl.parentNode.querySelector('small');

      if (part && remaining[part] !== undefined && displayEl) {
        displayEl.innerText = leftPad(remaining[part], 2, '0');

        // Only update label when updating to singular and back to plural again
        if (remaining[part] === 1) {
          labelEl.innerText = labels[part].singular;
        } else if (remaining[part] === 0) {
          labelEl.innerText = labels[part].plural;
        }
      }
    });
  };

  setInterval(refresh, 1000);
  refresh();
};

export default countdown;
