/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { formatNumber } from '../../i18n';
import { TranslationsContext } from '../../components/translate';

/**
 * Get localized message describing who the campaign will be sent to.
 */
const getRecipientsMessage = (i18n, list, segments) => {
  if (segments.length > 1) {
    return i18n.sprintf(
      i18n.ngettext(
        'The campaign will be sent to %(num)d ' +
          'segment in the <em>%(list)s</em> list.',
        'The campaign will be sent to %(num)d ' +
          'segments in the <em>%(list)s</em> list.',
        segments.length
      ),
      { num: segments.length, list: list.name }
    );
  }
  if (segments.length) {
    return i18n.sprintf(
      i18n.gettext(
        'The campaign will be sent to the <em>%(segment)s</em> ' +
          'segment in the <em>%(list)s</em> list.'
      ),
      { segment: segments[0].name, list: list.name }
    );
  }

  return i18n.sprintf(
    i18n.gettext('The campaign will be sent to the <em>%(name)s</em> list.'),
    list
  );
};

/**
 * Get localized message describing how many recipients the campaign will be
 * sent to.
 */
const getNumActualRecipientsMessage = (i18n, maxRecipients, numRecipients) => {
  if (numRecipients <= maxRecipients) {
    return i18n.sprintf(
      i18n.ngettext(
        'This selection consists of %(numRecipients)s unique contact.',
        'This selection consists of %(numRecipients)s unique contacts.',
        numRecipients
      ),
      { numRecipients: formatNumber(numRecipients) }
    );
  }

  return i18n.sprintf(
    i18n.gettext(
      'This selection consists of %(numRecipients)s unique contacts, ' +
        'but this campaign will be limited to %(maxRecipients)s recipients.'
    ),
    {
      maxRecipients: formatNumber(maxRecipients),
      numRecipients: formatNumber(numRecipients),
    }
  );
};

/**
 * <RecipientsChecklistItem /> component.
 *
 * Gives information about the recipients for the campaign. If no recipients
 * will receive the campaign it'll indicate invalid state. The edit button
 * links back to the recipients step to correct or change this.
 *
 * @param {String} props.editUrl URL to the recipients step.
 * @param {Object} props.i18n Localization object.
 * @param {Object} props.list Recipient list info.
 * @param {Object} props.segments Recipient segment names.
 * @param {Number} props.maxRecipients Maximum number of recipients for user's
 *                                     contacts tier.
 * @param {Number} props.numRecipients Current number of recipients for
 *                                     campaign.
 * @return {ReactElement}
 */
const RecipientsChecklistItem = ({
  list,
  segments,
  maxRecipients,
  numRecipients,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Recipients')}
      isValid={numRecipients > 0}
      description={`
        ${getRecipientsMessage(i18n, list, segments)}
        ${getNumActualRecipientsMessage(i18n, maxRecipients, numRecipients)}
      `}
      {...checklistItemProps}
    />
  );
};

RecipientsChecklistItem.propTypes = {
  maxRecipients: PropTypes.number.isRequired,
  numRecipients: PropTypes.number.isRequired,
  list: PropTypes.shape({
    name: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RecipientsChecklistItem;
