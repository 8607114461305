import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import Spinner from 'common/lib/components/spinner';
import { onChange as onStateChange } from '../state';

const ProcessingIndicator = ({ processing }) =>
  processing ? (
    <div
      className="
        processing-container
        display-inline-block bg-warning radius padding-x padding-y
      "
    >
      <Spinner />
    </div>
  ) : null;

ProcessingIndicator.propTypes = {
  processing: PropTypes.bool.isRequired,
};

const setup = () => {
  const container = document.querySelector('.processing-container');
  if (!container) {
    return;
  }
  const root = createRoot(container);
  let lastProcessing = window.MailMojo.State.processing;

  if (!container || lastProcessing === undefined) {
    return;
  }

  root.render(<ProcessingIndicator processing={lastProcessing} />);

  onStateChange(({ detail }) => {
    if (detail.processing !== lastProcessing) {
      root.render(<ProcessingIndicator processing={detail.processing} />);
      lastProcessing = detail.processing;
    }
  });
};

export default setup;
