/* eslint-disable import/prefer-default-export */

/**
 * Returns all palettes for a template.
 *
 * @param {Object} template
 * @return {Object}
 */
const getAllPalettes = (template) =>
  template.data && template.data.palettes && template.data.palettes.items;

/**
 * Returns the palette for a template chosen by the user.
 *
 * @param {Object} template
 * @return {Object}
 */
const getUserSelectedPalette = (template) => {
  const palettes = getAllPalettes(template);
  if (!palettes || !template.settings?.palette) {
    return null;
  }

  return palettes[template.settings.palette]
    ? template.settings.palette
    : null;
};

/**
 * Returns default palette for a template.
 *
 * If the user has chosen a palette from before, we use that palette as default.
 *
 * @param {Object} template
 * @return {Object}
 */
const getDefaultPalette = (template) =>
  getUserSelectedPalette(template) ||
  (template.data &&
    template.data.palettes &&
    template.data.palettes.default) ||
  null;

/**
 * Returns a palette color for a template by given name.
 *
 * @param {Object} template
 * @param {String} name
 * @return {Object}
 */
const getPaletteColorByName = (template, name) => {
  const palettes = getAllPalettes(template);
  return (
    palettes &&
    palettes[name === 'default' ? getDefaultPalette(template) : name].color
  );
};

/**
 * Returns all color definitions for a palette.
 *
 * This is primarly the hex color that represents the palette. The default palette is
 * sorted first in this array.
 *
 * @param {Object} template
 * @return {Array}
 */
const getPaletteColors = (template) => {
  const palettes = getAllPalettes(template);
  const defaultPalette = getDefaultPalette(template);
  return (
    (palettes &&
      Object.keys(palettes)
        .sort((a) => (a === defaultPalette ? -1 : 0))
        .map((key) => palettes[key].color)) ||
    []
  );
};

/**
 * Returns palette for a template by color definition.
 *
 * The color definition is primarly the hex color that represents the palette.
 *
 * @param {Object} template
 * @param {String} color
 * @return {Object}
 */
const getPaletteNameByColor = (template, color) => {
  const palettes = getAllPalettes(template);
  return Object.keys(palettes).find((key) => palettes[key].color === color);
};

/**
 * Returns the template from app state by its index.
 *
 * @param {Array} templates
 * @param {Number} index
 * @return {Object}
 */
const getTemplate = (templates, index) => {
  const template = window.MailMojo.State.templates.entities[templates[index]];
  return template !== undefined ? template : null;
};

export {
  getAllPalettes,
  getDefaultPalette,
  getPaletteColorByName,
  getPaletteColors,
  getPaletteNameByColor,
  getTemplate,
};
