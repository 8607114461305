/**
 * Utility base object to simplify extending and creating objects.
 *
 * This wraps around ES5 Object.create, triggering the "init" method on objects
 * based on this with any arguments passed to the "create" method.
 *
 * This also supports a "defaults" map on objects as a set of property names
 * with default values. These will be copied directly onto new object
 * instances. Future versions might make use of ES5s property descriptors.
 *
 * If the last argument is an object, it is taken as a "options" object and all
 * it's properties are used as overrides over defaults. Any properties in the
 * options object that are not in the defaults map will not be used though.
 *
 * Simple usage:
 *
 *   const FancyObject = Object.create(BaseObject);
 *   FancyObject.defaults = {
 *     foo: true,
 *     bar: 1.25
 *   };
 *   FancyObject.init = function (someVar) {
 *     this.isCool = someVar === true;
 *   };
 *
 *
 *   const fobj = FancyObject.create(true, { foo: false });
 *   ==>   fobj = {
 *     foo: false,
 *     bar: 1.25,
 *     isCool: true
 *   };
 */

export default (() => ({
  create(...args) {
    const instance = Object.create(this);
    const opts =
      typeof args[args.length - 1] === 'object' ? args[args.length - 1] : null;
    const props = this.defaults
      ? Object.getOwnPropertyNames(this.defaults)
      : null;

    if (props) {
      props.forEach((key) => {
        instance[key] =
          opts && {}.hasOwnProperty.call(opts, key)
            ? opts[key]
            : this.defaults[key];
      });
    }

    instance.init(...args);
    return instance;
  },
}))();
