/**
 * Get scroll left position of a window.
 *
 * Attempts to get the standard `scrollX` property value, but if undefined
 * (Internet Explorer) uses the alias `pageXOffset`.
 *
 * @param {Window} win The window to read scroll top position of.
 * @return {Number}
 */
export function getScrollLeft(win) {
  return win.scrollX || win.pageXOffset;
}

/**
 * Get scroll top position of a window.
 *
 * Attempts to get the standard `scrollY` property value, but if undefined
 * (Internet Explorer) uses the alias `pageYOffset`.
 *
 * Also handles Zurb Foundation's way of disabling scroll, where it sets a
 * negative `top` position on the `<html>` element equal to the actual scroll
 * position (which resets the browser's scroll position). If that is set it'll
 * be a negative value of the actual scroll position which we return instead of
 * the reset browser scroll.
 *
 * @param {Window} win The window to read scroll top position of.
 * @return {Number}
 */
export function getScrollTop(win) {
  const scrollTop = win.scrollY || win.pageYOffset;
  const zfRevealDocTop = parseInt(win.document.documentElement.style.top, 10);
  return zfRevealDocTop ? Math.abs(zfRevealDocTop) : scrollTop;
}

/**
 * Get bounding client rectangle of an element, corrected for scroll position.
 *
 * This makes the `top` and `left` position of the bounding rectangle absolute
 * in relation to the window.
 *
 * @param {HTMLElement} element The element to get absolute bounding rect for.
 * @return {Object} Definition of element bounding rect.
 */
export function getAbsoluteBoundingClientRect(element) {
  const bounds = element.getBoundingClientRect();

  /*
   * `ClientRect` is not enumerable, and properties can't be overriden, so we
   * have to make an explicit copy with corrected top/left position.
   */
  return {
    top: bounds.top + getScrollTop(window),
    right: bounds.right,
    bottom: bounds.bottom,
    left: bounds.left + getScrollLeft(window),
    width: bounds.width,
    height: bounds.height,
  };
}
