import { useContext } from 'react';
import ContentEditorContainer from '../../components/content-editor-container';
import { TranslationsContext } from '../../components/translate';
import * as formApi from '../../forms/api';
import * as webComponentApi from '../../templates/api';
import { get as getState } from '../../state';
const PageEditor = (props) => {
  const controlsContainer = document.querySelector('.extra-header-controls');
  const i18n = useContext(TranslationsContext);

  const items = [
    {
      resource: 'pages',
      stateKey: 'page',
      field: 'html',
      usesFields: ['styles'],
      updateField: 'updated_at',
      label: i18n.gettext('Page'),
      config: {
        bgcolors: {
          alphaMode: true,
        },
        webComponents: {
          api: webComponentApi,
        },
        personalization: getState('personalization'),
      },
      styleContent: (page) => page.styles,
      dependents: [1],
    },
    {
      resource: 'forms',
      stateKey: 'popupform',
      field: 'html',
      usesFields: ['styles', 'type'],
      updateField: 'updated_at',
      label: i18n.gettext('Form'),
      activateOnEvent: 'onEditForm',
      config: (form) => {
        const config = {
          forms: {
            editInline: true,
            api: formApi,
          },
        };
        if (form.type !== 'embedded') {
          config.webComponents = {
            api: webComponentApi,
          };
        }
        return config;
      },
      styleContent: (form) => {
        const base = `
        .mm-editor-content {
          display: flex;
        }
        `;

        if (form.type === 'embedded') {
          return `
          body {
            background-color: #ccc;
          }
          ${base}
          .mm-editor-content {
            align-items: start;
            justify-content: center;
            margin-top: 3rem;
          }
          mm\\:form {
            padding-top: 1rem;
            background-color: #fff;
          }
          ${form.styles}
          `;
        }

        return `
        ${base}
        ${form.styles}
      `;
      },
      dependents: [0],
    },
    {
      resource: 'pages',
      stateKey: 'page',
      field: 'confirmation_html',
      usesFields: ['styles'],
      updateField: 'updated_at',
      label: i18n.gettext('Confirmation'),
      config: {
        bgcolors: {
          alphaMode: true,
        },
        webComponents: {
          api: webComponentApi,
        },
        personalization: getState('personalization'),
      },
      styleContent: (page) => page.styles,
    },
  ];
  return (
    <ContentEditorContainer
      items={items}
      controlsContainer={controlsContainer}
      {...props}
    />
  );
};

export default PageEditor;
