import PropTypes from 'prop-types';

import Icon from 'common/lib/components/icon';

import { Status } from '../common/constants';

/**
 * <ChecklistItemIcon /> component.
 *
 * A stateless component for rendering validation state as an icon. This icon
 * has three possible states for `isValid`: true is green, false is red and
 * null is gray.
 *
 * @param {Boolean} props.isValid
 * @return {ReactElement}
 */
const ChecklistItemIcon = ({ isValid, status }) => {
  const classes = ['icon', 'medium'];
  let name = 'freehand/form-validation-check-square';

  if (status === Status.VALID || isValid === true) {
    classes.push('success');
  } else if (status === Status.WARNING) {
    classes.push('warning');
    name = 'freehand/alerts-warning-triangle';
  } else if (status === Status.INFO) {
    classes.push('help');
    name = 'freehand/alerts-information-circle';
  } else if (status === Status.INVALID || isValid === false) {
    classes.push('alert');
    name = 'freehand/form-validation-remove-square';
  } else {
    classes.push('dark-gray');
  }

  return <Icon name={name} classNames={classes.join(' ')} />;
};

ChecklistItemIcon.propTypes = {
  isValid: PropTypes.bool,
  status: PropTypes.oneOf(Object.keys(Status).map((key) => Status[key])),
};

ChecklistItemIcon.defaultProps = {
  isValid: null,
  status: null,
};

export default ChecklistItemIcon;
