/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';

const GoogleAnalyticsChecklistItem = ({
  value,
  defaultValue,
  error,
  instructions,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);

  const hasCampaign = !!value;
  const description = hasCampaign ? (
    <p className="description">
      {i18n.gettext('Campaign name')}
      {': '}
      <em>{value}</em>
    </p>
  ) : null;

  return (
    <ChecklistItem
      title={i18n.gettext('Google Analytics link tracking')}
      description={description}
      isValid={hasCampaign || null}
      switchEnabled={hasCampaign || checklistItemProps.isOpen}
      toggleSwitch={(isOn) => {
        if (isOn) {
          checklistItemProps.onOpen();
        } else {
          checklistItemProps.onSave({ utm_campaign: '' });
        }
      }}
      {...checklistItemProps}
    >
      <div className="grid-x grid-padding-x grid-padding-y-small">
        <div className="cell">
          {instructions.map((text) => (
            <p key={text} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </div>
        <div className="cell">
          <StringField
            label={i18n.gettext('Campaign name')}
            inputProps={{
              name: 'utm_campaign',
              defaultValue: value || defaultValue,
            }}
          />
        </div>
      </div>
    </ChecklistItem>
  );
};

GoogleAnalyticsChecklistItem.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};
GoogleAnalyticsChecklistItem.defaultProps = {
  error: null,
  value: null,
};

export default GoogleAnalyticsChecklistItem;
