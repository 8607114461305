/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

/**
 * <Tabs /> component.
 *
 * Renders a simple foundation Tab component with tab items.
 */
const Tabs = ({ items, onItemClick, selectedItem }) => (
  <ul className="tabs horizontal" role="tablist">
    {items.map((item) => (
      <li
        key={item[0]}
        className={`tabs-title ${selectedItem === item[0] && 'is-active'}`}
      >
        <a
          aria-selected={selectedItem === item[0] ? 'true' : ''}
          onClick={() => onItemClick(item[0])}
          role="tab"
          tabIndex={-1}
        >
          {item[1]}
        </a>
      </li>
    ))}
  </ul>
);

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.array).isRequired,
  onItemClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default Tabs;
