import { createRoot } from 'react-dom/client';

import MessageList from '../components/message-list';
import updateState, { onChange as onStateChange } from '../state';

const MESSAGE_SUCCESS = 'success';
const MESSAGE_ALERT = 'alert';
const MESSAGE_WARNING = 'warning';
const MESSAGE_INFO = 'info';

/**
 * Add another message to the list of messages.
 *
 * This replicates the structure of a message that we get from the backend,
 * which is Flask's flash message structure: A list with the first item being
 * the message category and the second being the message. In our case the
 * message is an object with support for more details like description and CTA
 * button.
 *
 * @param {String} message The message to display.
 * @param {Object} opts Extra details like message `type` (defaults to
 *                      `MESSAGE_SUCCESS`), and optionally `description`,
 *                      `buttonText` and `buttonLink`.
 */
const addMessage = (message, opts) => {
  updateState('messages', (messagesList) => {
    if (messagesList.find((m) => m[1].message === message)) {
      return messagesList;
    }
    return [[opts.type || MESSAGE_SUCCESS, { message, ...opts }], ...messagesList];
  });
};

/**
 * Add an alert message to the list of messages.
 *
 * See `addMessage` for more info.
 */
const addAlertMessage = (message, opts) =>
  addMessage(message, { ...opts, type: MESSAGE_ALERT });

/**
 * Add a success message to the list of messages.
 *
 * See `addMessage` for more info.
 */
const addSuccessMessage = (message, opts) =>
  addMessage(message, { ...opts, type: MESSAGE_SUCCESS });

/**
 * Remove message at the given index from the list.
 *
 * @param {Number} index
 */
const removeMessage = (index) => {
  updateState('messages', (messagesList) => {
    const newMessagesList = [...messagesList];
    newMessagesList.splice(index, 1);
    return newMessagesList;
  });
};

/**
 * Remove all current messages.
 */
const clearMessages = () => {
  updateState('messages', []);
};

/**
 * Map message category to icon name.
 *
 * @param {String} category
 * @return {String}
 */
const getIconForCategory = (category) => {
  switch (category) {
    case MESSAGE_SUCCESS:
      return 'ui-bold/interface-validation-check-circle';

    case MESSAGE_ALERT:
      return 'ui-bold/interface-alert-warning-diamond';

    case MESSAGE_WARNING:
      return 'ui-bold/interface-alert-warning-triangle';

    case MESSAGE_INFO:
    default:
      return 'ui-bold/interface-alert-information-circle';
  }
};

/**
 * Render the messages list.
 */
const renderMessages = (container, Component, messages) => {
  const root = createRoot(container);
  root.render(
    <Component
      messages={messages}
      getIconName={getIconForCategory}
      onRemove={(index) => removeMessage(index)}
    />
  );
};

export {
  addMessage,
  addAlertMessage,
  addSuccessMessage,
  removeMessage,
  clearMessages,
  getIconForCategory,
  MESSAGE_SUCCESS,
  MESSAGE_ALERT,
  MESSAGE_WARNING,
  MESSAGE_INFO,
};

/**
 * Setup the messages module, which does the initial rendering of messages from
 * the initial state and sets up listener for state and component updates.
 */
const initMessages = () => {
  const container = document.querySelector('.messages-container');
  let lastMessages = window.MailMojo?.State?.messages;

  if (!container || lastMessages === undefined) {
    return;
  }

  renderMessages(container, MessageList, lastMessages);
  onStateChange(({ detail }) => {
    if (detail.messages !== lastMessages) {
      renderMessages(container, MessageList, detail.messages);
      lastMessages = detail.messages;
    }
  });
};

export default initMessages;
