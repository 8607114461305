import io from 'socket.io-client';

const socketUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://ws.mailmojo.no'
    : 'https://ws.mailmojo.local';

let socket;

export default () =>
  new Promise((resolve) => {
    if (!socket) {
      socket = io(socketUrl, {
        transports: ['websocket'],
        autoConnect: false,
      });

      socket.open();
    }

    resolve(socket);
  });
