import PropTypes from 'prop-types';

import Icon from 'common/lib/components/icon';
import Tooltip from './tooltip';
import '../../scss/components/language-selector.scss';

const LanguageSelector = ({ currentLocale, languages }) => (
  <form
    method="get"
    onChange={({ target }) => {
      const domain =
        process.env.NODE_ENV === 'production'
          ? 'mailmojo.no'
          : 'mailmojo.local';
      document.cookie = `locale=${target.value};domain=${domain};path=/`;
      target.form.submit();
    }}
    className="flex-container align-center-middle"
  >
    {languages.map((language) => (
      <Tooltip key={language.locale} title={language.name}>
        <label htmlFor={`lang-${language.locale}`}>
          <input
            id={`lang-${language.locale}`}
            type="radio"
            name="lang"
            value={language.locale}
            defaultChecked={currentLocale === language.locale}
          />
          <Icon name={`flags/${language.flag}`} />
        </label>
      </Tooltip>
    ))}
  </form>
);

LanguageSelector.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      flag: PropTypes.string,
      locale: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default LanguageSelector;
