import { FC, useContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { count } from 'sms-length';

import { Translations, TranslationsContext } from '../../components/translate';

interface SMSEditorProps {
  text: string;
}

interface State {
  plain: string;
}

const SMSEditor: FC<SMSEditorProps> = ({ text }) => {
  const [value, setValue] = useState(text ?? '');
  const i18n = useContext(TranslationsContext);
  const { length, messages } = count(value);

  return (
    <div className="cell large-8">
      <textarea
        className="input"
        name="plain"
        rows={10}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="padding-top float-right">
        {i18n.sprintf(
          i18n.ngettext('%(num)d character', '%(num)d characters', length),
          {
            num: length,
          }
        )}
        {' / '}
        {i18n.sprintf(i18n.ngettext('%(num)d message', '%(num)d messages', messages), {
          num: messages,
        })}
      </div>
    </div>
  );
};

/**
 * SMS Content editor step controller.
 */
const SmsContentStep = {
  init(container: HTMLElement, state: State) {
    const { plain } = state;

    const root = createRoot(container);
    root.render(
      <Translations>
        <section>
          <div className="grid-container padding-y">
            <div className="grid-x grid-padding-x grid-padding-y align-center">
              <input type="hidden" name="next" />
              <SMSEditor text={plain} />
            </div>
          </div>
        </section>
      </Translations>
    );
  },
};

export default SmsContentStep;
