/*
 * HUD, heads-up-display.
 *
 * An overlayed transparent element that will always be the same size and
 * position as the visible part of the page/viewport. This is particularly
 * useful when we are embedded in an external application where we rely on
 * scroll position and size of the outer/parent window to figure out how much
 * of our iframe is actually visible.
 *
 * Important user interface elements that should be displayed above any other
 * content and always be visible can then be easily positioned within this
 * element.
 */
import ScrollListener from 'common/lib/scroll-listener';

/**
 * Update the size and position of the HUD viewport any time the window scroll
 * position or size changes.
 *
 * @param {DOMElement} viewport The viewport element.
 * @param {Event} event Custom event from our `ScrollListener` with info about
 *                      the current scroll position and window size.
 */
const update = (viewport, event) => {
  let top = 0;
  let height = window.innerHeight;

  if (event) {
    const { offsetTop, position, windowSize } = event;

    top = Math.max(0, position - offsetTop);
    height = windowSize.height - Math.max(0, offsetTop - position);

    /*
     * Restrict height when we're in an iframe to avoid overflowing when
     * content below our iframe is also in view (eg. we've scrolled to the
     * bottom of the page).
     */
    if (window.top !== window) {
      height = Math.min(window.innerHeight - top, height);
    }
  }

  Object.assign(viewport.style, {
    top: `${top}px`,
    left: 0,
    right: 0,
    height: `${height}px`,
  });
};

/**
 * Initialize updater of HUD viewport.
 */
const init = () => {
  const viewport = document.querySelector('.hud-viewport');
  if (!viewport) {
    return;
  }

  const triggerUpdate = (event) => update(viewport, event);
  const lastEvent = ScrollListener.getLastEvent();

  ScrollListener.on('scroll', triggerUpdate);
  if (lastEvent) {
    triggerUpdate(lastEvent);
  }
};

export default init;
