/* eslint-disable import/extensions */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Tooltip as FoundationTooltip } from 'foundation-sites/js/foundation.tooltip';

import { tooltipDefaults } from '../common/foundation';

const jQuery = window.jQuery;

/**
 * Component for creating a Foundation tooltip.
 *
 * @param {React.Component} WrappedComponent
 * @return {ReactElement}
 */
export default class Tooltip extends Component {
  static propTypes = {
    title: PropTypes.string,
    extraClasses: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    title: null,
    extraClasses: null,
    children: null,
  };

  componentDidMount() {
    this.createTooltip();
  }

  /**
   * Create or destroy the tooltip instance.
   *
   * If `props.title` has content we create a Foundation tooltip instance.
   * If not then we destroy the current, if present, because it received new
   * props without any tooltip content.
   */
  componentDidUpdate() {
    const { title } = this.props;

    if (title) {
      this.createTooltip();
    } else if (this.tooltip) {
      this.destroyTooltip();
    }
  }

  componentWillUnmount() {
    if (this.tooltip) {
      this.destroyTooltip();
    }
  }

  /**
   * Create the Foundation tooltip instance.
   *
   * The jQuery tooltip element is based on the wrapped `<div>` element.
   */
  createTooltip() {
    const { title } = this.props;

    if (this.tooltip || !title) {
      return;
    }

    this.tooltip = new FoundationTooltip(jQuery(this.element), {
      tipText: title,
      ...tooltipDefaults,
    });
  }

  /**
   * Destroy the Foundation tooltip instance.
   */
  destroyTooltip() {
    this.tooltip._destroy();
    this.tooltip = null;
  }

  render() {
    const { children, extraClasses, title } = this.props;

    return (
      <div
        className={extraClasses}
        ref={(element) => {
          this.element = element;
        }}
      >
        {children}
        <span className="show-for-sr">{title}</span>
      </div>
    );
  }
}
