/* eslint-disable react/no-danger, jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import PropTypes from 'prop-types';

import Icon from 'common/lib/components/icon';
import translate, { i18nType } from './translate';

const inputPropsType = PropTypes.shape({
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
});

const ErrorMessage = ({ message, isVisible }) => (
  <span className={`form-error ${isVisible ? 'is-visible' : ''}`}>
    <Icon name="ui-bold/interface-alert-warning-diamond" classNames="small" />
    <span
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  </span>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  isVisible: false,
};

const StringField = translate()(
  ({
    label,
    inputProps,
    isRequired,
    errorMessage,
    errors,
    isErrorVisible,
    i18n,
  }) => (
    <label className={isErrorVisible ? 'is-invalid-label' : null}>
      {label}
      {inputProps.rows === undefined ? (
        <input
          type="text"
          required={isRequired}
          className={isErrorVisible ? 'is-invalid-input' : null}
          {...inputProps}
        />
      ) : (
        <textarea
          required={isRequired}
          className={isErrorVisible ? 'is-invalid-input' : null}
          {...inputProps}
        />
      )}

      {(isRequired || isErrorVisible) && !errors && (
        <ErrorMessage
          message={errorMessage || i18n.gettext('This field is required.')}
          isVisible={isErrorVisible}
        />
      )}

      {(isRequired || isErrorVisible) &&
        errors &&
        errors.map((error, idx) => (
          <ErrorMessage
            key={`error-${idx}`}
            message={error}
            isVisible={isErrorVisible}
          />
        ))}
    </label>
  )
);

StringField.propTypes = {
  label: PropTypes.string,
  inputProps: inputPropsType.isRequired,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  isErrorVisible: PropTypes.bool,
  i18n: i18nType,
};

StringField.defaultProps = {
  label: null,
  isRequired: true,
  errorMessage: null,
  errrors: [],
  isErrorVisible: false,
  i18n: null,
};

const RadioField = ({ label, inputProps }) => (
  <label>
    <input type="radio" {...inputProps} /> {label}
  </label>
);

RadioField.propTypes = {
  label: PropTypes.string.isRequired,
  inputProps: inputPropsType.isRequired,
};

const VATNumberField = translate()(({ label, inputProps, i18n }) => (
  <label>
    {label}
    <input type="text" pattern={'([0-9]\\s*){9}(\\s*MVA)?'} {...inputProps} />
    <ErrorMessage message={i18n.gettext('Invalid VAT number.')} />
  </label>
));

VATNumberField.propTypes = {
  label: PropTypes.string.isRequired,
  inputProps: inputPropsType.isRequired,
  i18n: i18nType.isRequired,
};

export {
  ErrorMessage,
  inputPropsType,
  RadioField,
  StringField,
  VATNumberField,
};
