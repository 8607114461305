import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { Status } from '../../common/constants';
import { StringField } from '../../components/fields';
import SenderDomainStatus from '../../components/sender-domain-status';
import { TranslationsContext } from '../../components/translate';
import { get } from '../../utils/api';

const checkDomainStatus = (domain) =>
  get(`/domains/`)
    .then((result) =>
      result.filter((d) => d.domain === domain && d.type === 'email').pop()
    )
    .then((result) => {
      const domainStatus = {
        isFree: result.is_free_domain,
        isValid: result.is_dns_verified,
        isVerified: result.is_confirmed,
        isAuthenticated: result.is_authenticated,
      };
      return {
        id: result.id,
        domainStatus,
        status: domainStatus.isValid
          ? Status.VALID
          : domainStatus.isAuthenticated
            ? Status.WARNING
            : Status.INVALID,
      };
    })
    .catch(() => {
      return {
        domainStatus: {
          isValid: false,
          isVerified: false,
        },
        status: Status.INVALID,
      };
    });

/**
 * Modernized sender checklist item components which leaves persisting changes
 * up to the parent component.
 *
 * TODO: Make this the default and only sender checklist item component once
 * the automation checklist is updated too.
 */
const SenderChecklistItem = ({
  value,
  error,
  description,
  instructions,
  onValidityChange,
  source,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const domain = value.fromEmail?.split('@')[1];
  const [domainStatus, setDomainStatus] = useState();
  const [status, setStatus] = useState();
  const [domainId, setDomainId] = useState();

  useEffect(() => {
    if (domain) {
      checkDomainStatus(domain).then((result) => {
        setDomainStatus(result.domainStatus);
        setStatus(result.status);
        setDomainId(result.id);
        if (onValidityChange) {
          onValidityChange(result.status !== Status.INVALID);
        }
      });
    }
  }, [domain, onValidityChange]);

  const descriptionContent = (
    <>
      <small
        dangerouslySetInnerHTML={{
          __html: i18n.sprintf(description, {
            sender: `${value.fromName} &lt;${value.fromEmail}&gt;`,
          }),
        }}
      />
      {domainStatus && (
        <SenderDomainStatus
          id={domainId}
          domain={domain}
          domainStatus={domainStatus}
          i18n={i18n}
          source={source}
        />
      )}
    </>
  );

  return (
    <ChecklistItem
      title={i18n.gettext('Sender')}
      status={error ? Status.INVALID : status}
      description={descriptionContent}
      {...checklistItemProps}
    >
      <div className="grid-x grid-padding-x grid-padding-y-small">
        <div className="cell">
          <p>{instructions}</p>
        </div>
        <div className="cell medium-6 small-12">
          <StringField
            label={i18n.gettext('Name')}
            inputProps={{ name: 'from_name', defaultValue: value.fromName }}
            errorMessage={error?.fromName?.[0]}
            isErrorVisible={!!error?.fromName}
          />
        </div>
        <div className="cell medium-6 small-12">
          <StringField
            label={i18n.gettext('Email address')}
            inputProps={{
              type: 'email',
              name: 'from_email',
              defaultValue: value.fromEmail,
            }}
            errorMessage={error?.fromEmail?.[0]}
            isErrorVisible={!!error?.fromEmail}
          />
        </div>
      </div>
    </ChecklistItem>
  );
};

SenderChecklistItem.propTypes = {
  description: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
  value: PropTypes.shape({
    fromName: PropTypes.string,
    fromEmail: PropTypes.string,
  }).isRequired,
  onValidityChange: PropTypes.func,
  error: PropTypes.shape({
    fromEmail: PropTypes.arrayOf(PropTypes.string),
    fromName: PropTypes.arrayOf(PropTypes.string),
  }),
  source: PropTypes.string,
};
SenderChecklistItem.defaultProps = {
  error: null,
  onValidityChange: null,
};

export default SenderChecklistItem;
