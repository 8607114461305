import { Tooltip } from 'foundation-sites/js/foundation.tooltip';

import { tooltipDefaults } from '../common/foundation';
import getTranslations from '../i18n';

const jQuery = window.jQuery;

/**
 * Create an display a tooltip.
 *
 * @param {HTMLElement} element
 */
const showTooltip = (element, tipText) => {
  const tooltip = new Tooltip(jQuery(element), {
    tipText,
    disableHover: true,
    ...tooltipDefaults,
  });
  tooltip.show();
  return tooltip;
};

/**
 * Copy to clipboard.
 *
 * @param {HTMLButtonElement} button Button that triggers the copy.
 * @param {String} value Value that will be copied to clipboard.
 */
const copyValue = (button, value) => {
  // When we only have the value we want to copy we need to create a "hidden" input
  // field to store the value in so that we can select it and execute copy command.
  const input = document.createElement('input');
  input.style = 'position: absolute; left: -1000px; top: -1000px';
  input.value = value;
  document.body.appendChild(input);

  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);

  getTranslations().then((i18n) => {
    const tooltip = showTooltip(button, i18n.gettext('Copied!'));

    setTimeout(() => {
      tooltip.hide();
      jQuery(button).parents('.dropdown-pane').trigger('close.zf.trigger');
    }, 800);
  });
};

/**
 * Copy to clipboard from an input field.
 *
 * @param {HTMLButtonElement} button Button that triggers the copy.
 * @param {HTMLInputElement} input Input field with the content that will be
 *                                 copied to clipboard.
 */
const copyFromInput = (button, input) => {
  input.focus();
  input.select();
  document.execCommand('copy');
  showTooltip(input);

  getTranslations().then((i18n) => {
    showTooltip(input, i18n.gettext('Copied!'));
  });
};

export { copyFromInput, copyValue };
