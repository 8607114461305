import { useContext } from 'react';
import i18n from '../i18n';
import { BrowserContext } from '../contexts';

/**
 * Stateless component for rendering a close button.
 *
 * This close button will simply close the browser, no matter if any
 * image item is selected or not, and will not pass on any image as a result.
 */
const CloseButton = () => {
  const context = useContext(BrowserContext);
  return (
    <button
      type="button"
      className="button secondary"
      onClick={() => context.onClose()}
    >
      {i18n.gettext('Close')}
    </button>
  );
};

export default CloseButton;
