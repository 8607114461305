/* eslint-disable no-restricted-globals */
/**
 * Present a confirm dialog when clicking the element.
 *
 * @param {Array} elements
 */
const showConfirm = (element) => {
  const msg = element.dataset.confirmText;

  element.addEventListener('click', (event) => {
    if (!confirm(msg)) {
      event.preventDefault();
    }
  });
};

export default showConfirm;
