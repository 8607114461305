/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';

import { get as getState, onChange } from '../state';

/**
 * Hook for using a value from the global state and re-render when the state
 * value updates.
 *
 * @param {String} key The state key for retrieving the value.
 * @return {Any} The current state value.
 */
const useGlobalState = (key) => {
  const [value, setValue] = useState(getState(key));
  useEffect(
    () => onChange(({ detail: newState }) => setValue(newState[key])),
    [key]
  );
  return value;
};

/**
 * Basic hook for creating a controlled input.
 *
 * @param {String} initialValue The initial input value.
 * @return {Array} Current value and props for the input element.
 */
const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const props = {
    value,
    onChange: ({ target }) => setValue(target.value),
  };

  return [value, props];
};

export { useGlobalState, useInput };
