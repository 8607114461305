/* eslint-disable jsx-a11y/iframe-has-title */
import PropTypes from 'prop-types';
import { Component } from 'react';
import Modal from 'react-modal';

import Category from './template-category-label';
import Actions from './template-modal-actions';

import {
  getDefaultPalette,
  getPaletteColorByName,
  getPaletteColors,
  getPaletteNameByColor,
  getTemplate,
} from '../index';
import update, { onChange as appStateChange } from '../../state';
import translate from '../../components/translate';
import ColorPicker from '../../components/color-picker';

// Hide other content for screenreaders while the modal is open
Modal.setAppElement(document.body);

/**
 * <TemplateModal /> component.
 *
 * Show preview of templates with name and categories.
 */
class TemplateModal extends Component {
  static propTypes = {
    templateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    primaryButtonHandler: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      gettext: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    templateIndex: null,
    isOpen: false,
    selectedPalette: null,
  };

  /**
   * Setup app state listener to open modal when `templateModal` is changed.
   */
  componentDidMount() {
    appStateChange(({ detail }) => {
      const { isOpen } = this.state;
      if (
        (detail.templateModal !== null && !isOpen) ||
        (detail.templateModal === null && isOpen)
      ) {
        this.setState({
          isOpen: !isOpen,
          templateIndex: detail.templateModal ? detail.templateModal.id : null,
          selectedPalette: detail.templateModal
            ? detail.templateModal.selectedPalette
            : null,
        });
      }
    });
  }

  /**
   * Navigate through templates.
   *
   * @param {Number} direction Either 1 (next) or -1 (prev).
   */
  navigate(direction) {
    const { templateIds } = this.props;
    const { templateIndex, index = templateIndex + direction } = this.state;
    const template = getTemplate(templateIds, index);

    if (template !== null) {
      this.setState({
        templateIndex: index,
        selectedPalette: getDefaultPalette(template),
      });
    }
  }

  render() {
    const { templateIndex, isOpen, selectedPalette } = this.state;
    const { templateIds, primaryButtonHandler, i18n } = this.props;
    const closeModal = () => {
      update('templateModal', null);
    };

    if (templateIndex === null) {
      return null;
    }

    const template = getTemplate(templateIds, templateIndex);
    const paletteColors = getPaletteColors(template);
    const previewUrl = `${template.preview_url}${
      selectedPalette ? `?palette=${selectedPalette}` : ''
    }`;
    const categories = template.categories
      ? template.categories.map((cat) => (
          <Category key={`category-${cat.name}`} name={cat.name} />
        ))
      : null;
    return (
      <Modal
        className="modal-full-height flex-container flex-dir-column"
        isOpen={isOpen}
        closeTimeoutMS={200}
        onRequestClose={() => closeModal()}
        overlayClassName="ReactModal__Overlay flex-container align-center align-middle"
      >
        <header className="grid-x grid-padding-x-large grid-padding-y-large border-bottom">
          <div className="cell">
            <div className="grid-x grid-padding-x grid-padding-y">
              <div className="cell shrink">
                <h2 className="h3">
                  {template.name}{' '}
                  {template.product_description && (
                    <>
                      <br />
                      <small>({template.product_description})</small>
                    </>
                  )}
                </h2>
              </div>
              <Actions
                primaryLabel={i18n.gettext('Choose')}
                onPrimaryClick={() =>
                  primaryButtonHandler(template.id, selectedPalette)
                }
                onPreviousClick={() => this.navigate(-1)}
                onNextClick={() => this.navigate(1)}
                onCloseClick={() => closeModal()}
                disablePrevious={templateIndex === 0}
                disableNext={templateIndex === templateIds.length - 1}
              >
                {paletteColors.length > 1 && (
                  <ColorPicker
                    selected={getPaletteColorByName(template, selectedPalette)}
                    colors={paletteColors}
                    onSelect={(color) => {
                      this.setState({
                        selectedPalette: getPaletteNameByColor(
                          template,
                          color
                        ),
                      });
                    }}
                  />
                )}
              </Actions>
            </div>
            <div className="grid-x grid-padding-x grid-padding-y">
              <div className="cell auto">{categories}</div>
            </div>
          </div>
        </header>
        <iframe src={previewUrl} className="flex-child-grow" />
      </Modal>
    );
  }
}

export default translate()(TemplateModal);
