import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';

/**
 * Convert the keys and values of an object to an URI query string.
 *
 * Expects values to be strings, or at least scalar values that makes sense
 * to convert to a string. No guarantee is made for passing in nested objects
 * or similar.
 *
 * @param {Object} obj
 * @return {String}
 */
const createQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');

/**
 * Merges two objects into one, concatenating array values if the two arrays
 * are not equal.
 *
 * @param {Object} object
 * @param {Object} source
 * @return {Object}
 */
const merge = (object, source) => ({
  ...mergeWith(object, source, (objValue, srcValue) => {
    if (isArray(objValue) && isArray(srcValue)) {
      return isEqual(objValue, srcValue) ? srcValue : objValue.concat(srcValue);
    }

    return undefined;
  }),
});

/**
 * Convert a query string to an object of keys and values.
 *
 * Very simple and does not take into account any crazy parts of the spec.
 *
 * @param {String} str
 * @return {Object}
 */
const parseQueryString = (str) =>
  Object.assign(
    {},
    ...str
      .split('&')
      .map((kv) => kv.split('='))
      .map(([key, value]) => ({
        [key]: decodeURIComponent(value.replace(/\+/g, ' ')),
      }))
  );

/**
 * Emit the height of the embed content to the parent window.
 *
 * This allows embedding contexts to listen for changes to embed frame
 * content height, and adjust the `<iframe>` height accordingly to avoid
 * nested scroll bars on the page.
 *
 * @param {Number} height Optional content height, defaults to height of
 *                        `<body>` element on the page.
 */
export function emitHeightToParent(height) {
  if (window.top === window || !window.postMessage) {
    return false;
  }

  const totalHeight = height ?? Math.ceil(document.body.getBoundingClientRect().height);

  // Hide vertical scrollbar initially to accurately measure height
  if (document.documentElement.style.overflowY === '') {
    document.documentElement.style.overflowY = 'hidden';
  }

  window.parent.postMessage({ height: totalHeight }, '*');

  setTimeout(() => {
    // Reset scroll bars if we weren't able to set frame height tall enough
    if (document.body.scrollHeight > totalHeight) {
      document.documentElement.style.overflowY = 'auto';
    }
  }, 100);

  return true;
}

/**
 * Helper to only listen for the next event on an element.
 *
 * As soon as the event has fired, we stop listening for further events of
 * the same type on the element, and thus the promise is resolved.
 *
 * @param {HTMLElement} element
 * @param {String} eventName
 * @return {Promise}
 */
const once = (element, eventName) =>
  new Promise((resolve) => {
    const listener = (event) => {
      element.removeEventListener(eventName, listener);
      resolve(event);
    };
    element.addEventListener(eventName, listener);
  });

/**
 * Specify a function to run as soon as it is safe.
 *
 * The document should then be in `interactive` or `complete` mode. Which means
 * that the DOM is fully parsed and ready to be queried.
 *
 * @param {Function} fn
 */
export function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

export { createQueryString, once, parseQueryString, merge };
