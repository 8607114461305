/* eslint-disable import/extensions, import/no-extraneous-dependencies, import/no-unresolved */
/**
 * Representation of the visual viewport of the editor.
 *
 * The editor is usually embedded inside an iframe, which creates a separate
 * window (viewport) for the editor. But often we care only about the actual
 * visible part of this framed window, which is dependent on the current
 * viewport of the outer window (eg. how much of the iframe is within view
 * in the outer window).
 *
 * This represents that abstract visible viewport, a rectangle of the visible
 * part of the iframe within the outer window.
 */
import { getScrollTop } from 'common/lib/compat';

let ScrollListener = null;
/*
 * Safely access `ScrollListener` from parent window, in case parent window is on a
 * different origin.
 */
try {
  ScrollListener = window.parent.ScrollListener;
} catch (e) {}

const Viewport = {
  /**
   * Set up initial viewport state and event listeners.
   *
   * Listens for scroll events from outer window contexts with info about
   * current scroll top and window size.
   */
  init(editorFrame) {
    this.editorFrame = editorFrame;
    this.offsetTop = 0;
    this.scrollTop = 0;
    this.windowSize = { width: 0, height: 0 };

    if (ScrollListener) {
      const event = ScrollListener.getLastEvent();

      if (event) {
        this.offsetTop = event.offsetTop;
        this.windowSize = event.windowSize;
        this.scrollTop = event.position + getScrollTop(window);
      }

      ScrollListener.on('start', ({ offsetTop, windowSize }) => {
        this.offsetTop = offsetTop || 0;
        this.windowSize = windowSize;
      });

      ScrollListener.on('scroll', (evt) => this.onScroll(evt));
    }

    window.addEventListener('scroll', (evt) => this.onScroll(evt));
  },

  /**
   * Update viewport top position on scroll events.
   *
   * @param {Object} event
   */
  onScroll(event) {
    let y = 0;

    if (event.position !== undefined) {
      y = event.position;
    } else if (ScrollListener && ScrollListener.getLastEvent()) {
      y = ScrollListener.getLastEvent().position;
    }

    if (event.windowSize) {
      this.windowSize = event.windowSize;
    }

    this.scrollTop = y + getScrollTop(window);
  },

  /**
   * Get the current viewport bounds.
   *
   * The viewport bounds represents the rectangle currently visible of
   * the local window context, eg. the visible part of an iframe.
   *
   * Currently we only calculate the vertical part of the rectangle since
   * we have no current need for horisontal info and the `ScrollListener`
   * only reports about vertical scroll position.
   *
   * @return {Object} Partial rectangle with top, bottom and height info.
   */
  getBounds() {
    if (!this.editorFrame) {
      return null;
    }

    let frameRectTop = 0;

    const editorRect = this.editorFrame.getBoundingClientRect();

    if (window.top === window.parent) {
      /*
       * When there is no outer context we only want the static top of the
       * iframe (not the one relative to the viewport as you receive from
       * getBoundingClientRect()).
       */
      frameRectTop = this.editorFrame.offsetTop;
    } else {
      frameRectTop = editorRect.top;
    }

    const absoluteFrameTop = frameRectTop + this.offsetTop;
    const topInFrame = Math.max(0, this.scrollTop - absoluteFrameTop);
    const bottomInFrame =
      this.windowSize.height + this.scrollTop - absoluteFrameTop;

    return {
      top: topInFrame,
      bottom: bottomInFrame,
      height: bottomInFrame - topInFrame,
      width: editorRect.width,
    };
  },
};

export default Viewport;
