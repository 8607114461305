import { createQueryString } from '../utils';
import { get, patch, post, request } from '../utils/api';

const getAvailableFields = (filters = {}) =>
  get(`/accounts/fields/?${createQueryString(filters)}`);

const addFieldToForm = (formId, field) =>
  post(`/forms/${formId}/fields/?fields=editor_html`, { field });

const getFieldInForm = (formId, fieldId) => get(`/forms/${formId}/fields/${fieldId}/`);

const editFieldInForm = (formId, fieldId, data) =>
  patch(`/forms/${formId}/fields/${fieldId}/?fields=editor_html`, { field: data });

const deleteFieldInForm = (formId, fieldId) =>
  request(`/forms/${formId}/fields/${fieldId}/?fields=editor_html`, 'DELETE');

const getTags = () => get('/contacts/tags/');

export {
  addFieldToForm,
  deleteFieldInForm,
  editFieldInForm,
  getAvailableFields,
  getFieldInForm,
  getTags,
};
