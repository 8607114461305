import i18n from '../../i18n';
import { ContentItemType } from '../../types';
import { truncate } from '../../utils';

/**
 * ProductContentItem component.
 *
 * @param {object} props.item
 * @returns {React.Component}
 */
const ProductContentItem = ({ item }) => {
  const { data } = item;
  const qtyStatus =
    item.type === 'product' &&
    data.metadata.quantity !== undefined &&
    data.metadata.quantity <= 0
      ? 'alert'
      : 'highlight';

  return (
    <>
      <p className="flex-child-grow padding-bottom-small">
        <strong>{truncate(data.title, 50)}</strong>
      </p>
      <ul className="padding-bottom-small grid-x grid-padding-x no-bullet">
        {data.metadata.price !== undefined && (
          <li className="cell shrink">{data.metadata.price_formatted}</li>
        )}
        {data.metadata.original_price !== undefined && (
          <li className="cell auto">
            <s>{data.metadata.original_price_formatted}</s>
          </li>
        )}
        {data.metadata.discount_percent !== undefined && (
          <li className="cell shrink">
            {data.metadata.discount_percent_formatted}
          </li>
        )}
      </ul>
      <div className="flex-container align-middle">
        {qtyStatus && (
          <p className="flex-child-grow">
            <span className={`label ${qtyStatus}`}>
              {i18n.sprintf(
                i18n.gettext('%(quantity)s in stock'),
                data.metadata
              )}
            </span>
          </p>
        )}
      </div>
    </>
  );
};

ProductContentItem.propTypes = {
  item: ContentItemType.isRequired,
};

export default ProductContentItem;
